import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  POLLING_REFETCH_INTERVAL,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { GetEligibilityExportResponse } from "@taxbit-dashboard/rest";
import { Uuid } from "@taxbit-private/uuids";

import getEligibilityParams, {
  EligibilityDataParams,
} from "./getEligibilityParams";

export const useGetEligibilityExport = ({
  exportId,
  onError,
  onSuccess,
}: {
  exportId?: Uuid;
} & Pick<
  UseQueryOptions<
    GetEligibilityExportResponse | undefined,
    unknown,
    GetEligibilityExportResponse,
    [DashboardQueryKey, { exportId?: Uuid }]
  >,
  "onError" | "onSuccess"
>) => {
  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.EligibilityExport, { exportId }],
    () => exportId && restSdk.eligibility.export.get({ exportId }),
    {
      ...createQueryMetaObject(restSdk.eligibility.export.buildPath(exportId)),
      enabled: !!exportId,
      onError,
      onSuccess,
      refetchInterval: (data, queryData) =>
        data?.data.presignedUrl || queryData.state.error
          ? false
          : POLLING_REFETCH_INTERVAL,
      refetchIntervalInBackground: true,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateEligibilityExport = () => {
  const restSdk = useTaxBitRest();

  return useMutation(
    [DashboardQueryKey.EligibilityExport],
    (params: EligibilityDataParams) => {
      const apiParams = getEligibilityParams(params);
      const {
        "page[offset]": offset,
        "page[limit]": limit,
        ...filterParams
      } = apiParams;

      return restSdk.eligibility.exports.post(filterParams);
    },
    {
      ...createQueryMetaObject(restSdk.eligibility.exports.buildPath()),
    }
  );
};
