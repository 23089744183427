/**
 * Currently this is only intended to contain routes provided by /apps/dashboard.
 * We may choose to expand this concept later if we need navigation across UMC modules.
 */
enum DashboardAppRoutePath {
  Launch = "/",
  Callback = "/callback",
  AccountSettings = "/settings/my-account",
  CompanyUserManagement = "/settings/user-management",
  InvitationManagement = "/settings/invitation-management",
  FilerSettings = "/settings/filer-settings",
  Notifications = "/notifications",
}

export default DashboardAppRoutePath;
