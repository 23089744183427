import {
  AssetAmount,
  GainItem,
  LineItem,
  Transaction,
} from "@taxbit-dashboard/rest";
import { Card, Table, TrimmedBoxContent } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { DateTimeString } from "@taxbit-private/data-models-v2";
import { useMemo } from "react";

import { calculateTransactionAcquiredAsset } from "../../../../../utils/calculateTransactionAcquiredAsset";
import calculateTransactionCostBasis from "../../../../../utils/calculateTransactionCostBasis";
import useFormatAsset from "../../../../../utils/useFormatAsset";

type TableAsset = {
  received?: LineItem;
  costBasisDate: DateTimeString;
  cost?: AssetAmount;
  key: string;
};

const mapReceivedAssetsToTable = (transaction: Transaction): TableAsset[] => {
  const { received, datetime, id } = transaction;
  if (!received) return [];
  const acquiredAsset = calculateTransactionAcquiredAsset(transaction);
  const costBasis = calculateTransactionCostBasis(transaction);
  return [
    {
      received: acquiredAsset,
      costBasisDate: datetime,
      cost: costBasis,
      key: `${id}-acquisition`,
    },
  ];
};

type AcquistionTableProps = {
  transaction: Transaction;
};

const AcquisitionTable: React.FC<AcquistionTableProps> = ({ transaction }) => {
  const receivedAssets = mapReceivedAssetsToTable(transaction);

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const columns = useMemo(
    () =>
      [
        {
          key: "costBasisDate",
          label: "Date Acquired",
          renderCell: (costBasisDate: GainItem["costBasisDate"]) => {
            return formatDateTime({ date: costBasisDate, format: "DateTime" });
          },
        },
        {
          key: "received",
          label: "Incoming Asset",
          renderCell: (received: TableAsset["received"]) => {
            return formatAsset({ assetAmount: received?.assetAmount });
          },
        },
        {
          key: "cost",
          label: "Cost Basis",
          renderCell: (cost: TableAsset["cost"]) => {
            return formatAsset({ assetAmount: cost });
          },
        },
      ] as const,
    [formatAsset, formatDateTime]
  );

  return (
    <Card
      title="Acquisitions"
      helpIconProps={{
        trackingId: "acquisition-lots-help-icon",
        tooltipProps: {
          content:
            "The tax lot(s) added to inventory as a result of this transaction.",
          appendTo: "parent",
          isInteractive: true,
        },
      }}
    >
      <TrimmedBoxContent trim="all">
        <Table
          columns={columns}
          rows={receivedAssets}
          getRowKey={(row) => row.key}
        />
      </TrimmedBoxContent>
    </Card>
  );
};

export default AcquisitionTable;
