import { FileAction } from "@taxbit-dashboard/rest";
import {
  Flex,
  Icon,
  getSharedBorderStyle,
  StyledIconBase,
  withTracking,
  Body,
  invisibleClickableStyles,
  getSharedAtomFocusCss,
  getRgbaForHex,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import {
  fileActionFileTypesMap,
  fileTypeIconMap,
  fileTypeLabelMap,
} from "../../../../api/files/filesApiTypes";
import useFormFeatureFlagData from "../../../information-reporting/shared/useFormFeatureFlagData";
import useFileUploaderContext from "../../context/useFileUploaderContext";
import isFormsFileType from "../../utils/isFormsFileType";

type Props = {
  action: FileAction;
};

const SelectFileTypeStep: React.FC<Props> = ({ action }) => {
  const { selectedFileType, setSelectedFileType } =
    useFileUploaderContext(action);

  const { hasForms } = useFormFeatureFlagData({
    featureFlag: "ingestionModuleYearsByFormTemplate",
  });

  const fileTypes = hasForms
    ? fileActionFileTypesMap[action]
    : fileActionFileTypesMap[action].filter((type) => !isFormsFileType(type));

  return (
    <Flex gap="l" growItems={true}>
      {fileTypes.map((type) => {
        const isSelected = selectedFileType === type;

        return (
          <FileTypeButton
            isSelected={isSelected}
            aria-current={isSelected}
            onClick={() => setSelectedFileType(type)}
            trackingId={`select-file-type-${type}`}
            key={type}
          >
            <Icon name={fileTypeIconMap[type]} />
            <Body>{fileTypeLabelMap[type]}</Body>
          </FileTypeButton>
        );
      })}
    </Flex>
  );
};

export default SelectFileTypeStep;

const FileTypeButton = withTracking(
  styled.button.withConfig<{ isSelected: boolean }>({
    shouldForwardProp: (propName) => propName !== "isSelected",
  })(
    ({ theme, isSelected }) => `
    ${invisibleClickableStyles}
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.m};
  align-items: center;
  justify-content: center;

  padding: ${theme.spacing.m};
  border-radius: ${theme.borderRadius.s};
  border: ${getSharedBorderStyle(theme)};
  
  ${StyledIconBase} {
    height: ${theme.sizing.l};
    width: ${theme.sizing.l};
    color: ${theme.color.gray4};
  }

  &:hover {
    border-color: ${theme.color.borderHover};
    background-color: ${theme.color.gray0};
  }

  &:active {
    border-color: ${theme.color.borderHover};
    background-color: ${theme.color.gray1};
  }

  &:focus-visible {
    ${getSharedAtomFocusCss(theme)}
  }

  ${
    isSelected
      ? `
        ${StyledIconBase} {
          color: ${theme.color.primary};
        }

        
        background-color: ${getRgbaForHex(theme.color.primary, 0.05)};

        &, &:hover, &:active {
          border: ${theme.borderWidth.m} ${theme.color.primary} solid;
        }

        &:hover {
          background-color: ${getRgbaForHex(theme.color.primary, 0.1)};
        }

        &:active {
          background-color: ${getRgbaForHex(theme.color.primary, 0.15)};
        }
  
  `
      : ""
  }
`
  )
);
