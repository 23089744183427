import { Row, isDefined } from "@taxbit-dashboard/commons";
import { ReleasedDocument } from "@taxbit-dashboard/rest";
import { Badge } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import {
  revisionTypesMap,
  taxFormStatusToBadgePropsMap,
} from "../../../../../api/tax-forms/taxFormsApiTypes";

const useGetTaxFormDetailsRows = () => {
  const { formatDateTime } = useCosmicLocalizationContext();

  return useCallback(
    ({ taxFormDetails }: { taxFormDetails: ReleasedDocument }) => {
      const rows: Row[] = [
        {
          label: "Type",
          content: revisionTypesMap[taxFormDetails.revisionType],
        },
        {
          label: "Status",
          content: (
            <Badge
              {...taxFormStatusToBadgePropsMap[taxFormDetails.revisionState]}
            />
          ),
        },
      ];

      if (isDefined(taxFormDetails.revision)) {
        rows.push({
          label: "Revision Number",
          content: taxFormDetails.revision,
        });
      }

      rows.push({
        label: "Date Generated",
        content: formatDateTime({ date: taxFormDetails.dateCreated }),
      });

      if (isDefined(taxFormDetails.dateDelivered)) {
        rows.push({
          label: "Date Delivered",
          content: formatDateTime({ date: taxFormDetails.dateDelivered }),
        });
      }

      if (isDefined(taxFormDetails.dateSent)) {
        rows.push({
          label: "Date Mailed",
          content: formatDateTime({ date: taxFormDetails.dateSent }),
        });
      }

      if (isDefined(taxFormDetails.dateEFiled)) {
        rows.push({
          label: "Date Filed",
          content: formatDateTime({ date: taxFormDetails.dateEFiled }),
        });
      }

      return [rows];
    },
    [formatDateTime]
  );
};

export default useGetTaxFormDetailsRows;
