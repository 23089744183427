import { FileAction, FileType } from "@taxbit-dashboard/rest";
import { Flex, Button, MimeType } from "@taxbit-private/cosmic";
import { RhfUploader } from "@taxbit-private/cosmic-react-hook-form";

import UploadDescriptionText from "./UploadDescriptionText";
import {
  FILE_UPLOADER_FORM_ID,
  FileUploaderFormFields,
} from "../../context/fileUploaderFormTypes";
import useFileUploaderContext from "../../context/useFileUploaderContext";

export enum UploadStepTrackingId {
  UploadButton = "file-uploader-upload-button",
  UploaderComponent = "file-uploader-uploader-component",
}

type Props = {
  action: FileAction;
};

const RhfUploadStep: React.FC<Props> = ({ action }) => {
  const { isUploading, progress, selectedFileType } =
    useFileUploaderContext(action);

  return (
    <Flex direction="column" gap="m">
      <UploadDescriptionText action={action} />
      <RhfUploader<FileUploaderFormFields>
        name="files"
        shouldShowSelectedFiles={true}
        supportedFileTypes={
          selectedFileType === FileType.Transactions
            ? [MimeType.Csv, MimeType.Jsonl]
            : [MimeType.Csv]
        }
        trackingId={UploadStepTrackingId.UploaderComponent}
        isDisabled={isUploading}
      />
      <Flex>
        <Button
          type="submit"
          form={FILE_UPLOADER_FORM_ID}
          label="Upload File"
          trackingId={UploadStepTrackingId.UploadButton}
          isLoading={isUploading}
          loadingText={`Uploading (${progress}%)`}
        />
      </Flex>
    </Flex>
  );
};

export default RhfUploadStep;
