import { irFormStatusSchema, irFormTypeSchema } from "@taxbit-dashboard/rest";
import { z } from "zod";

export const formsFiltersFormFieldSchema = z.object({
  statuses: z.array(irFormStatusSchema),
  types: z.array(irFormTypeSchema),
  dateRange: z.tuple([z.date().optional(), z.date().optional()]),
});

export type FormsFiltersFormFields = z.infer<
  typeof formsFiltersFormFieldSchema
>;
