import {
  AuthorizedView,
  DashboardAppRoutePath,
  DashboardNavigationItemChild,
  DashboardNavigationItemWithChildren,
  UserPermission,
} from "@taxbit-dashboard/commons";

import CompanyUserManagement from "./company-user-management/company-user-table/CompanyUserManagement";
import { CompanyUserManagementDataProvider } from "./company-user-management/context/useCompanyUserManagementData";
import InvitationManagement from "./invitation-management/user-invitation-table/InvitationManagement";

export const userManagementNavigationItem: DashboardNavigationItemChild = {
  href: DashboardAppRoutePath.CompanyUserManagement,
  label: "User Management",
  trackingId: "sidebar-navigation-user-management",
  key: "user-manangement",
} as const;

export const invitationsNavigationItem: DashboardNavigationItemChild = {
  href: DashboardAppRoutePath.InvitationManagement,
  label: "Invitation Management",
  trackingId: "sidebar-navigation-invitation-management",
  key: "invitations",
} as const;

export const navigationItem: DashboardNavigationItemWithChildren = {
  iconName: "users",
  label: "My Organization",
  trackingId: "sidebar-navigation-my-organization-anchor",
  children: [userManagementNavigationItem, invitationsNavigationItem],
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadOrganizationMembers],
} as const;

export const routes = [
  {
    path: "/user-management",
    element: (
      <AuthorizedView permissions={[UserPermission.ReadOrganizationMembers]}>
        <CompanyUserManagementDataProvider>
          <CompanyUserManagement />
        </CompanyUserManagementDataProvider>
      </AuthorizedView>
    ),
  },
  {
    path: "/invitation-management",
    element: (
      <AuthorizedView
        permissions={[UserPermission.ReadOrganizationInvitations]}
      >
        <InvitationManagement />
      </AuthorizedView>
    ),
  },
];
