import {
  DashboardPrimaryTemplate,
  useDashboardFeatureFlags,
  MissingDashboardConfigEmptyState,
} from "@taxbit-dashboard/commons";
import { FileAction, FileType } from "@taxbit-dashboard/rest";
import { Card, Flex, Stepper } from "@taxbit-private/cosmic";

import FilesTableCard from "./files-table-card/FilesTableCard";
import FileUploaderFormCard from "./FileUploaderFormCard";
import FormDataInformation from "./form-data-information/FormDataInformation";
import SelectFileTypeStep from "./select-file-type-step/SelectFileTypeStep";
import UploadStep from "./upload-step/UploadStep";
import { useIngestFileUploaderContext } from "../context/ingest/IngestFileUploaderContext";

const SELECTED_FILE_TYPE = {
  title: "Select File Type",
  content: <SelectFileTypeStep action={FileAction.Ingest} />,
  key: "select-file-type",
};

const UPLOAD = {
  title: "Upload",
  content: <UploadStep action={FileAction.Ingest} />,
  key: "upload",
};

const FORM_DATA_INFORMATION = {
  title: "Additional Form Data Information",
  content: <FormDataInformation action={FileAction.Ingest} />,
  key: "additional-form-data-information",
};

const IngestFileUploader = () => {
  const { selectedFileType, hasIngestionTemplates } =
    useIngestFileUploaderContext();
  const { hasIngestionTemplateSelectionAccess } = useDashboardFeatureFlags();

  const steps =
    selectedFileType === FileType.Forms
      ? [SELECTED_FILE_TYPE, FORM_DATA_INFORMATION, UPLOAD]
      : [SELECTED_FILE_TYPE, UPLOAD];

  return (
    <DashboardPrimaryTemplate title="Ingest Data">
      {hasIngestionTemplateSelectionAccess && !hasIngestionTemplates ? (
        <MissingDashboardConfigEmptyState />
      ) : (
        <Flex direction="column" gap="l">
          {hasIngestionTemplateSelectionAccess ? (
            // This component is a mirror of the current setup based on RHF. The previous
            // setup will be removed as soon as the complete flow is migrated to the new setup.
            <FileUploaderFormCard action={FileAction.Ingest} />
          ) : (
            <Card title="Upload Files">
              <Flex padding="contentPadding">
                <Stepper steps={steps} />
              </Flex>
            </Card>
          )}
          <FilesTableCard action={FileAction.Ingest} />
        </Flex>
      )}
    </DashboardPrimaryTemplate>
  );
};

export default IngestFileUploader;
