import { useSetBackHrefAndNavigate } from "@taxbit-dashboard/commons";
import {
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
  Table,
} from "@taxbit-private/cosmic";

import useAccountsTableColumns from "./useAccountsTableColumns";
import useAccountsTableSort from "./useAccountsTableSort";
import useAccountsTableData from "../useAccountsTableData";

const AccountsTable: React.FC = () => {
  const {
    data = [],
    shouldDisableControls,
    isLoading,
    isError,
  } = useAccountsTableData();

  const sortProps = useAccountsTableSort();

  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const accountsTableColumns = useAccountsTableColumns();

  const searchEmptyStateProps = useSearchEmptyStateProps();

  return (
    <Table
      density="compact"
      rows={data}
      columns={accountsTableColumns}
      shouldShowAutomaticPlaceholders={true}
      getRowKey={({ id }) => id?.toString() ?? ""}
      isLoading={shouldDisableControls || isLoading}
      getRowOnClick={({ id }) => {
        setBackHrefAndNavigate({
          to: `./${id}`,
          backHrefKey: "account-details",
          // Start off page context for all nested views on a clean state.
          reset: ["transactions", "transaction-details"],
        });
      }}
      emptyStateProps={
        isError
          ? getEnUsErrorEmptyStateProps({ entity: "accounts" })
          : searchEmptyStateProps
      }
      isManualSortable={true}
      {...sortProps}
    />
  );
};

export default AccountsTable;
