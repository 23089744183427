import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

export type AccountId = StringType<"AccountId">;

export const accountIdSchema = z
  .string()
  .transform((accountId) => accountId as AccountId);

export type OrganizationId = StringType<"OrganizationId">;

export const organizationIdSchema = z
  .string()
  .transform((organizationId) => organizationId as OrganizationId);

export type CompanyUserId = StringType<"CompanyUserId">;

export const companyUserIdSchema = z
  .string()
  .transform((companyUserId) => companyUserId as CompanyUserId);

export type PayerId = StringType<"PayerId">;

export const payerIdSchema = z
  .string()
  .transform((payerId) => payerId as PayerId);

export type OffsetPaginationApiParams = {
  "page[limit]"?: number;
  "page[offset]"?: number;
};

export enum AccountType {
  UsIraTraditional = "US_IRA_TRADITIONAL",
  UsIraRoth = "US_IRA_ROTH",
  UsIraSimple = "US_IRA_SIMPLE",
  UsIraSep = "US_IRA_SEP",
}

export enum ValidationResultCode {
  ValidSsnMatch = "VALID_SSN_MATCH",
  InvalidData = "INVALID_DATA",
  Foreign = "FOREIGN",
  ValidEinMatch = "VALID_EIN_MATCH",
  Mismatch = "MISMATCH",
  Pending = "PENDING",
  TinNotIssued = "TIN_NOT_ISSUED",
  ValidSsnEinMatch = "VALID_SSN_EIN_MATCH",
}

export const validationResultCodeSchema = z.nativeEnum(ValidationResultCode);

export type AccountOwnerId = StringType<"AccountOwnerId">;

export const accountOwnerIdSchema = z
  .string()
  .transform((accountOwnerId) => accountOwnerId as AccountOwnerId);

export const accountTypeSchema = z.nativeEnum(AccountType);

export const yearEndFairMarketValueSchema = z.object({
  yearEnd: z.number(),
  fairMarketValue: z.string(),
});

export enum DispositionMethod {
  UniversalFifo = "UNIVERSAL_FIFO",
  UniversalHifo = "UNIVERSAL_HIFO",
  ExchangeHifo = "EXCHANGE_HIFO",
  EnterpriseExchangeHifo = "ENTERPRISE_EXCHANGE_HIFO",
  EnterpriseExchangeFifo = "ENTERPRISE_EXCHANGE_FIFO",
  GbIndividual = "GB_INDIVIDUAL",
  GbBusiness = "GB_BUSINESS",
  LuWac = "LU_WAC",
}

export const dispositionMethodSchema = z.nativeEnum(DispositionMethod);

export enum AccountOwnerType {
  Individual = "INDIVIDUAL",
  Entity = "ENTITY",
}

export const accountOwnerTypeSchema = z.nativeEnum(AccountOwnerType);

export const addressSchema = z.object({
  firstLine: z.string().optional(),
  secondLine: z.string().optional(),
  city: z.string().optional(),
  stateOrProvince: z.string().optional(),
  country: z.string().optional(),
  postalCode: z.string().optional(),
});

export enum AccountServiceTinType {
  UsSsn = "US_SSN",
  UsEin = "US_EIN",
  UsAtin = "US_ATIN",
  UsItin = "US_ITIN",
  Other = "OTHER",
}

export const accountOwnerPiiDtoSchema = z.object({
  name: z.string().optional(),
  email: z.string().optional(),
  birthDate: z.string().optional(),
  tin: z.string().optional(),
  maskedTin: z.string().optional(),
  tinType: z.nativeEnum(AccountServiceTinType).optional(),
  taxCountryCode: z.string().optional(),
  address: addressSchema.optional(),
});

const irsTinMatchValidationSchema = z.object({
  validationType: z.literal("IRS_TIN_MATCH"),
  validationResult: validationResultCodeSchema.optional(),
  validationResponseDate: z.string().optional(),
});

export type TinMatchReportingValidation = z.infer<
  typeof irsTinMatchValidationSchema
>;

const accountOwnerValidationMapSchema = z.object({
  irsTinMatch: irsTinMatchValidationSchema.optional(),
});

export const accountOwnerSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  dateModified: z.string(),
  dateCreated: z.string(),
  organizationId: uuidSchema,
  externalId: z.string(),
  accountOwnerType: accountOwnerTypeSchema,
  prefersPhysicalMail: z.boolean().optional(),
  isTaxExempt: z.boolean().optional(),
  hasFatcaFilingRequirement: z.boolean().optional(),
  hasSecondTinNotice: z.boolean().optional(),
  isSubjectToBackupWithholding: z.boolean().optional(),
  pii: accountOwnerPiiDtoSchema,
  validation: accountOwnerValidationMapSchema,
});

export const accountV2Schema = z.object({
  accountId: accountIdSchema,
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  organizationId: organizationIdSchema,
  payerId: payerIdSchema.optional(),
  externalId: z.string(),
  accountType: accountTypeSchema.optional(),
  establishmentDate: z.string().optional(),
  yearEndFairMarketValue: yearEndFairMarketValueSchema.array().optional(),
  dispositionMethod: dispositionMethodSchema.optional(),
  accountOwner: accountOwnerSchema,
});

export enum AccountOwnerCuringsIssueType {
  W8benePoBoxPermanentAddress = "W8BENE_PO_BOX_PERMANENT_ADDRESS",
  UsIndicia = "US_INDICIA",
  W8beneCareOf = "W8BENE_CARE_OF_PERMANENT_ADDRESS",
}

export const createAccountOwnerCuringRequestSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  issueType: z.nativeEnum(AccountOwnerCuringsIssueType),
  target: z.string(),
  note: z.string().optional(),
});

export const accountOwnerCuringSchema =
  createAccountOwnerCuringRequestSchema.extend({
    id: z.number(),
    organizationId: organizationIdSchema,
    companyUserId: companyUserIdSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
  });
