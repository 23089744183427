import { RhfDateRangePicker } from "@taxbit-private/cosmic-react-hook-form";

import { FormsFiltersFormFields } from "./formsFiltersFormFieldSchema";

export enum FormsTypeFilterTrackingIds {
  DateRange = "ir-forms-filter-date-range-picker",
}

const FormsDateRangeFilter: React.FC = () => {
  return (
    <RhfDateRangePicker<Pick<FormsFiltersFormFields, "dateRange">>
      name="dateRange"
      label="Date Generated"
      trackingId={FormsTypeFilterTrackingIds.DateRange}
      shouldAlignMenuToTargetWidth={true}
      placeholder="All Time"
      maxDate={new Date()}
    />
  );
};

export default FormsDateRangeFilter;
