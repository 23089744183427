import {
  ToastConfig,
  irFormTypeToDisplayNameMap,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import pluralize from "pluralize";

import { useDownloadIrForms } from "../../../../../api/information-reporting/forms/formsApi";
import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useIrFormsContext } from "../../context/useIrForms";

enum IrFormsDownloadActionTrackingIds {
  ErrorToast = "ir-forms-download-error-toast",
  SuccessToast = "ir-forms-download-success-toast",
}

const ALLOWED_FORMS_TO_DOWNLOAD = 100_000;

export const downloadSuccessToast: ToastConfig = {
  message:
    "Forms are being prepared. The zip file will appear in the toolbar when ready to download.",
  trackingId: IrFormsDownloadActionTrackingIds.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const downloadErrorToast: ToastConfig = {
  message: "Forms failed to download. Please try again.",
  trackingId: IrFormsDownloadActionTrackingIds.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
  variant: "danger",
};

const useDownloadFormsConfirmationModal = ({
  onClose,
  isBulkDownload = false,
}: {
  onClose: () => void;
  isBulkDownload?: boolean;
}) => {
  const addToast = useDashboardStore((store) => store.addToast);
  const {
    currentForm,
    currentYear,
    selectedForms,
    toggleAllSelectedForms,
    aggregates,
  } = useIrFormsContext();

  const { formatWholeQuantity } = useCosmicLocalizationContext();

  const { mutate: downloadForms } = useDownloadIrForms({
    onError: () => {
      addToast(downloadErrorToast);
    },
    onSuccess: () => {
      addToast(downloadSuccessToast);
    },
  });

  const handleDownloadForms = () => {
    toggleAllSelectedForms(false);
    downloadForms({
      taxFormKeys: isBulkDownload ? undefined : selectedForms,
      documentType: currentForm,
      documentYear: currentYear,
    });
    onClose();
  };

  const totalCount = isBulkDownload
    ? aggregates?.total ?? 0
    : selectedForms.length;

  const formattedAllowedFormsToDownload = formatWholeQuantity(
    ALLOWED_FORMS_TO_DOWNLOAD
  );

  const modalContent =
    totalCount > ALLOWED_FORMS_TO_DOWNLOAD
      ? `You may only download up to ${formattedAllowedFormsToDownload} forms at one time. The first  ${formattedAllowedFormsToDownload} forms will be downloaded into a zip file.`
      : `${formatWholeQuantity(totalCount)} ${currentForm && irFormTypeToDisplayNameMap[currentForm]} ${pluralize("form", totalCount)} will be downloaded into a zip file.`;

  return {
    handleDownloadForms,
    modalContent,
  };
};

export default useDownloadFormsConfirmationModal;
