import { Button, NumericalBadge } from "@taxbit-private/cosmic";
import { useState } from "react";

import EligibilityFiltersDrawer from "./EligibilityFiltersDrawer";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum EligibilityFiltersDrawerToggleTrackingIds {
  ToggleButton = "irEligibilityFiltersDrawerToggleButton",
  CountBadge = "irEligibilityFiltersDrawerCountBadge",
}

const EligibilityFiltersDrawerToggle = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { shouldDisableControls, filterDrawerFilterCount } =
    useEligibilityDataContext();

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        size="small"
        trackingId={EligibilityFiltersDrawerToggleTrackingIds.ToggleButton}
        onClick={() => setIsDrawerOpen(true)}
        isDisabled={shouldDisableControls}
        utilityElement={
          filterDrawerFilterCount > 0 ? (
            <NumericalBadge
              trackingId={EligibilityFiltersDrawerToggleTrackingIds.CountBadge}
              value={filterDrawerFilterCount}
            />
          ) : undefined
        }
      />
      <EligibilityFiltersDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default EligibilityFiltersDrawerToggle;
