import { QueryFunctionContext, useInfiniteQuery } from "@tanstack/react-query";
import {
  createQueryMetaObject,
  DashboardQueryKey,
  filterToDefinedValues,
  useTaxBitRest,
} from "@taxbit-dashboard/commons";
import { AccountId, TransactionId, TransferType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

const getTransferLotsQueryKeys = (
  accountId: AccountId,
  transactionId: TransactionId,
  continuationToken?: string,
  limit?: number
) =>
  [
    {
      key: DashboardQueryKey.TransferOutLots,
      accountId,
      transactionId,
      limit,
      continuationToken,
    },
  ] as const;

export const useGetTransferLots = ({
  type,
  accountId: accountUuid,
  transactionId: transactionUuid,
  continuationToken: continuationKey,
  limit,
  shouldFetchTransferLots = false,
}: {
  type: TransferType;
  accountId: AccountId;
  transactionId: TransactionId;
  continuationToken?: string;
  limit?: number;
  shouldFetchTransferLots?: boolean;
}) => {
  const restSdk = useTaxBitRest();

  const fetchTransferLots = ({
    queryKey: [{ accountId, transactionId }],
    pageParam,
  }: QueryFunctionContext<
    ReturnType<typeof getTransferLotsQueryKeys>,
    string
  >) => {
    return restSdk.transferOutLots.get({
      accountId,
      transactionId,
      next: pageParam,
      limit,
    });
  };

  const query = useInfiniteQuery(
    getTransferLotsQueryKeys(
      accountUuid,
      transactionUuid,
      continuationKey,
      limit
    ),
    fetchTransferLots,
    {
      ...createQueryMetaObject(
        restSdk.transferOutLots.buildPath(transactionUuid, accountUuid)
      ),
      enabled: !!shouldFetchTransferLots && type === TransferType.TransferOut,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage?.meta?.page?.next,
    }
  );

  const data = useMemo(
    () =>
      filterToDefinedValues(query.data?.pages)?.flatMap((page) => page.data),
    [query]
  );

  return { ...query, data };
};
