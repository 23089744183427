import { Button, NumericalBadge } from "@taxbit-private/cosmic";
import { useState } from "react";

import FormsFiltersDrawer from "./FormsFiltersDrawer";
import { useIrFormsContext } from "../context/useIrForms";

export enum FormsFiltersDrawerToggleTrackingIds {
  ToggleButton = "ir-forms-filters-drawer-toggle-button",
  CountBadge = "ir-forms-filters-drawer-count-badge",
}

const FormsFiltersDrawerToggle = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { shouldDisableControls, filterDrawerFilterCount } =
    useIrFormsContext();

  return (
    <>
      <Button
        label="Filters"
        iconName="sliders"
        variant="button-tertiary"
        size="small"
        trackingId={FormsFiltersDrawerToggleTrackingIds.ToggleButton}
        onClick={() => setIsDrawerOpen(true)}
        isDisabled={shouldDisableControls}
        utilityElement={
          filterDrawerFilterCount > 0 ? (
            <NumericalBadge
              trackingId={FormsFiltersDrawerToggleTrackingIds.CountBadge}
              value={filterDrawerFilterCount}
            />
          ) : undefined
        }
      />
      <FormsFiltersDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};

export default FormsFiltersDrawerToggle;
