import { useMutation, useQuery } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  useTaxBitRest,
  createQueryMetaObject,
  unwrapPublicApiWrappedQuery,
  useDashboardFeatureFlags,
  isDefined,
  useUserPermission,
  UserPermission,
} from "@taxbit-dashboard/commons";
import {
  KycDocumentGenerationParams,
  UserId,
  KycDocumentId,
  GetKycDocument,
  KycTaxDocumentType,
  AccountOwnerId,
} from "@taxbit-dashboard/rest";

type UseGetTaxDocumentationParams = {
  userId?: UserId | AccountOwnerId;
};

export const useGetKycTaxDocumentationSummary = (
  userId?: UserId | AccountOwnerId,
  documentType?: KycTaxDocumentType
) => {
  const { hasTaxDocumentationAccess } = useDashboardFeatureFlags();
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentationSummary, userId],
    () =>
      userId ? restSdk.kycTaxDocumentation.summary.get({ userId }) : undefined,
    {
      ...createQueryMetaObject(
        restSdk.kycTaxDocumentation.summary.buildPath(userId)
      ),
      enabled:
        !!userId &&
        hasTaxDocumentationAccess &&
        isDefined(documentType) &&
        documentType !== KycTaxDocumentType.Comprehensive,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGetKycTaxDocumentationStatus = (
  userId?: UserId | AccountOwnerId,
  documentType?: KycTaxDocumentType
) => {
  const { hasTaxDocumentationAccess } = useDashboardFeatureFlags();
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentationStatus, userId],
    () =>
      userId ? restSdk.kycTaxDocumentation.status.get({ userId }) : undefined,
    {
      ...createQueryMetaObject(
        restSdk.kycTaxDocumentation.status.buildPath(userId)
      ),
      enabled:
        !!userId &&
        hasTaxDocumentationAccess &&
        isDefined(documentType) &&
        documentType === KycTaxDocumentType.Comprehensive,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};

export const useGenerateKycDocument = () => {
  const restSdk = useTaxBitRest();
  return useMutation(
    ({
      userId,
      requestParams,
    }: {
      userId: UserId | AccountOwnerId;
      requestParams: KycDocumentGenerationParams;
    }) => restSdk.kycTaxDocumentation.document.post({ userId, requestParams })
  );
};

export const useGetKycDocument = ({
  userId,
  documentId,
  refetchInterval,
  onError,
  onSuccess,
}: {
  userId?: UserId | AccountOwnerId;
  documentId?: KycDocumentId;
  refetchInterval?: number;
  onError?: () => void;
  onSuccess?: (data: GetKycDocument) => void;
}) => {
  const restSdk = useTaxBitRest();
  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentationDocument, userId, documentId],
    () =>
      userId && documentId
        ? restSdk.kycTaxDocumentation.document.get({ userId, documentId })
        : undefined,
    {
      ...createQueryMetaObject(
        restSdk.kycTaxDocumentation.document.buildPath(userId, documentId)
      ),
      enabled: !!documentId && !!userId,
      refetchInterval,
      cacheTime: 0,
      onError,
      onSuccess,
    }
  );

  return unwrapPublicApiWrappedQuery(query);
};

export const useGetKycTaxDocumentation = ({
  userId,
}: UseGetTaxDocumentationParams) => {
  const { hasTaxDocumentationAccess } = useDashboardFeatureFlags();
  const unmaskTaxId = useUserPermission(UserPermission.ReadTins);

  const restSdk = useTaxBitRest();

  const query = useQuery(
    [DashboardQueryKey.KycTaxDocumentation, userId, !!unmaskTaxId],
    () =>
      userId
        ? restSdk.kycTaxDocumentation.get({ userId, unmaskTaxId })
        : undefined,
    {
      ...createQueryMetaObject(restSdk.kycTaxDocumentation.buildPath(userId)),
      enabled: !!userId && hasTaxDocumentationAccess,
    }
  );
  return unwrapPublicApiWrappedQuery(query);
};
