import {
  AccountsTemplateType,
  DashboardFormType,
  TransactionsTemplateType,
  dashboardFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { z } from "zod";

export const irFormTypeToDisplayNameMap: Record<DashboardFormType, string> = {
  [DashboardFormType.Irs1042S]: "1042-S",
  [DashboardFormType.Irs1099B]: "1099-B",
  [DashboardFormType.Irs1099Div]: "1099-DIV",
  [DashboardFormType.Irs1099Int]: "1099-INT",
  [DashboardFormType.Irs1099K]: "1099-K",
  [DashboardFormType.Irs1099Misc]: "1099-MISC",
  [DashboardFormType.Irs1099Nec]: "1099-NEC",
  [DashboardFormType.Irs1099R]: "1099-R",
  [DashboardFormType.Irs5498]: "5498",
  [DashboardFormType.GainLossPdf]: "Gain Loss PDF",
  [DashboardFormType.GainLossSummary]: "Gain Loss Summary",
  [DashboardFormType.UkGainLossSummary]: "UK Gain Loss Summary",
  [DashboardFormType.TransactionSummary]: "Transaction Summary",
  [DashboardFormType.TransactionSummaryPdf]: "Transaction Summary PDF",
  [DashboardFormType.RmdStatement]: "RMD Statement",
  [DashboardFormType.Cesop]: "CESOP",
  [DashboardFormType.Dac7]: "DAC7",
};

/**
 * @deprecated The new individual feature flag system swaps the order of this mapping to be
 * form type to years rather than years to form types. No new feature flags should use this schema.
 */
export const irFormTypesByYearSchema = z.record(
  // Check that the year is a valid four digit year before converting it to a string for the Record key
  fourDigitYearSchema.transform((year) => `${year}`),
  dashboardFormTypeSchema.array()
);

/**
 * @deprecated The new individual feature flag system swaps the order of this mapping to be
 * form type to years rather than years to form types. No new feature flags should use this type.
 */
export type IrFormTypesByYear = z.infer<typeof irFormTypesByYearSchema>;

/**
 * @deprecated The new individual feature flag system swaps the order of this mapping to be
 * form type to years rather than years to form types. No new feature flags should use this default.
 */
export const defaultIrFormTypesByYear = irFormTypesByYearSchema.parse({
  "2022": Object.values(DashboardFormType),
});

export const yearsByIrFormTypeSchema = z.record(
  dashboardFormTypeSchema,
  z
    .number()
    /**
     * We'd love to use `fourDigitYearSchema.array()` for the value type but when doing so,
     * zod throws a completely nonsensical error saying that the expected value is a single
     * form type rather than an array of years. This issue only appears when using the imported
     * `fourDigitYearSchema` and not if we recreate the same schema locally in this repo. Gave up
     * on debugging this one, but perhaps when we upgrade zod it will be fixed.
     *
     * https://taxbit.atlassian.net/browse/TAX-37426
     */
    .transform((num) => fourDigitYearSchema.parse(num))
    .array()
    .min(1)
);
export type YearsByIrFormType = z.infer<typeof yearsByIrFormTypeSchema>;

export const defaultYearsByIrFormType = yearsByIrFormTypeSchema.parse({});

const accountsTemplateTypesSchema = z.nativeEnum(AccountsTemplateType).array();

export const defaultIngestionModuleAccountsTemplates =
  accountsTemplateTypesSchema.parse([]);

const transactionsTemplateTypesSchema = z
  .nativeEnum(TransactionsTemplateType)
  .array();

export const defaultIngestionModuleTransactionsTemplates =
  transactionsTemplateTypesSchema.parse([]);

/**
 * This object reflects all current flags under the LaunchDarkly "TaxBit Dashboard" project.
 *
 * When adding new flags, please include a default value in `catch` statement. This default should reflect
 * the safest value for this flag in the event that LaunchDarkly fails to intiialize properly.
 * For example, the default value should not expose the WIP UI or allow unnecessary API requests
 * to be made.
 *
 * If a flag is removed in LaunchDarkly, please remove it from this set and test file as well.
 */
export const dashboardFeatureFlagSetSchema = z.object({
  hasAccountDetailsV2Access: z.boolean().catch(false),
  hasAccountFormItemsAccess: z.boolean().catch(false),
  hasAccountInventoryAccess: z.boolean().catch(false),
  hasAccountsExportsAccess: z.boolean().catch(false),
  hasAccountValidationCannedReportAccess: z.boolean().catch(false),
  hasAddressCuringAccess: z.boolean().catch(false),
  hasCbiTransfersFilters: z.boolean().catch(false),
  hasCbiTransfersPage: z.boolean().catch(false),
  hasDac7AccountAccess: z.boolean().catch(false),
  hasDeleteFormsAccess: z.boolean().catch(false),
  hasDownloadQaPackageAccess: z.boolean().catch(false),
  hasDownloadsAccess: z.boolean().catch(false),
  hasEligibilityPageAccess: z.boolean().catch(false),
  hasFilerSetupAccess: z.boolean().catch(false),
  hasFormsStatusCannedReportAccess: z.boolean().catch(false),
  hasIngestionModuleAccess: z.boolean().catch(false),
  hasIngestionModuleDeleteAccess: z.boolean().catch(false),
  hasIngestionTemplateSelectionAccess: z.boolean().catch(false),
  hasIrFormsPageAccess: z.boolean().catch(false),
  hasIrItemsPreviewAccess: z.boolean().catch(false),
  hasIrSummaryPageAccess: z.boolean().catch(false),
  hasIrwCustomReportsAccess: z.boolean().catch(false),
  hasIssueFilterAccess: z.boolean().catch(false),
  hasNewOrganizationPickerAccess: z.boolean().catch(false),
  hasOnDemandReportsAccess: z.boolean().catch(false),
  hasRecalculateEligibilityAccess: z.boolean().catch(false),
  hasRetirementAccountAccess: z.boolean().catch(false),
  hasNotificationCenterAccess: z.boolean().catch(false),
  hasTaxCannedReportsAccess: z.boolean().catch(false),
  hasTaxDocumentationAccess: z.boolean().catch(false),
  hasTinMatchingServiceAccess: z.boolean().catch(false),
  hasTransactionCountsCannedReportAccess: z.boolean().catch(false),
  hasTransactionsTabAccess: z.boolean().catch(false),
  hasTransferOutTaxLotsAccess: z.boolean().catch(false),
  hasUserSettingsAccess: z.boolean().catch(false),
  hasW8TaxDocumentationAccess: z.boolean().catch(false),
  ingestionModuleAccountsTemplates: accountsTemplateTypesSchema.catch(
    defaultIngestionModuleAccountsTemplates
  ),
  ingestionModuleTransactionsTemplates: transactionsTemplateTypesSchema.catch(
    defaultIngestionModuleTransactionsTemplates
  ),
  ingestionModuleYearsByFormTemplate: yearsByIrFormTypeSchema.catch(
    defaultYearsByIrFormType
  ),
  formsPageYearsByFormType: yearsByIrFormTypeSchema.catch(
    defaultYearsByIrFormType
  ),
  generateFormsYearsByFormType: yearsByIrFormTypeSchema.catch(
    defaultYearsByIrFormType
  ),
  irFormTypesByYear: irFormTypesByYearSchema.catch(defaultIrFormTypesByYear),
  isCbiIntegratedMember: z.boolean().catch(false),
  isCbiMember: z.boolean().catch(false),
  shouldHideAccountsTableTinUi: z.boolean().catch(false),
  shouldSkipFileHeaderValidation: z.boolean().catch(false),
  // Defaults to true because the vast majority of users should see cost basis on their 1099-B forms
  shouldForm1099BIncludeCostBasis: z.boolean().catch(true),
});

export type DashboardFeatureFlagSet = z.infer<
  typeof dashboardFeatureFlagSetSchema
>;

export type DashboardFeatureFlag = keyof DashboardFeatureFlagSet;
