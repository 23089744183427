import { dateTimeStringSchema } from "@taxbit-private/data-models-v2";
import {
  caProvinceCodes,
  usStateCodes,
  countryCodes,
} from "@taxbit-private/irw-address";
import {
  exemptFatcaCodes,
  exemptPayeeCodes,
  usTaxIdTypes,
  w8BenETaxClassificationEnum,
  w9TaxClassificationEnum,
} from "@taxbit-private/irw-tax-documentation";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

export enum KycTaxDocumentType {
  W8Ben = "W-8BEN",
  W9 = "W-9",
  W8BenE = "W-8BEN-E",
  Comprehensive = "COMPREHENSIVE",
}

export enum KycTaxDocumentStatus {
  Valid = "VALID",
  Invalid = "INVALID",
  Undocumented = "UNDOCUMENTED",
}

export enum KycTinVerificationStatus {
  Pending = "PENDING",
  InvalidData = "INVALID_DATA",
  TinMismatch = "TIN_MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  TinTypeMismatch = "TIN_TYPE_MISMATCH",
  TinMismatchForeignIndicia = "TIN_MISMATCH_FOREIGN_INDICIA",
  ForeignIndicia = "FOREIGN_INDICIA",
  Valid = "VALID",
  CountryCodeMismatch = "COUNTRY_CODE_MISMATCH",
}

export enum KycTaxDocumentationIssueType {
  ExpiredForm = "EXPIRED_FORM",
  UsMailingAddress = "US_MAILING_ADDRESS",
  UsPermanentAddress = "US_PERMANENT_ADDRESS",
  CareOf = "CARE_OF_PERMANENT_ADDRESS",
  PoBoxPermanentAddress = "PO_BOX_PERMANENT_ADDRESS",
}

export const kycTaxDocumentationIssueTypeSchema = z.nativeEnum(
  KycTaxDocumentationIssueType
);

export enum KycTaxDocumentationIssueStatus {
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycCuringStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycResubmissionStatus {
  NotApplicable = "NOT_APPLICABLE",
  Open = "OPEN",
  Resolved = "RESOLVED",
}

export enum KycTaxDocumentationSubmissionStatus {
  Submitted = "SUBMITTED",
  NotSubmitted = "NOT_SUBMITTED",
}

export enum KycTaxDocumentationDac7Status {
  Complete = "COMPLETE",
  Incomplete = "INCOMPLETE",
}

export enum KycTaxdocumentationVatStatus {
  Pending = "PENDING",
  Valid = "VALID",
  Invalid = "INVALID",
  InsufficientData = "INSUFFICIENT_DATA",
  NotRequired = "NOT_REQUIRED",
}

export const unrefinedAddressSchema = z
  .object({
    firstLine: z.string().optional(),
    secondLine: z.string().optional(),
    city: z.string().optional(),
    stateOrProvince: z
      .union([z.enum(caProvinceCodes), z.enum(usStateCodes), z.string()])
      .optional(),
    postalCode: z.string().optional(),
    country: z.enum(countryCodes),
  })
  .describe("unrefinedAddressSchema");

export const unrefinedW9TaxDocumentationSchema = z.object({
  name: z.string(),
  dbaName: z.string().optional(),
  otherTaxClassification: z.string().optional(),
  exemptPayeeCode: z.enum(exemptPayeeCodes).optional(),
  exemptFatcaCode: z.enum(exemptFatcaCodes).optional(),
  taxClassification: z.enum(w9TaxClassificationEnum),
  address: unrefinedAddressSchema,
  tin: z.string(),
  tinType: z.enum(usTaxIdTypes),
  documentType: z.literal(KycTaxDocumentType.W9),
  hasCertified: z.boolean(),
  isNotSubjectBackupWithholding: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenTaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.literal("INDIVIDUAL"),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  dateOfBirth: z.string(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8Ben),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

export const unrefinedW8BenETaxDocumentationSchema = z.object({
  name: z.string(),
  country: z.string(),
  taxClassification: z.enum(w8BenETaxClassificationEnum),
  permanentAddress: unrefinedAddressSchema,
  mailingAddress: unrefinedAddressSchema.optional(),
  tin: z.string().optional(),
  ftin: z.string().optional(),
  referenceNumbers: z.string().optional(),
  ftinNotLegallyRequired: z.boolean(),
  documentType: z.literal(KycTaxDocumentType.W8BenE),
  hasCertified: z.boolean(),
  signature: z.string(),
  signatureTimestamp: z.string(),
});

// comprehensive

export enum ForeignAccountHolderAccountTypeOptions {
  Individual = "INDIVIDUAL",
  Corporation = "CORPORATION",
  Partnership = "PARTNERSHIP",
  Trust = "TRUST",
  Other = "OTHER",
  DisregardedEntity = "DISREGARDED_ENTITY",
}

export enum ForeignOtherAccountTypeOptions {
  TaxExemptOrganization = "TAX_EXEMPT_ORGANIZATION",
  PrivateFoundation = "PRIVATE_FOUNDATION",
  InternationalOrganization = "INTERNATIONAL_ORGANIZATION",
  CentralBankOfIssue = "CENTRAL_BANK_OF_ISSUE",
  ForeignGovernmentControlledEntity = "FOREIGN_GOVERNMENT_CONTROLLED_ENTITY",
  ForeignGovernmentIntegralPart = "FOREIGN_GOVERNMENT_INTEGRAL_PART",
  Estate = "ESTATE",
}

export enum ForeignTrustAccountTypeOptions {
  SimpleTrust = "SIMPLE_TRUST",
  ComplexTrust = "COMPLEX_TRUST",
  GrantorTrust = "GRANTOR_TRUST",
}

export enum UsAccountHolderAccountTypeOptions {
  Individual = "INDIVIDUAL",
  SoleProprietor = "SOLE_PROPRIETOR",
  Llc = "LLC",
  SmLlc = "SM_LLC",
  Ccorporation = "C_CORPORATION",
  Scorporation = "S_CORPORATION",
  Partnership = "PARTNERSHIP",
  TrustEstate = "TRUST_ESTATE",
  Other = "OTHER",
  DisregardedEntity = "DISREGARDED_ENTITY",
}

export enum UsLlcAccountTypeOptions {
  Ccorporation = "C_CORPORATION",
  Scorporation = "S_CORPORATION",
  Partnership = "PARTNERSHIP",
}

const taxResidenceSchema = z.object({
  country: z.string().optional(),
  tin: z.string().optional(),
  tinNotRequired: z.boolean().optional(),
});

export const accountHolderSchema = z.object({
  address: unrefinedAddressSchema.optional(),
  countryOfCitizenship: z.string().optional(),
  dateOfBirth: z.string().optional(),
  dbaName: z.string().optional(),
  mailingAddress: unrefinedAddressSchema.optional(),
  mailingAddressIsDifferent: z.literal(true).optional(),
  name: z.string().optional(),
  cityOfBirth: z.string().optional(),
  countryOfBirth: z.string().optional(),
  financialAccountIdentifier: z.string().optional(),
  financialAccountName: z.string().optional(),
  ftin: z.string().optional(),
  ftinNotLegallyRequired: z.literal(true).optional(),
  taxResidences: taxResidenceSchema.array().optional(),
  showTaxResidence: z.array(z.boolean()).optional(),
  tin: z.string().optional(),
  vatin: z.string().optional(),
  vatinCountry: z.string().optional(),
  vatinNotRequired: z.boolean().optional(),
  exemptFatcaCode: z.string().optional(),
  exemptPayeeCode: z.string().optional(),
  isIndividual: z.boolean().optional(),
  isEuResident: z.boolean().optional(),
  isUsPerson: z.boolean().optional(),
  usAccountType: z.nativeEnum(UsAccountHolderAccountTypeOptions).optional(),
  usSmllcElection: z.boolean().optional(),
  usLlcClassification: z.nativeEnum(UsLlcAccountTypeOptions).optional(),
  usOtherClassification: z.string().optional(),
  usTrustEstateEin: z.boolean().optional(),
  foreignAccountType: z
    .nativeEnum(ForeignAccountHolderAccountTypeOptions)
    .optional(),
  foreignTrustClassification: z
    .nativeEnum(ForeignTrustAccountTypeOptions)
    .optional(),
  foreignOtherClassification: z
    .nativeEnum(ForeignOtherAccountTypeOptions)
    .optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
});

export const regardedOwnerSchema = z.object({
  address: unrefinedAddressSchema.optional(),
  countryOfCitizenship: z.string().optional(),
  dateOfBirth: z.string().optional(),
  dbaName: z.string().optional(),
  mailingAddress: unrefinedAddressSchema.optional(),
  mailingAddressIsDifferent: z.literal(true).optional(),
  name: z.string().optional(),
  ftin: z.string().optional(),
  ftinNotLegallyRequired: z.literal(true).optional(),
  tin: z.string().optional(),
  exemptFatcaCode: z.string().optional(),
  exemptPayeeCode: z.string().optional(),
  isUsPerson: z.boolean().optional(),
  usAccountType: z.nativeEnum(UsAccountHolderAccountTypeOptions).optional(),
  usLlcClassification: z.nativeEnum(UsLlcAccountTypeOptions).optional(),
  usOtherClassification: z.string().optional(),
  usTrustEstateEin: z.boolean().optional(),
  foreignAccountType: z
    .nativeEnum(ForeignAccountHolderAccountTypeOptions)
    .optional(),
  foreignTrustClassification: z
    .nativeEnum(ForeignTrustAccountTypeOptions)
    .optional(),
  foreignOtherClassification: z
    .nativeEnum(ForeignOtherAccountTypeOptions)
    .optional(),
});

export const unrefinedComprehensiveTaxDocumentationSchema = z.object({
  accountHolder: accountHolderSchema.optional(),
  regardedOwner: regardedOwnerSchema.optional(),
  documentType: z.literal(KycTaxDocumentType.Comprehensive),
  signature: z.string().optional(),
  signatureDate: z.string().optional(),
  hasCertified: z.boolean().optional(),
  isNotSubjectBackupWithholding: z.boolean().optional(),
  createdAt: dateTimeStringSchema.optional(),
  modifiedAt: dateTimeStringSchema.optional(),
});

export type KycTaxDocumentationAddress = z.infer<typeof unrefinedAddressSchema>;

const w9TaxDocumentationWithUuidSchema =
  unrefinedW9TaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

const w8BenTaxDocumentationWithUuidSchema =
  unrefinedW8BenTaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

const w8BenETaxDocumentationWithUuidSchema =
  unrefinedW8BenETaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

const comprehensiveTaxDocumentationWithUuidSchema =
  unrefinedComprehensiveTaxDocumentationSchema.extend({
    id: uuidSchema,
    createdAt: z.string(),
  });

export const taxDocumentationWithUuidSchema = z.discriminatedUnion(
  "documentType",
  [
    w9TaxDocumentationWithUuidSchema,
    w8BenTaxDocumentationWithUuidSchema,
    w8BenETaxDocumentationWithUuidSchema,
    comprehensiveTaxDocumentationWithUuidSchema,
  ]
);

export type KycTaxDocumentation = z.infer<
  typeof taxDocumentationWithUuidSchema
>;
