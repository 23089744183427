import {
  Transfer,
  TransferLotItem,
  TransferType,
} from "@taxbit-dashboard/rest";
import {
  Card,
  Flex,
  getEnUsErrorEmptyStateProps,
  H4,
  InlineSpinner,
  Table,
  TableColumns,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import useDispositionTableData from "./useDispositionTableData";
import useFormatAsset from "../../../../utils/useFormatAsset";

type DispositionTableProps = {
  transfer: Transfer;
};

export enum DispositionTableTrackingIds {
  NextPageLoadingSpinner = "dispositionTableNextPageLoadingSpinner",
}

export const EMPTY_STATE_SUBTITLE = "Cost Basis is not available yet";

const emptyStateProps = {
  title: "Missing Cost Basis",
  subtitleElement: EMPTY_STATE_SUBTITLE,
  avatarProps: {
    variant: "secondary",
    iconName: "search",
  },
} as const;

const DispositionTable: React.FC<DispositionTableProps> = ({ transfer }) => {
  const { type, accountId, transactionId } = transfer;

  const {
    isLoading,
    transferLotItems,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    totalCostBasis,
  } = useDispositionTableData(type, accountId, transactionId);

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const columns = useMemo(
    (): TableColumns<TransferLotItem> => [
      {
        key: "acquisitionTimestamp",
        label: "Date Acquired",
        renderCell: (acquisitionTimestamp) => {
          return formatDateTime({
            date: acquisitionTimestamp,
            format: "DateTime",
          });
        },
      },
      {
        key: "amountTransferred",
        label: "Outgoing Asset",
        textAlign: "right",
        renderCell: (assetAmount) => {
          return formatAsset({ assetAmount });
        },
      },
      {
        key: "cost",
        label: "Cost Basis",
        textAlign: "right",
        renderCell: (costBasis) => {
          return formatAsset({ assetAmount: costBasis });
        },
      },
    ],
    [formatAsset, formatDateTime]
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingNextPage,
    hasNextPage: !!hasNextPage,
    onLoadMore: () => {
      void fetchNextPage();
    },
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: !!isError,
  });

  // Only show the table if this is a transfer-out
  if (type === TransferType.TransferOut) {
    return (
      <Card
        title="Tax Lots"
        utilityElement={
          transferLotItems.length > 0 && (
            <H4>
              Total: {hasNextPage ? COSMIC_VALUE_PLACEHOLDER : totalCostBasis}
            </H4>
          )
        }
      >
        <TrimmedBoxContent trim="all">
          <Table
            columns={columns}
            rows={transferLotItems}
            getRowKey={(transferLotItem) =>
              transferLotItem.index || transferLotItem.dispositionTransactionId
            }
            emptyStateProps={
              isError
                ? getEnUsErrorEmptyStateProps({ entity: "transfer lots" })
                : emptyStateProps
            }
            isLoading={isLoading}
          />
        </TrimmedBoxContent>
        {isFetchingNextPage && (
          <Flex padding="contentPadding" justifyContent="center">
            <InlineSpinner
              trackingId={DispositionTableTrackingIds.NextPageLoadingSpinner}
            />
          </Flex>
        )}
        <div ref={sentryRef} />
      </Card>
    );
  } else {
    return undefined;
  }
};

export default DispositionTable;
