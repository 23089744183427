import { useDefinedCurrentCompanyUser } from "@taxbit-dashboard/commons";
import {
  ActionButton,
  ActionButtonGroup,
  ContentSpinner,
  EmptyState,
  ErrorEmptyState,
  Table,
  TableColumns,
  optionalAlphabeticalSort,
} from "@taxbit-private/cosmic";
import { useCallback, useState } from "react";

import { OrganizationMemberWithRoles } from "../../../../api/organizationMembersApi";
import { AUTH0_USER_PASS_AUTH } from "../../../../utils/usernamePasswordConnection";
import { useCompanyUserManagementDataContext } from "../context/useCompanyUserManagementData";
import RemoveCompanyUserModal from "../modals/RemoveCompanyUserModal";
import ResetMfaForCompanyUserModal from "../modals/ResetMfaForCompanyUserModal";
import UpdateCompanyUserModal from "../modals/UpdateCompanyUserModal";

enum UserManagementTableTrackingId {
  EditUserButton = "edit-user-button",
  DeleteUserButton = "delete-user-button",
  ResetMfaForUserButton = "reset-mfa-for-user-button",
}

const userManagementTableColumns: TableColumns<OrganizationMemberWithRoles> = [
  {
    key: "name",
    label: "Name",
    sortMethod: optionalAlphabeticalSort,
  },
  {
    key: "email",
    label: "Email",
    sortMethod: optionalAlphabeticalSort,
  },
  {
    key: "roles",
    label: "Roles",
    // Per design, there isn't a super logical way to sort the joined list of roles
    // so we will just disable sorting on this column.
    shouldDisableSorting: true,
    renderCell: (roles) => roles.map(({ name }) => name).join(", "),
  },
];

const CompanyUserManagementTable: React.FC = () => {
  const {
    organizationMembers,
    isLoading: isLoadingOrganizationMembers,
    isError: isErrorOrganizationMembers,
    shouldDisableControls,
  } = useCompanyUserManagementDataContext();

  const currentCompanyUser = useDefinedCurrentCompanyUser();

  const [userToRemove, setUserToRemove] =
    useState<OrganizationMemberWithRoles>();
  const [userToUpdate, setUserToUpdate] =
    useState<OrganizationMemberWithRoles>();
  const [userToMfaReset, setUserToMfaReset] =
    useState<OrganizationMemberWithRoles>();

  const getUserActionButtons = useCallback(
    (user: OrganizationMemberWithRoles) => {
      const isCurrentCompanyUser = user.id === currentCompanyUser?.id;

      // Only Username-Password users are able to have their MFA reset
      const hasUsernamePasswordConnection =
        user.connectionStrategy === AUTH0_USER_PASS_AUTH;
      return (
        <ActionButtonGroup>
          <ActionButton
            iconName="edit"
            trackingId={`${UserManagementTableTrackingId.EditUserButton}-${user.id}`}
            onClick={() => setUserToUpdate(user)}
            label="Edit"
          />
          <ActionButton
            iconName="lock"
            trackingId={`${UserManagementTableTrackingId.ResetMfaForUserButton}-${user.id}`}
            onClick={() => setUserToMfaReset(user)}
            isDisabled={!hasUsernamePasswordConnection}
            label="Reset MFA"
            tooltipProps={{
              content: "SSO users not eligible for MFA",
              isDisabled: hasUsernamePasswordConnection,
            }}
          />
          <ActionButton
            iconName="trash"
            trackingId={`${UserManagementTableTrackingId.DeleteUserButton}-${user.id}`}
            onClick={() => setUserToRemove(user)}
            isDisabled={isCurrentCompanyUser}
            label="Delete"
            tooltipProps={{
              content: "You cannot delete yourself",
              isDisabled: !isCurrentCompanyUser,
            }}
          />
        </ActionButtonGroup>
      );
    },
    [currentCompanyUser]
  );

  if (isLoadingOrganizationMembers) {
    return <ContentSpinner />;
  } else if (isErrorOrganizationMembers) {
    return <ErrorEmptyState />;
  } else if (organizationMembers?.length) {
    return (
      <>
        <Table
          rows={organizationMembers}
          columns={userManagementTableColumns}
          initialSortColumnKey="name"
          getRowKey={(row) => row.id}
          getRowUtilityElement={getUserActionButtons}
          isLoading={shouldDisableControls}
          isSortable={true}
        />
        {userToRemove && (
          <RemoveCompanyUserModal
            onClose={() => setUserToRemove(undefined)}
            isOpen={!!userToRemove}
            user={userToRemove}
          />
        )}
        {userToMfaReset && (
          <ResetMfaForCompanyUserModal
            onClose={() => setUserToMfaReset(undefined)}
            isOpen={!!userToMfaReset}
            user={userToMfaReset}
          />
        )}
        {userToUpdate && (
          <UpdateCompanyUserModal
            onClose={() => setUserToUpdate(undefined)}
            isOpen={!!userToUpdate}
            user={userToUpdate}
          />
        )}
      </>
    );
  } else {
    return (
      <EmptyState
        avatarProps={{ iconName: "user", variant: "secondary" }}
        title="No users found."
      />
    );
  }
};

export default CompanyUserManagementTable;
