import { fourDigitYearSchema } from "@taxbit-private/datetime";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../../getPublicApiSuccessSchema";
import { dashboardFormTypeSchema } from "../formMetadataApiTypes";
import { IrFormTypeApiParams } from "../irRestApiTypes";
import { OffsetPaginationApiParams, accountIdSchema } from "../sharedApiTypes";

const formsAggregatesSchema = z.object({
  documentType: dashboardFormTypeSchema,
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  original: z.number(),
  correction: z.number(),
  voided: z.number(),
  total: z.number(),
});

export type FormsAggregates = z.infer<typeof formsAggregatesSchema>;

export const getFormsAggregatesResponseSchema = getPublicApiSuccessSchema(
  formsAggregatesSchema
);

export const getFormsSearchKeySchema = z.enum(["account_external_id", "name"]);
export type GetFormsSearchKey = z.infer<typeof getFormsSearchKeySchema>;

export const getFormsSortKeySchema = z.enum(["date_generated"]);
export type GetFormsSortKey = z.infer<typeof getFormsSortKeySchema>;

export const getFormsSortSchema = z.enum([
  ...getFormsSortKeySchema.options,
  ...getFormsSortKeySchema.options.map((opt) => `-${opt}` as const),
]);
export type GetFormsSort = z.infer<typeof getFormsSortSchema>;

export enum IrFormStatus {
  Generated = "GENERATED",
  Filed = "FILED",
}
export const irFormStatusSchema = z.nativeEnum(IrFormStatus);

export enum IrFormType {
  Original = "ORIGINAL",
  Correction = "CORRECTION",
  Void = "VOID",
}
export const irFormTypeSchema = z.nativeEnum(IrFormType);

export type GetIrFormsParams = IrFormTypeApiParams &
  OffsetPaginationApiParams & {
    "sort"?: GetFormsSort;
    "filters[account_external_id]"?: string;
    "filters[name]"?: string;
    "filters[status]"?: IrFormStatus[];
    "filters[type]"?: IrFormType[];
    "filters[date_generated][$gte]"?: string;
    "filters[date_generated][$lte]"?: string;
  };

export const revisionKeySchema = z.string().brand("RevisionKey");
export type RevisionKey = z.infer<typeof revisionKeySchema>;

const irFormSchema = z.object({
  id: uuidSchema,
  accountId: accountIdSchema,
  accountExternalId: z.string().optional(),
  name: z.string().optional(),
  status: irFormStatusSchema,
  type: irFormTypeSchema,
  dateGenerated: z.string().datetime().optional(),
  documentDate: z.number().transform((year) => fourDigitYearSchema.parse(year)),
  documentType: dashboardFormTypeSchema,
  revisionKey: revisionKeySchema,
});

export type IrForm = z.infer<typeof irFormSchema>;

export const getIrFormsResponseSchema = getPublicApiSuccessSchema(
  irFormSchema.array()
);

export const createIrFormsExportResponseSchema = getPublicApiSuccessSchema(
  z.object({
    exportId: uuidSchema,
  })
);

const irFormsExportResponseSchema = z.object({
  presignedUrl: z.string().optional(),
  status: z.enum(["PROCESSING", "FINISHED"]),
});

export const getIrFormsExportResponseSchema = getPublicApiSuccessSchema(
  irFormsExportResponseSchema
);
const irFormKeysSchema = z.object({
  accountId: accountIdSchema,
  revisionKey: revisionKeySchema,
});
export type IrFormKeys = z.infer<typeof irFormKeysSchema>;

export const rescindIrFormsRequestSchema = getPublicApiSuccessSchema(
  z.object({
    taxForms: irFormKeysSchema.array(),
  })
);
export type RescindIrFormsRequest = z.infer<typeof rescindIrFormsRequestSchema>;

export const rescindIrFormsResponseSchema = getPublicApiSuccessSchema(
  z.object({
    failed: irFormKeysSchema.array(),
  })
);

export const downloadIrFormsRequestSchema = getPublicApiSuccessSchema(
  z.object({
    taxFormKeys: irFormKeysSchema.array().optional(),
    documentType: dashboardFormTypeSchema,
    documentYear: z
      .number()
      .transform((year) => fourDigitYearSchema.parse(year)),
  })
);
export type DownloadIrFormsRequest = z.infer<
  typeof downloadIrFormsRequestSchema
>;
