import z from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum UmcApiReportType {
  TaxDocumentationStatus = "tax-documentation-status",
  TinVerification = "tin-verification",
  Custom = "custom",
}

export const umcReportTypeSchema = z.nativeEnum(UmcApiReportType);

export enum UmcApiReportSubtype {
  Full = "full",
  ActionNeeded = "action-needed",
  Unknown = "unknown",
}

export const umcApiReportSchema = z.object({
  key: z.string(),
  date: z.string().optional(),
  type: z.nativeEnum(UmcApiReportType),
  subtype: z.nativeEnum(UmcApiReportSubtype).optional(),
});

export const getUmcReportsResponseSchema = getPublicApiSuccessSchema(
  umcApiReportSchema.array()
);

export type UmcApiReport = z.infer<typeof umcApiReportSchema>;

export const getUmcReportUrlSchema = getPublicApiSuccessSchema(
  z.object({
    url: z.string(),
  })
);
