import z from "zod";

export const DEFAULT_PAGE_NUM = 1;

/**
 * Coerces a primitive into an number and validates that it could be a
 * valid page number. If it does not, the value will default to 1.
 */
const pageNumberSchema = z.coerce.number().int().min(1).catch(DEFAULT_PAGE_NUM);

export default pageNumberSchema;
