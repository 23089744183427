import { zodResolver } from "@hookform/resolvers/zod";
import {
  useDashboardFeatureFlags,
  useDashboardStore,
  useUrlFilterParams,
} from "@taxbit-dashboard/commons";
import {
  FileType,
  FilesApiFile,
  DashboardFormType,
  FileAction,
  ingestFileTypes,
} from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { createFileUploaderFormFieldsSchema } from "./fileUploaderFormFieldsSchema";
import { FileUploaderFormFields } from "./fileUploaderFormTypes";
import useFileUploaderFormDefaults from "./useFileUploaderFormDefaults";
import useGetFileData from "./useGetFileData";
import useTemplatesFeatureFlagData from "./useTemplatesFeatureFlagData";
import validateFilesTableUrlParams from "./validateFilesTableUrlParams";
import { useGetFiles, useUploadFile } from "../../../api/files/filesApi";
import {
  fileActionFileTypesMap,
  fileTypeLabelMap,
} from "../../../api/files/filesApiTypes";
import { getTotalParts } from "../../../api/files/filesApiUtils";
import { TOAST_TIMEOUT } from "../../../utils/toastTimeout";
import useFormDataUploaderDropdowns from "../../information-reporting/shared/useFormDataUploaderDropdowns";
import validateFile from "../file-uploader/validateFile";

const useFileUploader = (action: FileAction) => {
  const [file, setFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isValidating, setIsValidating] = useState(false);
  const [completedParts, setCompletedParts] = useState(0);
  const [currentUploadId, setCurrentUploadId] = useState<string>();
  const [selectedFileTypeLegacy, setSelectedFileTypeLegacy] =
    useState<FileType>(
      action === FileAction.Ingest
        ? FileType.Accounts
        : FileType.DeleteAccountOwners
    );

  const { getTemplatesForFileType } = useTemplatesFeatureFlagData();

  const { filteredFileTypes, defaultValues } = useFileUploaderFormDefaults({
    action,
  });

  const {
    hasIngestionTemplateSelectionAccess,
    shouldSkipFileHeaderValidation,
  } = useDashboardFeatureFlags();

  const formMethods = useForm<FileUploaderFormFields>({
    resolver: zodResolver(
      createFileUploaderFormFieldsSchema({ shouldSkipFileHeaderValidation })
    ),
    defaultValues,
  });

  const selectedFileType = hasIngestionTemplateSelectionAccess
    ? formMethods.watch("fileType")
    : selectedFileTypeLegacy;

  const hasMultipleTemplatesForSelectedFileType = useMemo(() => {
    const templates = getTemplatesForFileType(selectedFileType);
    return templates.length > 1;
  }, [getTemplatesForFileType, selectedFileType]);

  const hasIngestionTemplates = useMemo(() => {
    return ingestFileTypes.some(
      (fileType) => getTemplatesForFileType(fileType).length > 0
    );
  }, [getTemplatesForFileType]);

  const { urlParams, setUrlParams } = useUrlFilterParams({
    validateParams: validateFilesTableUrlParams,
  });

  const languageForFormType = selectedFileType
    ? fileTypeLabelMap[selectedFileType].toLowerCase()
    : "";

  const {
    forms,
    currentForm,
    years,
    currentYear,
    setCurrentForm,
    setCurrentYear,
  } = useFormDataUploaderDropdowns();

  const getFileData = useGetFileData();

  const addToast = useDashboardStore((store) => store.addToast);

  const getFilesParams = {
    fileType: fileActionFileTypesMap[action],
    ...urlParams,
  };
  const {
    data: filteredFiles,
    meta,
    ...filteredQuery
  } = useGetFiles(getFilesParams);

  const setFileTypeFilter = useCallback(
    (newFileType?: FileType) =>
      setUrlParams((draft) => {
        draft.fileType = newFileType ? [newFileType] : undefined;
        draft.page = 1;
      }),
    [setUrlParams]
  );

  const currentlyUploadingFile = hasIngestionTemplateSelectionAccess
    ? formMethods.watch("files")[0]
    : file;

  const { mutate: uploadFile, isLoading } = useUploadFile({
    onUploadStart: (uploadId) => {
      setCurrentUploadId(uploadId);
      setFileTypeFilter(undefined);

      addToast({
        message: `File ${currentlyUploadingFile?.name} is currently uploading. Do not close this window or navigate away from TaxBit until the upload is complete.`,
        timeoutMs: TOAST_TIMEOUT,
        trackingId: "upload-started-toast",
      });
    },
    onChunkUploaded: () => setCompletedParts((prev) => prev + 1),
    onUploadComplete: () => {
      setCompletedParts(0);
      setCurrentUploadId(undefined);
    },
  });

  const isUploading = isLoading || isValidating;

  const progress = useMemo(() => {
    const totalParts = getTotalParts(file?.size ?? 0);
    return file ? Math.ceil((completedParts / totalParts) * 100) : 0;
  }, [file, completedParts]);

  const isStaleUpload = useCallback(
    (upload: FilesApiFile) =>
      !!(upload.uploadId && currentUploadId !== upload.uploadId),
    [currentUploadId]
  );

  const onSubmitForm = useCallback(
    (e: React.FormEvent) => {
      void formMethods.handleSubmit(
        ({ files: [fileToUpload], ...otherFields }) => {
          const requestData = getFileData({
            file: fileToUpload,
            ...otherFields,
          });

          if (requestData) {
            uploadFile(
              {
                requestData,
                file: fileToUpload,
              },
              {
                onSuccess: () => {
                  addToast({
                    message: `File ${
                      fileToUpload.name
                    } has been successfully uploaded. We are now validating the format of your ${languageForFormType}, and then your file will be ready for approval. Before we ${action.toLowerCase()} your ${languageForFormType}, you will need to review and approve the file.`,
                    trackingId: "upload-succeeded-toast",
                    timeoutMs: TOAST_TIMEOUT,
                  });
                  formMethods.reset();
                },
                onError: (error) => {
                  if (error.detailedErrors) {
                    // It is possible for us to have multiple errors on a single attempted upload,
                    // but we only want to show one message to not overwhelm the user.
                    const errorText = error.detailedErrors[0].detail;

                    if (errorText) {
                      formMethods.setError("files", {
                        message: errorText,
                      });
                    }
                  } else {
                    addToast({
                      message: `Failed to upload ${fileToUpload.name}. Please try again later.`,
                      trackingId: "upload-failed-toast",
                      timeoutMs: TOAST_TIMEOUT,
                      variant: "danger",
                    });
                  }
                },
              }
            );
          }
        }
      )(e);
    },
    [
      action,
      addToast,
      formMethods,
      getFileData,
      languageForFormType,
      uploadFile,
    ]
  );

  const onUpload = useCallback(async () => {
    setIsValidating(true);
    const validationError = await validateFile({
      fileType: selectedFileType,
      file,
      templateType: currentForm,
      shouldSkipFileHeaderValidation,
      hasIngestionTemplateSelectionAccess,
    });
    setIsValidating(false);

    if (validationError) {
      setErrorMessage(validationError);
      return;
    } else {
      setErrorMessage(undefined);
    }

    // We shouldn't ever see an undefined file at this point,
    // but we have this check to appease TS.
    if (!file) {
      return;
    }

    const requestData = getFileData({
      file,
      fileType: selectedFileType,
      formDocumentType: currentForm,
      formTaxYear: currentYear,
    });

    if (requestData) {
      uploadFile(
        {
          requestData,
          file,
        },
        {
          onSuccess: () => {
            addToast({
              message: `File ${
                file.name
              } has been successfully uploaded. We are now validating the format of your ${languageForFormType}, and then your file will be ready for approval. Before we ${action.toLowerCase()} your ${languageForFormType}, you will need to review and approve the file.`,
              trackingId: "upload-succeeded-toast",
              timeoutMs: TOAST_TIMEOUT,
            });
            setCurrentForm(undefined);
            setCurrentYear(undefined);
            setFile(undefined);
          },
          onError: (error) => {
            if (error.detailedErrors) {
              // It is possible for us to have multiple errors on a single attempted upload,
              // but we only want to show one message to not overwhelm the user.
              const errorText = error.detailedErrors[0].detail;

              if (errorText) {
                setErrorMessage(errorText);
              }
            } else {
              addToast({
                message: `Failed to upload ${file.name}. Please try again later.`,
                trackingId: "upload-failed-toast",
                timeoutMs: TOAST_TIMEOUT,
                variant: "danger",
              });
            }
          },
        }
      );
    }
  }, [
    selectedFileType,
    file,
    currentForm,
    shouldSkipFileHeaderValidation,
    hasIngestionTemplateSelectionAccess,
    getFileData,
    currentYear,
    uploadFile,
    addToast,
    languageForFormType,
    action,
    setCurrentForm,
    setCurrentYear,
  ]);

  const onChange = useCallback((files: File[]) => {
    const newFile = files[0];

    // If the current file has just been removed, ensured we don't have a lingering
    // error alert showing.
    if (!newFile) {
      setErrorMessage(undefined);
    }

    setFile(newFile);
  }, []);

  const isUploadDisabled =
    !file ||
    !!errorMessage ||
    ((selectedFileType === FileType.Forms ||
      selectedFileType === FileType.DeleteForms) &&
      (!currentForm || !currentYear));

  return {
    errorMessage,
    files: file ? [file] : [],
    /**
     * We must disabled the upload button if we don't have the current list of
     * files to ensure we can safely block duplicate filenames from being uploaded.
     *
     * The button is also disabled when we have no file selected or when an error
     * message is currently being shown.
     */
    isUploadDisabled,
    onChange,
    onUpload,
    isUploading,
    progress,
    isStaleUpload,
    selectedFileType,
    setSelectedFileType: (fileType: FileType) => {
      setSelectedFileTypeLegacy(fileType);
      setErrorMessage(undefined);
    },
    forms,
    currentForm,
    setCurrentForm: (form: DashboardFormType) => {
      setCurrentForm(form);
      setErrorMessage(undefined);
    },
    years,
    currentYear,
    setCurrentYear: (year: FourDigitYear) => {
      setCurrentYear(year);
      setErrorMessage(undefined);
    },
    fileTypeFilter: urlParams.fileType,
    setFileTypeFilter,
    urlParams,
    setUrlParams,
    filteredFiles,
    totalFilteredFilesCount: meta?.page?.totalCount ?? 0,
    filteredQuery,
    formMethods,
    onSubmitForm,
    hasIngestionTemplates,
    hasMultipleTemplatesForSelectedFileType,
    filteredFileTypes,
  };
};

export default useFileUploader;
