import {
  Body,
  Checkbox,
  Divider,
  Flex,
  Modal,
  NumericalRowGroup,
} from "@taxbit-private/cosmic";
import pluralize from "pluralize";
import { useMemo, useState } from "react";

import useGenerateTaxFormsConfirmationModal, {
  GenerateTaxFormsConfirmationModalTrackingId,
} from "./useGenerateTaxFormsConfirmationModal";

type GenerateTaxFormsConfirmationModalProps = {
  shouldIgnoreSelectedItems?: boolean;
  onClose: () => void;
  isOpen: boolean;
};

const GenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  onClose,
  isOpen,
}: GenerateTaxFormsConfirmationModalProps) => {
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const {
    handleSubmit,
    totalAccountsLabel,
    totalAccounts,
    totalFilteredAccounts,
    isLoading,
    hasFiltersApplied,
  } = useGenerateTaxFormsConfirmationModal({
    shouldIgnoreSelectedItems,
    shouldIncludeFilters: isCheckboxChecked,
    onClose,
  });

  const totalRow = useMemo(
    () =>
      ({
        label: totalAccountsLabel,
        value: totalAccounts,
        key: "total",
        variant: "bold",
      }) as const,
    [totalAccountsLabel, totalAccounts]
  );

  return (
    <Modal
      title="Generate Forms for Eligible Accounts"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        GenerateTaxFormsConfirmationModalTrackingId.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Generate Forms",
        trackingId: GenerateTaxFormsConfirmationModalTrackingId.SubmitButton,
        isLoading,
        loadingText: "Processing",
        variant: "button-primary",
        onClick: handleSubmit,
      }}
    >
      <Flex direction="column" gap="m">
        <Body>
          By clicking &quot;Generate Forms&quot; below, a form will be generated
          for all selected eligible accounts with complete account data.
        </Body>
        <Body>
          A form will not be generated for the following:
          <ul>
            <li>
              Eligible accounts with an existing form, for which data has not
              changed
            </li>
            <li>Eligible accounts with incomplete account data</li>
            <li>Eligible accounts that have been removed from scope</li>
            <li>Ineligible accounts (e.g., Exempt, Non-US, Not Reportable)</li>
          </ul>
        </Body>
        {shouldIgnoreSelectedItems && hasFiltersApplied && (
          <>
            <Divider />
            <Checkbox
              isChecked={isCheckboxChecked}
              label={`Generate forms for filtered population only (${totalFilteredAccounts} ${pluralize(
                "account",
                totalFilteredAccounts
              )})`}
              onChange={() => setIsCheckboxChecked((prev) => !prev)}
              trackingId={GenerateTaxFormsConfirmationModalTrackingId.Checkbox}
            />
          </>
        )}
        <Divider />
        <NumericalRowGroup rows={[totalRow]} />
      </Flex>
    </Modal>
  );
};

export default GenerateTaxFormsConfirmationModal;
