import { useNavigate, useSearch } from "@tanstack/react-location";
import { DEFAULT_PAGE_NUM, readonlyIncludes } from "@taxbit-dashboard/commons";
import { getEligibilitySearchKeySchema } from "@taxbit-dashboard/rest";
import { produce } from "immer";
import { useCallback, useMemo } from "react";

import validateEligibilityTableUrlParams, {
  EligibilityTableGenerics,
} from "./validateEligibilityTableUrlParams";
import { useGetEligibilityData } from "../../../../api/information-reporting/eligible-users/eligibilityApi";
import {
  EligibilityTableParams,
  eligibilityEnumParamKeys,
} from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import useIrDropdowns from "../../shared/useIrDropdowns";

const useEligibilityTableData = () => {
  const { currentForm, currentYear } = useIrDropdowns();
  const formParams = useMemo(
    () => ({
      formDate: currentYear,
      formType: currentForm,
    }),
    [currentForm, currentYear]
  );

  const rawUrlParams = useSearch<EligibilityTableGenerics>();
  const navigate = useNavigate();

  const setUrlParams = useCallback(
    (setter: (draft: EligibilityTableParams) => void) => {
      navigate({
        search: (params) => {
          const validatedParams = validateEligibilityTableUrlParams(
            params ?? {}
          );
          return produce({ ...validatedParams, ...formParams }, setter);
        },
      });
    },
    [navigate, formParams]
  );

  const setFilterParams = useCallback(
    (setter: (draft: Omit<EligibilityTableParams, "page">) => void) => {
      setUrlParams((draft) => {
        setter(draft);
        draft.page = DEFAULT_PAGE_NUM;
      });
    },
    [setUrlParams]
  );

  const clearAllFilters = useCallback(
    () =>
      setFilterParams((draft) => {
        for (const key of getEligibilitySearchKeySchema.options) {
          draft[key] = undefined;
        }

        for (const key of eligibilityEnumParamKeys) {
          draft[key] = [];
        }
      }),
    [setFilterParams]
  );

  const urlParams = useMemo(
    () => validateEligibilityTableUrlParams(rawUrlParams),
    [rawUrlParams]
  );

  const query = useGetEligibilityData({
    ...urlParams,
    ...formParams,
  });

  const filterDrawerFilterCount = useMemo(() => {
    return Object.keys(urlParams).reduce((count, key) => {
      if (readonlyIncludes(eligibilityEnumParamKeys, key) && urlParams[key]) {
        const selectedOptionCount = urlParams[key]?.length ?? 0;
        return count + selectedOptionCount;
      } else {
        return count;
      }
    }, 0);
  }, [urlParams]);

  const memoizedUrlParams = useMemo(
    () => ({ ...urlParams, ...formParams }),
    [urlParams, formParams]
  );

  return {
    ...query,
    /**
     * The validated and defaulted set of URL params derived from the current URL.
     * These params may not match the URL exactly, but represents the set of params
     * that will be used to fetch accounts.
     */
    urlParams: memoizedUrlParams,
    /**
     * A setter for pagination url params. Takes in a setter fn that uses Immer to
     * update a draft of the current parsed url params. The param name for the setter
     * function MUST begin with draft to avoid eslint errors about mutating function params.
     *
     * ex: (draft) => { draft.page += 1; }
     */
    setPaginationParams: setUrlParams,
    /**
     * A setter for filter url params. Takes in a setter fn that uses Immer to update a draft of
     * the current parsed url params, and resets the current page so that the user will immediately
     * view the first page of the new results. The param name for the setter function MUST begin with
     * draft to avoid eslint errors about mutating function params.
     *
     * ex: (draft) => { draft["name"] = "Mr Duck"; }
     */
    setFilterParams,
    /**
     * Clears all filter params and resets the current page.
     */
    clearAllFilters,
    /**
     * Indicates whether we should disable the controls because we are loading new results or
     * loading a new view that displays controls (ex: we already have filter params in the URL).
     */
    shouldDisableControls: query.isLoading || query.isPreviousData,
    /**
     * The total count of results for the given set of filter params.
     */
    totalCount: query.meta?.page?.totalCount ?? 0,
    /**
     * The total count of filters applied via the filter drawer.
     */
    filterDrawerFilterCount,
  };
};

export default useEligibilityTableData;
