import { AccountValidationsEligibilityStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { EligibilityFiltersFormFields } from "./eligibilityFiltersFormFieldSchema";
import { accountValidationsEligibilityStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

export enum AccountValidationsEligibilityStatusFilterTrackingIds {
  Multiselect = "irAccountValidationsEligibilityStatusFilterMultiselect",
}

const AccountValidationsEligibilityStatusFilter = () => {
  const options = useMemo(() => {
    return Object.values(AccountValidationsEligibilityStatus).map(
      (accountValidationsEligibilityStatus) => ({
        key: accountValidationsEligibilityStatus,
        label:
          accountValidationsEligibilityStatusLabelMap[
            accountValidationsEligibilityStatus
          ],
      })
    );
  }, []);

  return (
    <RhfMultiselect<
      AccountValidationsEligibilityStatus,
      Pick<
        EligibilityFiltersFormFields,
        "accountValidationsEligibilityStatuses"
      >,
      "accountValidationsEligibilityStatuses",
      (typeof options)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="accountValidationsEligibilityStatuses"
      options={options}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Account Validation"
      placeholder="All"
      trackingId={
        AccountValidationsEligibilityStatusFilterTrackingIds.Multiselect
      }
    />
  );
};

export default AccountValidationsEligibilityStatusFilter;
