import { useLocation } from "@tanstack/react-location";
import { useEffect, useState } from "react";

import useIsSubrouteMatch from "./useSubrouteMatch";
import {
  PageContextPageKey,
  pageContextPageKeyMap,
} from "../../store/page-context/pageContextSliceModel";
import useDashboardStore from "../../store/useDashboardStore";
import usePageScroll from "../../utils/usePageScroll";

/**
 * All pages that sit alongside our left navigation sidebars share a single scroll container,
 * so scroll position is automatically persisted when navigating between routes if
 * the scroll location is available on both routes. For example, if you scroll 300px
 * down on a page and then navigate to a page that is tall enough to also be scrolled
 * 300px down, then the new page will initialize while partially scrolled. To mitigate this,
 * we explicitly scroll to the top on every page change if there's no conflicting scroll state.
 */
const useScrollPagesToTop = () => {
  const { scrollPageToTop } = usePageScroll();
  const {
    current: { pathname },
  } = useLocation();
  const [location, setLocation] = useState(pathname);

  const scrollPositions = useDashboardStore((store) => store.scrollPositions);

  const isSubrouteMatch = useIsSubrouteMatch();

  useEffect(() => {
    // If we are on the same page as the last time we scrolled, do not
    // scroll to the top of the page. This is to handle the global event
    // from ingestion module which refreshes a page automatically.
    if (pathname === location) {
      return;
    }

    const [matchingPageKey] =
      (Object.entries(pageContextPageKeyMap).find(([, route]) =>
        isSubrouteMatch(route)
      ) as [PageContextPageKey, string] | undefined) ?? [];

    // If we're on a page that has a scroll position stored, do not
    // scroll to the top of the page because we will already be scrolling
    // to a different part of it.
    if (!matchingPageKey || !scrollPositions[matchingPageKey]) {
      scrollPageToTop();
    }

    setLocation(pathname);
  }, [isSubrouteMatch, pathname, scrollPageToTop, scrollPositions, location]);
};

export default useScrollPagesToTop;
