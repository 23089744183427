import { useCallback, useRef } from "react";

import NotificationsCenterContents from "./NotificationsCenterContents";
import TopNavigationPopover from "../../app/navigation/top-navigation/shared/TopNavigationPopover";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

export enum NotificationsCenterTrackingId {
  ToggleButton = "notifications-center-toggle-button",
}

const NotificationsCenter: React.FC = () => {
  const { hasUnreadNotifications } = useNotificationsContext(
    NotificationsContext.Center
  );

  const targetButtonRef = useRef<HTMLButtonElement>(null);

  // Simulates a button click to close the popover when navigating to the notifications page.
  const handleClose = useCallback(() => {
    if (targetButtonRef.current) {
      targetButtonRef.current.click();
    }
  }, []);

  return (
    <TopNavigationPopover
      targetButtonProps={{
        iconName: "bell",
        trackingId: NotificationsCenterTrackingId.ToggleButton,
        label: "Notifications",
      }}
      targetButtonRef={targetButtonRef}
      contents={<NotificationsCenterContents handleClose={handleClose} />}
      hasDot={hasUnreadNotifications}
    />
  );
};

export default NotificationsCenter;
