import { isDefined } from "@taxbit-dashboard/commons";
import {
  FileType,
  DashboardFormType,
  AccountsFileType,
  AccountsTemplateType,
  TransactionsTemplateType,
} from "@taxbit-dashboard/rest";

import parseAsPromise from "../../../utils/parseAsPromise";
import {
  csvHeadersMap,
  getCsvTemplateHeaders,
  hasCsvTemplateDownloadUrl,
} from "../csvTemplateData";

export const MAX_FILE_SIZE = 5e9; // 5 GB
export const MAX_HEADER_VALIDATION_SIZE = 5e8; // 500 MB
export const MAX_ACCOUNTS_FILE_SIZE = 12e7; // 120 MB

export const getFilenameParts = (filename: string) => {
  const parts = filename.split(".");

  // The filename can contain periods, so we need to rejoin all sections
  // up until the last split that delineates the extension.
  const prefix = parts.slice(0, -1).join("");

  return { prefix, extension: parts.pop() ?? "" };
};

export const FileUploadError = {
  TooLarge:
    "The file you tried to upload is too large. Please upload a file smaller than 5GB.",
  AccountsFileTooLarge:
    "The accounts file you tried to upload is too large. Please upload a file smaller than 120MB.",
  NoFile: "You must upload a file to continue.",
  IncorrectHeaders:
    "The file you tried to upload has incorrect headers. Please upload your file again using the template.",
  InvalidExtension:
    "Files uploaded must be one of the following types: .csv, .jsonl",
  InvalidExtensionCsv: "File must be a .csv file.",
  MissingTemplate: "Please select a template.",
} as const;

/**
 * Validates the given file according to the minimal requirements for the
 * MVP release of the transactions uploader. Namely, that the file is an
 * appropriate size and that the file's header row mirrors the generic CSV
 * template.
 *
 * This method returns an error message if a validation error exists, or undefined
 * if the file is valid.
 */
const validateFile = async ({
  hasIngestionTemplateSelectionAccess,
  shouldSkipFileHeaderValidation,
  fileType,
  file,
  templateType,
}: {
  hasIngestionTemplateSelectionAccess: boolean;
  shouldSkipFileHeaderValidation: boolean;
  fileType: FileType;
  file?: File;
  templateType?:
    | DashboardFormType
    | AccountsTemplateType
    | TransactionsTemplateType;
}) => {
  // This case shouldn't be possible because we disable the upload button without a
  // file, but we need to account for it anyway to appease TypeScript.
  if (!file) {
    return FileUploadError.NoFile;
  }

  const { extension } = getFilenameParts(file.name);

  if (file.size > MAX_FILE_SIZE) {
    return FileUploadError.TooLarge;
  }

  if (fileType === FileType.Accounts && file.size > MAX_ACCOUNTS_FILE_SIZE) {
    return FileUploadError.AccountsFileTooLarge;
  }

  if (extension === "csv") {
    /**
     * Due to an outstanding issue in Papaparse, we need to skip CSV header validation for
     * larger files or Papaparse throws a seemingly unrelated error. See issue here:
     * https://github.com/mholt/PapaParse/issues/1014
     *
     * NOTE: The backend expects CSV files to contain the correct headers, so large files that
     * skip validation may get stuck in a "validating" state. This tradeoff is hopefully temporary.
     */
    if (file.size > MAX_HEADER_VALIDATION_SIZE) {
      return undefined;
    }

    /**
     * This is an internal flag that allows skipping header validation. This should only
     * be used by devs to unblock uploading new file types for testing in the backend.
     */
    if (shouldSkipFileHeaderValidation) {
      return undefined;
    }

    const results = await parseAsPromise(file, {
      preview: 1,
    });

    const headers = results.data[0];

    // Papaparse will read in additional columns as blank extra headers, but
    // these should be ignored for the purposes of validation.
    const populatedHeaders = headers?.filter((h) => !!h);

    if (hasIngestionTemplateSelectionAccess) {
      const templateHeaders = getCsvTemplateHeaders({ fileType, templateType });

      if (templateHeaders) {
        const doHeadersMatch =
          templateHeaders?.length === populatedHeaders?.length &&
          templateHeaders?.every(
            (header, idx) => header === populatedHeaders?.[idx]
          );

        if (doHeadersMatch) {
          return undefined;
        } else {
          return FileUploadError.IncorrectHeaders;
        }
      } else {
        return FileUploadError.MissingTemplate;
      }
    }

    const expectedHeadersForType = (() => {
      if (
        fileType === FileType.Transactions ||
        fileType === FileType.DeleteTransactions ||
        fileType === FileType.DeleteAccounts ||
        fileType === FileType.DeleteAccountOwners ||
        fileType === FileType.DeleteForms
      ) {
        return [csvHeadersMap[fileType]];
      } else if (
        fileType === FileType.Forms &&
        hasCsvTemplateDownloadUrl(templateType)
      ) {
        return [csvHeadersMap[templateType]];
      } else if (fileType === FileType.Accounts) {
        return [
          csvHeadersMap[AccountsFileType.AccountOwner],
          csvHeadersMap[AccountsFileType.AccountOwnerIra],
          csvHeadersMap[AccountsFileType.AccountOwnerEu],
        ];
      } else {
        return undefined;
      }
    })();

    // This case shouldn't be possible because we disable the Uploader until all of the
    // headers have been loaded in, but we need to account for it anyway to appease TypeScript.
    if (
      !expectedHeadersForType ||
      expectedHeadersForType.some((h) => !isDefined(h))
    ) {
      return FileUploadError.IncorrectHeaders;
    }

    const doHeadersMatch = expectedHeadersForType.some(
      (expectedHeaders) =>
        expectedHeaders?.length === populatedHeaders?.length &&
        expectedHeaders?.every(
          (header, idx) => header === populatedHeaders?.[idx]
        )
    );

    if (doHeadersMatch) {
      return undefined;
    } else {
      return FileUploadError.IncorrectHeaders;
    }
  } else if (fileType === FileType.Transactions && extension === "jsonl") {
    return undefined;
  } else if (
    fileType === FileType.Forms ||
    fileType === FileType.Accounts ||
    fileType === FileType.DeleteTransactions ||
    fileType === FileType.DeleteAccounts ||
    fileType === FileType.DeleteAccountOwners ||
    fileType === FileType.DeleteForms
  ) {
    return FileUploadError.InvalidExtensionCsv;
  } else {
    // This case shouldn't be possible because we block the Uploader from allowing any files
    // but .csv and .jsonl, but we need to account for it anyway to appease Eslint.
    return FileUploadError.InvalidExtension;
  }
};

export default validateFile;
