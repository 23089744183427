import {
  defaultDashboardFeatureFlagSet,
  isProdEnvironment,
  logError,
} from "@taxbit-dashboard/commons";
import { CompanyUser } from "@taxbit-dashboard/rest";
import { LDClientBase } from "launchdarkly-js-sdk-common";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

export const initializeLaunchDarkly = () =>
  asyncWithLDProvider({
    clientSideID: isProdEnvironment()
      ? "62fa7f71fa9b0111cb64d7c4"
      : "62fa7f71fa9b0111cb64d7c3",
    flags: defaultDashboardFeatureFlagSet,
    context: {
      kind: "user",
      // This key is important, it ensurse that each initialized LaunchDarkly
      // session does not increment our total count for any unauthenticated user who opens the app.
      // Following best practice outlined here in the docs:
      // https://docs.launchdarkly.com/sdk/features/user-config#designating-anonymous-users
      key: "unique-anonymous-user-key",
    },
  });

export const updateLaunchDarklyUser = async (
  { id, email, name }: CompanyUser,
  organizationId: string,
  organizationName: string,
  ldClient: LDClientBase
) => {
  try {
    // LaunchDarkly is supposed to be intelligent enough
    // that if this call fails, it can still operate in a reasonable manner,
    // which is why we don't throw an error.
    // https://launchdarkly.com/blog/designing-for-failure-to-avoid-disaster/
    await ldClient.identify({
      kind: "user",
      key: id,
      email,
      name,
      organizationId,
      organizationName,
    });
  } catch (error) {
    logError({ message: "Unable to update LaunchDarkly user", error });
  }
};
