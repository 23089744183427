import { navigateToUrl, useDashboardStore } from "@taxbit-dashboard/commons";
import { useState } from "react";

import {
  useGenerateEligibilityExport,
  useGetEligibilityExport,
} from "../../../../api/information-reporting/eligible-users/eligibilityExportApi";
import { TOAST_TIMEOUT } from "../../../../utils/toastTimeout";
import { useEligibilityDataContext } from "../context/useEligibilityData";

export enum DownloadEligibilityTableTrackingId {
  ErrorToast = "irEligibilityTableExportErrorToast",
  SuccessToast = "irEligibilityTableExportSuccessToast",
  InfoToast = "irEligibilityTableExportInfoToast",
}

const exportErroredToastContent = {
  message: "Eligibility report failed to export. Please try again.",
  trackingId: DownloadEligibilityTableTrackingId.ErrorToast,
  variant: "danger",
  timeoutMs: TOAST_TIMEOUT,
} as const;

const exportSucceededToastContent = {
  message: "Your eligibility report has been exported.",
  trackingId: DownloadEligibilityTableTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
} as const;

const exportStartedToastContent = {
  message: "Your eligibility report is being exported.",
  trackingId: DownloadEligibilityTableTrackingId.InfoToast,
  timeoutMs: TOAST_TIMEOUT,
} as const;

const useEligibilityTableExport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useDashboardStore((store) => store.addToast);

  const {
    urlParams,
    currentForm,
    currentYear,
    shouldDisableControls,
    isEligibilityDataError,
  } = useEligibilityDataContext();

  const { mutate: generateEligibilityExport, data: eligibilityExportResponse } =
    useGenerateEligibilityExport();

  useGetEligibilityExport({
    exportId: eligibilityExportResponse?.data?.exportId,
    onSuccess: (response) => {
      const { data } = response;

      if (data.presignedUrl) {
        setIsLoading(false);
        addToast(exportSucceededToastContent);
        navigateToUrl(data.presignedUrl);
      }
    },
    onError: () => {
      setIsLoading(false);
      addToast(exportErroredToastContent);
    },
  });

  const handleSubmit = () => {
    setIsLoading(true);
    addToast(exportStartedToastContent);

    generateEligibilityExport(
      {
        ...urlParams,
        formDate: currentYear,
        formType: currentForm,
      },
      {
        onError: () => {
          setIsLoading(false);
          addToast(exportErroredToastContent);
        },
      }
    );
  };

  return {
    isLoading,
    isDisabled: isLoading || shouldDisableControls || isEligibilityDataError,
    onClick: handleSubmit,
  };
};

export default useEligibilityTableExport;
