import { Button } from "@taxbit-private/cosmic";

import useEligibilityTableExport from "./useEligibilityTableExport";

export enum EligibilityTableExportTrackingIds {
  ExportButton = "irEligibilityTableExportButton",
}

type EligibilityTableExportButtonProps = {
  isAnchor?: boolean;
  label: string;
};

const EligibilityTableExportButton: React.FC<
  EligibilityTableExportButtonProps
> = ({ isAnchor = false, label }) => {
  const { isLoading, onClick, isDisabled } = useEligibilityTableExport();

  const buttonProps = isAnchor
    ? ({
        variant: "anchor-primary",
      } as const)
    : ({
        size: "small",
        variant: "button-tertiary",
        iconName: "download",
        loadingText: "Exporting",
        isLoading,
      } as const);

  return (
    <Button
      {...buttonProps}
      label={label}
      onClick={onClick}
      trackingId={EligibilityTableExportTrackingIds.ExportButton}
      isDisabled={isDisabled}
    />
  );
};

export default EligibilityTableExportButton;
