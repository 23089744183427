import { DispositionMethod } from "./payersRestApiTypes";

export const payerDispositionMethodLabelMap: Record<DispositionMethod, string> =
  {
    [DispositionMethod.UniversalFifo]: "Universal FIFO",
    [DispositionMethod.UniversalHifo]: "Universal HIFO",
    [DispositionMethod.ExchangeHifo]: "Exchange HIFO",
    [DispositionMethod.EnterpriseExchangeHifo]: "Enterprise Exchange HIFO",
    [DispositionMethod.EnterpriseExchangeFifo]: "Enterprise Exchange FIFO",
    [DispositionMethod.GbIndividual]: "GB Individual",
    [DispositionMethod.GbBusiness]: "GB Business",
    [DispositionMethod.LuWac]: "LU WAC",
  };
