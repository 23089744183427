import { zodResolver } from "@hookform/resolvers/zod";
import {
  getBrowserDateFromUtcDateString,
  getUtcDateStringFromBrowserDate,
  hasDefinedValues,
} from "@taxbit-dashboard/commons";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  FormsFiltersFormFields,
  formsFiltersFormFieldSchema,
} from "./formsFiltersFormFieldSchema";
import { FormsTableParams } from "../../../../api/information-reporting/forms/formsApiTypes";
import { useIrFormsContext } from "../context/useIrForms";

const getFormDataFromUrlParams = ({
  statuses,
  types,
  startDate,
  endDate,
}: FormsTableParams): FormsFiltersFormFields => ({
  statuses: statuses ?? [],
  types: types ?? [],
  dateRange: [
    getBrowserDateFromUtcDateString(startDate),
    getBrowserDateFromUtcDateString(endDate),
  ],
});

const formDataEmptyValues: FormsFiltersFormFields = {
  statuses: [],
  types: [],
  dateRange: [undefined, undefined],
};

const useFormsFiltersDrawerForm = () => {
  const { urlParams, setFilterParams } = useIrFormsContext();

  const defaultValues = useMemo(
    () => getFormDataFromUrlParams(urlParams),
    [urlParams]
  );

  const formMethods = useForm<FormsFiltersFormFields>({
    resolver: zodResolver(formsFiltersFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        ({ statuses, types, dateRange: [startDate, endDate] }) => {
          setFilterParams((draft) => {
            draft.statuses = statuses;
            draft.types = types;
            draft.startDate =
              startDate && getUtcDateStringFromBrowserDate(startDate, true);
            draft.endDate =
              endDate && getUtcDateStringFromBrowserDate(endDate, false);
          });
        }
      )(e);
    },
    [formMethods, setFilterParams]
  );

  useEffect(() => {
    formMethods.reset(getFormDataFromUrlParams(urlParams));
  }, [urlParams, formMethods]);

  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof FormsFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useFormsFiltersDrawerForm;
