import { Body, Flex, MimeType } from "@taxbit-private/cosmic";
import { RhfUploader } from "@taxbit-private/cosmic-react-hook-form";

import { FilerSetupModalTrackingId } from "../filerSetupModalTypes";

const FilerLogoUpload = () => {
  return (
    <Flex direction="column" gap="m">
      <Body>(Optional) Upload a logo below.</Body>
      <Body>
        <i>We recommend a height-to-width ratio between 1:4 and 2:1.</i>
      </Body>
      <RhfUploader
        name="logos"
        supportedFileTypes={[MimeType.Png, MimeType.Jpeg]}
        trackingId={FilerSetupModalTrackingId.LogoUploader}
        shouldShowSelectedFiles={true}
      />
    </Flex>
  );
};

export default FilerLogoUpload;
