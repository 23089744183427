import {
  UrlFilterParams,
  readonlyIncludes,
  useUrlFilterParams,
} from "@taxbit-dashboard/commons";
import { getFormsSearchKeySchema } from "@taxbit-dashboard/rest";
import { useCallback, useMemo } from "react";

import validateFormsTableUrlParams from "./validateFormsTableUrlParams";
import { useGetForms } from "../../../../api/information-reporting/forms/formsApi";
import {
  FormsTableParams,
  formsEnumParamKeys,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import useFormFeatureFlagDropdowns from "../../shared/useFormFeatureFlagDropdowns";

const useFormsTableData = () => {
  const { currentForm, currentYear } = useFormFeatureFlagDropdowns({
    featureFlag: "formsPageYearsByFormType",
  });

  const validateParams = useCallback(
    (rawUrlParams: UrlFilterParams<FormsTableParams>) =>
      validateFormsTableUrlParams(rawUrlParams, {
        formDate: currentYear,
        formType: currentForm,
      }),
    [currentForm, currentYear]
  );

  const {
    urlParams,
    setUrlParams,
    setUrlParamsAndResetPagination: setFilterParams,
  } = useUrlFilterParams({
    validateParams,
  });

  const query = useGetForms(urlParams);

  const clearAllFilters = useCallback(
    () =>
      setFilterParams((draft) => {
        for (const key of getFormsSearchKeySchema.options) {
          draft[key] = undefined;
        }

        for (const key of formsEnumParamKeys) {
          draft[key] = [];
        }

        draft.startDate = undefined;
        draft.endDate = undefined;
      }),
    [setFilterParams]
  );

  const filterDrawerFilterCount = useMemo(() => {
    return Object.keys(urlParams).reduce((count, key) => {
      if (key === "startDate" && urlParams.startDate) {
        return count + 1;
      } else if (readonlyIncludes(formsEnumParamKeys, key) && urlParams[key]) {
        return count + (urlParams[key]?.length ?? 0);
      }

      return count;
    }, 0);
  }, [urlParams]);

  return {
    ...query,
    urlParams,
    clearAllFilters,
    setPaginationParams: setUrlParams,
    setFilterParams,
    filterDrawerFilterCount,
    shouldDisableControls: query.isLoading || query.isPreviousData,
    totalCount: query.meta?.page?.totalCount ?? 0,
  };
};

export default useFormsTableData;
