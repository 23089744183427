import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useState } from "react";

import useFormFeatureFlagData from "./useFormFeatureFlagData";

const useFormDataUploaderDropdowns = () => {
  const [currentForm, setCurrentForm] = useState<DashboardFormType>();
  const [currentYear, setCurrentYear] = useState<FourDigitYear>();

  const { forms, getYearsForForm } = useFormFeatureFlagData({
    featureFlag: "ingestionModuleYearsByFormTemplate",
  });

  return {
    forms,
    currentForm,
    years: getYearsForForm(currentForm),
    currentYear,
    setCurrentForm,
    setCurrentYear,
  };
};

export default useFormDataUploaderDropdowns;
