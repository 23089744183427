import { AccountsFileType, FileAction, FileType } from "@taxbit-dashboard/rest";
import {
  Flex,
  Body,
  Button,
  Anchor,
  Uploader,
  MimeType,
} from "@taxbit-private/cosmic";

import useFileUploaderContext from "../../context/useFileUploaderContext";
import { guideUrlMap, hasCsvGuideUrl } from "../../csvGuideData";
import {
  csvTemplateDownloadUrlMap,
  hasCsvTemplateDownloadUrl,
} from "../../csvTemplateData";

export enum UploadStepTrackingId {
  UploaderComponent = "file-uploader-uploader-component",
  GenericGuideLink = "file-uploader-generic-guide-link",
  DisabledGuideLink = "file-uploader-disabled-guide-link",
  GenericTemplateLink = "file-uploader-generic-template-link",
  DisabledTemplateLink = "file-uploader-disabled-template-link",
  UploadButton = "file-uploader-upload-button",
}

type Props = {
  action: FileAction;
};

const UploadStep: React.FC<Props> = ({ action }) => {
  const {
    errorMessage,
    files,
    isUploadDisabled,
    isUploading,
    onChange,
    onUpload,
    progress,
    selectedFileType,
    currentForm,
  } = useFileUploaderContext(action);

  const getGuideUrl = () => {
    if (hasCsvGuideUrl(currentForm)) {
      return guideUrlMap[currentForm];
    } else if (hasCsvGuideUrl(selectedFileType)) {
      return guideUrlMap[selectedFileType];
    }
    return "";
  };

  const getTemplateUrl = (accountsFileType?: AccountsFileType) => {
    if (
      hasCsvTemplateDownloadUrl(currentForm) &&
      action === FileAction.Ingest
    ) {
      return csvTemplateDownloadUrlMap[currentForm];
    } else if (hasCsvTemplateDownloadUrl(selectedFileType)) {
      return csvTemplateDownloadUrlMap[selectedFileType];
    } else if (selectedFileType === FileType.Accounts && accountsFileType) {
      return csvTemplateDownloadUrlMap[accountsFileType];
    }
    return "";
  };

  return (
    <Flex direction="column" gap="m">
      <Body>
        Before importing your file, you must format it to match the TaxBit
        engine&apos;s requirements. Follow our{" "}
        {selectedFileType === FileType.Forms && currentForm === undefined ? (
          <Button
            isDisabled={true}
            trackingId={UploadStepTrackingId.DisabledGuideLink}
            variant="anchor-primary-inline"
            label="guide"
            tooltipProps={{
              content: "Please select a form type.",
            }}
          />
        ) : action === FileAction.Delete ? (
          // TODO: Add guide links for delete file types. https://taxbit.atlassian.net/browse/TAX-36639
          <Button
            isDisabled={true}
            trackingId={UploadStepTrackingId.DisabledGuideLink}
            variant="anchor-primary-inline"
            label="guide"
            tooltipProps={{
              content: "Coming Soon",
            }}
          />
        ) : (
          <Anchor
            label="guide"
            href={getGuideUrl()}
            trackingId={UploadStepTrackingId.GenericGuideLink}
            variant="anchor-primary-inline"
          />
        )}{" "}
        to format your data to meet the standards of our template, or{" "}
        {selectedFileType === FileType.Forms && currentForm === undefined ? (
          <Button
            isDisabled={true}
            trackingId={UploadStepTrackingId.DisabledTemplateLink}
            variant="anchor-primary-inline"
            label="download the template"
            tooltipProps={{
              content: "Please select a form type.",
            }}
          />
        ) : selectedFileType === FileType.Accounts ? (
          <>
            {"download the "}
            <Anchor
              label="standard template"
              href={getTemplateUrl(AccountsFileType.AccountOwner)}
              trackingId={UploadStepTrackingId.GenericTemplateLink}
              variant="anchor-primary-inline"
            />{" "}
            {" or the "}{" "}
            <Anchor
              label="IRA template"
              href={getTemplateUrl(AccountsFileType.AccountOwnerIra)}
              trackingId={UploadStepTrackingId.GenericTemplateLink}
              variant="anchor-primary-inline"
            />
          </>
        ) : (
          <Anchor
            label={`download the ${
              selectedFileType === FileType.Transactions ? "standard " : ""
            }template`}
            href={getTemplateUrl()}
            trackingId={UploadStepTrackingId.GenericTemplateLink}
            variant="anchor-primary-inline"
          />
        )}
        .
      </Body>
      <Uploader
        files={files}
        onChange={onChange}
        shouldShowSelectedFiles={true}
        supportedFileTypes={
          selectedFileType === FileType.Transactions
            ? [MimeType.Csv, MimeType.Jsonl]
            : [MimeType.Csv]
        }
        trackingId={UploadStepTrackingId.UploaderComponent}
        errorMessage={errorMessage}
        validationState={errorMessage ? "error" : "none"}
        isDisabled={isUploading}
      />
      <Flex>
        <Button
          isDisabled={isUploadDisabled}
          label="Upload File"
          onClick={() => {
            void onUpload();
          }}
          trackingId={UploadStepTrackingId.UploadButton}
          isLoading={isUploading}
          loadingText={`Uploading (${progress}%)`}
        />
      </Flex>
    </Flex>
  );
};

export default UploadStep;
