import z from "zod";

import { GetAccountsParams } from "./accountsRestApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

const exportAccountsPostSchema = z.object({
  exportId: z.string(),
});

export type GetExportAccountsCsvParams = z.infer<
  typeof exportAccountsPostSchema
>;

export type GetInitiateAccountsCsvParams = Omit<
  GetAccountsParams,
  "page[offset]" | "page[limit]"
>;

export enum DownloadAccountsCsvStatus {
  Finished = "FINISHED",
  Processing = "PROCESSING",
}

const downloadAccountsCsvSchema = z.object({
  presignedUrl: z.string().optional(),
  status: z.nativeEnum(DownloadAccountsCsvStatus),
});

export type ExportAccountsCsvSchema = z.infer<typeof downloadAccountsCsvSchema>;

export const getAccountsPostExportResponseSchema = getPublicApiSuccessSchema(
  exportAccountsPostSchema
);

export const getExportAccountsCsvResponseSchema = getPublicApiSuccessSchema(
  downloadAccountsCsvSchema
);

export type ExportAccountsCsvResponseType = z.infer<
  typeof getExportAccountsCsvResponseSchema
>;
