import { InlineSpinner, Popover } from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationActionMenuContents from "./TopNavigationActionMenuContents";
import TopNavigationActionMenuTargetButton from "./TopNavigationActionMenuTargetButton";
import { useTopNavigationActionMenuContext } from "./useTopNavigationActionMenu";

const TopNavigationActionMenu = () => {
  const { isLoading, actionMenuTargetButtonRef, onCloseActionMenu } =
    useTopNavigationActionMenuContext();

  if (isLoading) {
    return <WhiteSpinner />;
  }

  return (
    <Popover
      targetButtonProps={{
        as: TopNavigationActionMenuTargetButton,
      }}
      targetButtonRef={actionMenuTargetButtonRef}
      contents={<TopNavigationActionMenuContents />}
      onMenuToggle={onCloseActionMenu}
    />
  );
};

const WhiteSpinner = styled(InlineSpinner)(
  ({ theme }) => `
    color: ${theme.color.white}`
);

export default TopNavigationActionMenu;
