import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@tanstack/react-location";
import {
  DashboardAppRoutePath,
  getTopNavigationHeight,
  useOrganizationPrefixedNavigate,
  useIsHrefActive,
  UserPermission,
  useUserPermission,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import {
  ActionButton,
  Flex,
  Divider,
  H4,
  getRgbaForHex,
  ActionButtonGroup,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import TopNavigationDropdown from "./action-menu/TopNavigationActionMenu";
import { HELP_CENTER_LINK } from "./action-menu/TopNavigationActionMenuItems";
import { TopNavigationActionMenuContextProvider } from "./action-menu/useTopNavigationActionMenu";
import { DownloadsCenter } from "./downloads/download-list/DownloadsCenter";
import OrganizationPicker, { TopNavigationButton } from "./OrganizationPicker";
import { TOP_NAV_COLOR } from "./TopNavigationActionButton";
import Logo from "../../../../assets/taxbitLogo.svg?react";
import useTenantBrandingOverrides from "../../../../hooks/useTenantBrandingOverrides";
import NotificationsCenter from "../../../notifications/center/NotificationsCenter";

const TopNavigation: React.FC = () => {
  const { prefixedNavigate } = useOrganizationPrefixedNavigate();
  const { logout } = useAuth0();
  const isHrefActive = useIsHrefActive();

  const hasUserAdminRole = useUserPermission(
    UserPermission.ReadOrganizationMembers
  );
  const {
    hasDownloadsAccess,
    hasNewOrganizationPickerAccess,
    hasNotificationCenterAccess,
    hasUserSettingsAccess,
  } = useDashboardFeatureFlags();
  const canViewSettings = hasUserSettingsAccess || hasUserAdminRole;

  const { logoElement } = useTenantBrandingOverrides();

  return (
    <TopBar padding="m" alignItems="center" justifyContent="space-between">
      <TopNavigationButton
        as={Link}
        to={DashboardAppRoutePath.Launch}
        trackingId="top-nav-logo-button"
      >
        <Flex alignItems="center" gap="m" padding="none s">
          {logoElement ?? <WhiteLogo />}
          <WhiteDivider direction="vertical" />
          <WhiteTitle forwardedAs="div">Information Reporting</WhiteTitle>
        </Flex>
      </TopNavigationButton>
      <Flex alignItems="center" gap="m">
        {!hasNewOrganizationPickerAccess && (
          <>
            <OrganizationPicker />
            <WhiteDivider direction="vertical" />
            <ActionButtonGroup>
              {hasDownloadsAccess && <DownloadsCenter />}
              {hasNotificationCenterAccess && <NotificationsCenter />}
              {canViewSettings && (
                <SettingsButton
                  isActive={isHrefActive("/settings")}
                  variant="white"
                  iconName="settings"
                  trackingId="top-nav-settings-link"
                  label="Settings"
                  onClick={() =>
                    prefixedNavigate({
                      to: hasUserSettingsAccess
                        ? DashboardAppRoutePath.AccountSettings
                        : DashboardAppRoutePath.CompanyUserManagement,
                    })
                  }
                />
              )}
              <ActionButton
                variant="white"
                iconName="help-circle"
                trackingId="top-nav-help-center-link"
                label="Get Help"
                href={HELP_CENTER_LINK}
                target="_blank"
              />
              <ActionButton
                variant="white"
                iconName="log-out"
                trackingId="top-nav-logout-button"
                label="Log Out"
                onClick={() =>
                  logout({
                    logoutParams: {
                      federated: true,
                      returnTo: window.location.origin,
                    },
                  })
                }
              />
            </ActionButtonGroup>
          </>
        )}
        {hasNewOrganizationPickerAccess && (
          <>
            <TopNavigationActionMenuContextProvider>
              <TopNavigationDropdown />
            </TopNavigationActionMenuContextProvider>
            <WhiteDivider direction="vertical" />
            <ActionButtonGroup>
              {hasDownloadsAccess && <DownloadsCenter />}
              {hasNotificationCenterAccess && <NotificationsCenter />}
            </ActionButtonGroup>
          </>
        )}
      </Flex>
    </TopBar>
  );
};

const TopBar = styled(Flex)(
  ({ theme }) => `
  position: fixed;
  width: 100%;
  z-index: ${theme.zIndex.sideBarNav};
  background-color: ${TOP_NAV_COLOR};
  height: ${getTopNavigationHeight(theme)};
`
);

const WhiteLogo = styled(Logo)(
  ({ theme }) => `
  height: ${theme.sizing.xs};
  color: ${theme.color.white};
`
);

const WhiteDivider = styled(Divider)(
  ({ theme }) => `
  background-color: ${theme.color.white};
  height: ${theme.sizing.xs}
`
);

const WhiteTitle = styled(H4)(
  ({ theme }) => `
  color: ${theme.color.white};
`
);

const SettingsButton = styled(ActionButton).withConfig<{ isActive: boolean }>({
  shouldForwardProp: (propName) => propName !== "isActive",
})(
  ({ theme, isActive }) => `
  ${
    isActive
      ? `
    color: ${TOP_NAV_COLOR};
    background-color: ${theme.color.white};

    &:hover {
      background-color: ${getRgbaForHex(theme.color.white, 0.8)};
    }

    &:active {
      background-color: ${getRgbaForHex(theme.color.white, 0.6)};
    }
  `
      : ""
  }
`
);

export default TopNavigation;
