import { DashboardFormType, GenericFormItem } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";

import useFormItem1099BColumns from "./useFormItem1099BColumns";
import useFormItem1099DivColumns from "./useFormItem1099DivColumns";
import useFormItem1099IntColumns from "./useFormItem1099IntColumns";
import useFormItem1099KColumns from "./useFormItem1099KColumns";
import useFormItem1099MiscColumns from "./useFormItem1099MiscColumns";
import useFormItem1099NecColumns from "./useFormItem1099NecColumns";
import useFormItem1099RColumns from "./useFormItem1099RColumns";
import useFormItems5498Columns from "./useFormItem5498Columns";
import useFormItemGainLossColumns from "./useFormItemGainLossColumns";
import useFormItemRmdColumns from "./useFormItemRmdColumns";

const baseColumns: TableColumns<GenericFormItem> = [
  {
    key: "externalId",
    label: "ID",
    isContentFullWidth: true,
  },
];

const useFormItemsColumns = (formType: DashboardFormType) => {
  const formItem1099BColumns = useFormItem1099BColumns();
  const formItem1099DivColumns = useFormItem1099DivColumns();
  const formItem1099IntColumns = useFormItem1099IntColumns();
  const formItem1099KColumns = useFormItem1099KColumns();
  const formItem1099MiscColumns = useFormItem1099MiscColumns();
  const formItem1099NecColumns = useFormItem1099NecColumns();
  const formItem1099RColumns = useFormItem1099RColumns();
  const formItem5498Columns = useFormItems5498Columns();
  const formItemGainLossColumns = useFormItemGainLossColumns();
  const formItemRmdColumns = useFormItemRmdColumns();

  if (formType === DashboardFormType.Irs1099B) {
    return [...formItem1099BColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099Div) {
    return [...formItem1099DivColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099Int) {
    return [...formItem1099IntColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099K) {
    return [...formItem1099KColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099Misc) {
    return [...formItem1099MiscColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099Nec) {
    return [...formItem1099NecColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs1099R) {
    return [...formItem1099RColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.Irs5498) {
    return [...formItem5498Columns, ...baseColumns];
  }

  if (formType === DashboardFormType.RmdStatement) {
    return [...formItemRmdColumns, ...baseColumns];
  }

  if (formType === DashboardFormType.GainLossSummary) {
    return [...formItemGainLossColumns, ...baseColumns];
  }

  return baseColumns;
};

export default useFormItemsColumns;
