import { Modal } from "@taxbit-private/cosmic";

import useDownloadFormsConfirmationModal from "./useDownloadFormsConfirmationModal";

type DownloadFormsConfirmationModalProps = {
  onClose: () => void;
  isOpen: boolean;
  isBulkDownload?: boolean;
};

export enum IrFormsDownloadConfirmationModalTrackingIds {
  CloseButton = "ir-forms-download-confirmation-modal-close-btn",
  CancelButton = "ir-forms-download-confirmation-modal-cancel-btn",
  SubmitButton = "ir-forms-download-confirmation-modal-submit-btn",
}

const DownloadFormsConfirmationModal = ({
  onClose,
  isOpen,
  isBulkDownload,
}: DownloadFormsConfirmationModalProps) => {
  const { handleDownloadForms, modalContent } =
    useDownloadFormsConfirmationModal({ isBulkDownload, onClose });

  return (
    <Modal
      title="Download Forms"
      isOpen={isOpen}
      onClose={onClose}
      closeButtonTrackingId={
        IrFormsDownloadConfirmationModalTrackingIds.CloseButton
      }
      secondaryButtonProps={{
        label: "Cancel",
        trackingId: IrFormsDownloadConfirmationModalTrackingIds.CancelButton,
        onClick: onClose,
      }}
      primaryButtonProps={{
        label: "Download",
        trackingId: IrFormsDownloadConfirmationModalTrackingIds.SubmitButton,
        variant: "button-primary",
        onClick: handleDownloadForms,
      }}
    >
      {modalContent}
    </Modal>
  );
};

export default DownloadFormsConfirmationModal;
