import {
  Link,
  renderStringOrPlaceholder,
  useDashboardStore,
  useOrganizationPrefixedNavigation,
  GridRow,
  BaseGrid as Grid,
} from "@taxbit-dashboard/commons";
import {
  AccountId,
  AssetAmount,
  LineItem,
  Transaction,
} from "@taxbit-dashboard/rest";
import { Card, Flex, Divider } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import calculateAssetFiatValue from "../../../../utils/calculateAssetFiatValue";
import formatTransactionType from "../../../../utils/formatTransactionType";
import sumUpFees from "../../../../utils/sumUpFees";
import useFormatAsset from "../../../../utils/useFormatAsset";

export enum TransactionDetailsInformationTrackingId {
  ToAccountLink = "transactionDetailsToAccountLink",
}

type TransactionDetailsInformationProps = {
  transaction: Transaction;
  costBasis?: AssetAmount;
  accountId: AccountId;
};

const TransactionDetailsInformation: React.FC<
  TransactionDetailsInformationProps
> = ({ transaction, accountId }) => {
  const resetPageContext = useDashboardStore((store) => store.resetPageContext);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();

  const {
    datetime,
    externalId,
    fees,
    id,
    type,
    received,
    sent,
    createdDatetime,
    updatedDatetime,
    metadata,
  } = transaction;

  const receivedAsset = received?.[0];
  const sentAsset = sent?.[0];
  const transactionHash = metadata?.platform?.transactionHash;

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const formatAssetValue = useCallback(
    (lineItem?: LineItem) => {
      const formattedAsset = formatAsset({
        assetAmount: lineItem?.assetAmount,
      });
      if (lineItem && lineItem.assetAmount.asset.type === "Crypto") {
        const calculatedAssetFiatValue = calculateAssetFiatValue(lineItem);
        return `${formattedAsset} (${formatAsset({
          assetAmount: calculatedAssetFiatValue,
        })})`;
      } else {
        return formattedAsset;
      }
    },
    [formatAsset]
  );

  return (
    <Card title="Details">
      <Flex direction="column" padding="l" gap="m">
        <Grid>
          <GridRow
            label="Type"
            content={
              type ? formatTransactionType(type) : COSMIC_VALUE_PLACEHOLDER
            }
          />
          <GridRow
            label="Timestamp"
            content={formatDateTime({ date: datetime, format: "DateTime" })}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Amount In"
            content={formatAssetValue(receivedAsset)}
          />
          <GridRow label="Amount Out" content={formatAssetValue(sentAsset)} />
          <GridRow
            label="Fee"
            content={formatAsset({ assetAmount: sumUpFees(fees) })}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Account"
            content={
              <Link
                to={buildOrganizationPrefixedPath(`/accounts/${accountId}`)}
                onClick={() => {
                  resetPageContext(["transaction-details"]);
                }}
                variant="anchor-primary-inline"
                label="View Details"
                trackingId={
                  TransactionDetailsInformationTrackingId.ToAccountLink
                }
              />
            }
          />
          <GridRow
            label="Transaction Hash"
            content={renderStringOrPlaceholder(transactionHash)}
          />
        </Grid>
        <Divider />
        <Grid>
          <GridRow
            label="Transaction ID"
            content={renderStringOrPlaceholder(externalId)}
          />
          <GridRow
            label="TaxBit Transaction ID"
            content={renderStringOrPlaceholder(id)}
          />
          <GridRow
            label="Ingested Timestamp"
            content={formatDateTime({
              date: createdDatetime,
              format: "DateTime",
            })}
          />
          <GridRow
            label="Modified Timestamp"
            content={formatDateTime({
              date: updatedDatetime,
              format: "DateTime",
            })}
          />
        </Grid>
      </Flex>
    </Card>
  );
};

export default TransactionDetailsInformation;
