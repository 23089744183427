import { ReactNode } from "react";

import {
  NotificationsCenterDataContextProvider,
  useNotificationsCenterDataContext,
} from "./center/useNotificationsCenterData";
import {
  NotificationsPageDataContextProvider,
  useNotificationsPageDataContext,
} from "./page/useNotificationsPageData";

export enum NotificationsContext {
  Center = "NOTIFICATIONS_CENTER",
  Page = "NOTIFICATIONS_PAGE",
}

export const NotificationsContextProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }) => {
  return (
    <NotificationsCenterDataContextProvider>
      <NotificationsPageDataContextProvider>
        {children}
      </NotificationsPageDataContextProvider>
    </NotificationsCenterDataContextProvider>
  );
};

const useNotificationsContext = (context: NotificationsContext) => {
  const pageContext = useNotificationsPageDataContext();
  const centerContext = useNotificationsCenterDataContext();

  return context === NotificationsContext.Page ? pageContext : centerContext;
};

export default useNotificationsContext;
