import {
  cosmicPaginationLimitSchema,
  utcDateStringSchema,
  pageNumberSchema,
  withOptionalCatch,
} from "@taxbit-dashboard/commons";
import {
  AccountTaxClassification,
  AccountTaxDocumentationStatus,
  accountTaxDocumentationStatusSchema,
  AccountTaxDocumentationType,
  accountTaxDocumentationTypeSchema,
  GetAccountsSearchKey,
  getAccountsSearchKeySchema,
  getAccountsSortSchema,
  kycTaxDocumentationIssueTypeSchema,
  TinValidationStatus,
  tinValidationStatusSchema,
} from "@taxbit-dashboard/rest";
import z from "zod";

export const DEFAULT_ACCOUNTS_SORT = "-created";

const sortSchema = getAccountsSortSchema.catch(DEFAULT_ACCOUNTS_SORT);

const searchValueSchema = withOptionalCatch(z.string().min(1).optional());

const searchKeyStringParams = Object.fromEntries(
  getAccountsSearchKeySchema.options
    .filter((key) => key !== "masked_tax_id")
    .map((key) => [key, searchValueSchema])
) as Record<
  Exclude<GetAccountsSearchKey, "masked_tax_id">,
  typeof searchValueSchema
>;

const TIN_REGEX = /^\d{4}$/;
export const tinSchema = z.string().regex(TIN_REGEX);

export const accountsTableParamsSchema = z.object({
  limit: cosmicPaginationLimitSchema,
  page: pageNumberSchema,
  sort: sortSchema,
  startDate: withOptionalCatch(utcDateStringSchema.optional()),
  endDate: withOptionalCatch(utcDateStringSchema.optional()),
  tinValidationStatuses: withOptionalCatch(
    z.array(tinValidationStatusSchema).optional()
  ),
  taxDocumentationStatuses: withOptionalCatch(
    z.array(accountTaxDocumentationStatusSchema).optional()
  ),
  taxDocumentationTypes: withOptionalCatch(
    z.array(accountTaxDocumentationTypeSchema).optional()
  ),
  taxDocumentationOpenIssues: withOptionalCatch(
    z.array(kycTaxDocumentationIssueTypeSchema).optional()
  ),
  // This is snake_case to avoid an extra translation layer for sorts.
  // TODO: Add the translation layer so the inconsistent casing is less confusing.
  masked_tax_id: withOptionalCatch(tinSchema.optional()),
  ...searchKeyStringParams,
});

export type AccountsTableParams = z.infer<typeof accountsTableParamsSchema>;

export const accountsEnumParamKeys = [
  "tinValidationStatuses",
  "taxDocumentationStatuses",
  "taxDocumentationTypes",
  "taxDocumentationOpenIssues",
] as const;
export type AccountsTableEnumParamKey = (typeof accountsEnumParamKeys)[number];

export const accountsSearchKeyLabelMap: Record<GetAccountsSearchKey, string> = {
  email: "Email",
  name: "Name",
  masked_tax_id: "TIN",
  external_id: "Account ID",
};

export const tinStatusLabelMap: Record<TinValidationStatus, string> = {
  [TinValidationStatus.CountryCodeMismatch]: "Country Code Mismatch",
  [TinValidationStatus.ForeignIndicia]: "Foreign Indicia",
  [TinValidationStatus.InvalidData]: "Invalid Data",
  [TinValidationStatus.Pending]: "Pending",
  [TinValidationStatus.TinMismatch]: "TIN Mismatch",
  [TinValidationStatus.TinMismatchForeignIndicia]:
    "TIN Mismatch Foreign Indicia",
  [TinValidationStatus.TinNotIssued]: "TIN Not Issued",
  [TinValidationStatus.TinTypeMismatch]: "TIN Type Mismatch",
  [TinValidationStatus.Unprocessed]: "Unprocessed",
  [TinValidationStatus.Valid]: "Valid Match",
};

export const taxDocumentationTypeLabelMap: Record<
  AccountTaxDocumentationType,
  string
> = {
  [AccountTaxDocumentationType.W9]: "W-9",
  [AccountTaxDocumentationType.W8Ben]: "W-8BEN",
  [AccountTaxDocumentationType.W8BenE]: "W-8BEN-E",
  [AccountTaxDocumentationType.NotSubmitted]: "Not Submitted",
  [AccountTaxDocumentationType.Comprehensive]: "DAC7",
};

export const taxDocumentationStatusLabelMap: Record<
  AccountTaxDocumentationStatus,
  string
> = {
  [AccountTaxDocumentationStatus.Invalid]: "Invalid",
  [AccountTaxDocumentationStatus.Undocumented]: "Undocumented",
  [AccountTaxDocumentationStatus.Valid]: "Valid",
  [AccountTaxDocumentationStatus.Undetermined]: "Undetermined",
};

export const taxClassificationLabelMap: Record<
  AccountTaxClassification,
  string
> = {
  [AccountTaxClassification.Ccorporation]: "C Corp",
  [AccountTaxClassification.Individual]: "Individual",
  [AccountTaxClassification.Llc]: "LLC",
  [AccountTaxClassification.Other]: "Other",
  [AccountTaxClassification.Partnership]: "Partnership",
  [AccountTaxClassification.Scorporation]: "S Corp",
  [AccountTaxClassification.Smllc]: "Single Member LLC",
  [AccountTaxClassification.SoleProprietor]: "Sole Proprietor",
  [AccountTaxClassification.TrustEstate]: "Trust Estate",
  [AccountTaxClassification.Corporation]: "Corporation",
  [AccountTaxClassification.SmLlc]: "Small LLC",
  [AccountTaxClassification.DisregardedEntity]: "Disregarded Entity",
  [AccountTaxClassification.LlcC]: "LLC C Corporation",
  [AccountTaxClassification.LlcP]: "LLC Partnership",
  [AccountTaxClassification.LlcS]: "LLC S Corporation",
  [AccountTaxClassification.SimpleTrust]: "Simple Trust",
  [AccountTaxClassification.ComplexTrust]: "Complex Trust",
  [AccountTaxClassification.GrantorTrust]: "Grantor Trust",
  [AccountTaxClassification.Estate]: "Estate",
  [AccountTaxClassification.CentralBankOfIssue]: "Central Bank Of Issue",
  [AccountTaxClassification.ForeignGovernmentControlledEntity]:
    "Foreign Government Controlled Entity",
  [AccountTaxClassification.ForeignGovernmentIntegralPart]:
    "Foreign Government Integral Part",
  [AccountTaxClassification.TaxExemptOrganization]: "Tax Exempt Organization",
  [AccountTaxClassification.PrivateFoundation]: "Private Foundation",
  [AccountTaxClassification.InternationalOrganization]:
    "International Organization",
  [AccountTaxClassification.Trust]: "Trust",
} as const;
