import { useGetPayers } from "@taxbit-dashboard/commons";
import { Payer } from "@taxbit-dashboard/rest";
import {
  Table,
  TrimmedBoxContent,
  ContentSpinner,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
} from "@taxbit-private/cosmic";
import { useCallback } from "react";

import FilerSetupTableRowActionMenu from "./FilerSetupTableRowActionMenu";
import useFilerSetupTableColumns, {
  FilerSetupTableTrackingId,
} from "./useFilerSetupTableColumns";

const FilerSetupTable: React.FC = () => {
  const { data: payersData = [], isLoading, isError } = useGetPayers();

  const columns = useFilerSetupTableColumns();
  const getRowKey = useCallback((payer: Payer) => payer.payerId, []);

  const getRowUtilityElement = useCallback(
    (payer: Payer) => <FilerSetupTableRowActionMenu payer={payer} />,
    []
  );

  const renderContent = () => {
    if (isLoading) {
      return (
        <ContentSpinner trackingId={FilerSetupTableTrackingId.LoadingSpinner} />
      );
    }

    if (isError) {
      return <ContentErrorEmptyState entity="Filers" />;
    } else if (payersData?.length) {
      return (
        <Table
          rows={payersData}
          columns={columns}
          getRowKey={getRowKey}
          getRowUtilityElement={getRowUtilityElement}
          isUtilityColumnSticky={true}
          isSortable={true}
          initialSortColumnKey="isDefault"
          isInitialSortDescending={true}
        />
      );
    } else {
      return <ContentEntityEmptyState entity="Filers" />;
    }
  };

  return <TrimmedBoxContent trim="all">{renderContent()}</TrimmedBoxContent>;
};

export default FilerSetupTable;
