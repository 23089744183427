import Environment from "./types/Environment";

const hostByEnvironment: Record<Environment, string> = {
  PRODUCTION: "api-ent.taxbit.com",
  STAGING: "staging.api-ent.taxbit.com",
};

export default (path: string, environment: Environment = "PRODUCTION") => {
  return `https://${hostByEnvironment[environment]}/v1.5/${path}`;
};
