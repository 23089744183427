import { AccountId, TransactionId, TransferType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import calculateTotalCostBasis from "./calculateTotalCostBasis";
import { useGetTransferLots } from "../../../../api/transferLotsApi";
import useFormatAsset from "../../../../utils/useFormatAsset";

const LOTS_LIMIT = 100;

const useDispositionTableData = (
  type: TransferType,
  accountId: AccountId,
  transactionId: TransactionId
) => {
  const formatAsset = useFormatAsset();

  const {
    isFetching: isTransferLotsLoading,
    data: transferLotData = [],
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetTransferLots({
    type,
    accountId,
    transactionId,
    limit: LOTS_LIMIT,
    shouldFetchTransferLots: type === TransferType.TransferOut,
  });

  const totalCostBasisDisplayValue = useMemo(() => {
    const costBasisAmount = calculateTotalCostBasis(transferLotData);
    return formatAsset({
      assetAmount: costBasisAmount,
    });
  }, [formatAsset, transferLotData]);

  return {
    isLoading: isTransferLotsLoading,
    transferLotItems: transferLotData,
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    totalCostBasis: totalCostBasisDisplayValue,
  };
};

export default useDispositionTableData;
