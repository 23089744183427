import { z } from "zod";

import {
  FormItemDocumentType,
  baseFormLineItemSchema,
  baseFormLineItemWithIdsSchema,
} from "./formItemsSharedRestApiTypes";

export const form1099IntLineItemSchema = baseFormLineItemSchema.extend({
  documentType: z.literal(FormItemDocumentType.Form1099Int),
  interestIncome: z.string(),
  earlyWithdrawalPenalty: z.string().optional(),
  interestOnUsSavingsBondsAndTreasuryObligations: z.string().optional(),
  federalTaxWithheld: z.string().optional(),
  investmentExpense: z.string().optional(),
  foreignTaxPaid: z.string().optional(),
  foreignCountryOrUsPossession: z.string().optional(),
  taxExemptInterest: z.string().optional(),
  specifiedPrivateActivityBondInterest: z.string().optional(),
  marketDiscount: z.string().optional(),
  bondPremium: z.string().optional(),
  bondPremiumOnTreasuryObligations: z.string().optional(),
  bondPremiumOnTaxExemptBond: z.string().optional(),
  taxExemptAndTaxCreditBondCusip: z.string().optional(),
  state: z.string().optional(),
  payersStateNumber: z.string().optional(),
  stateTaxWithheld: z.string().optional(),
});

export const form1099IntLineItemWithIdsSchema = form1099IntLineItemSchema.merge(
  baseFormLineItemWithIdsSchema
);
export type Form1099IntLineItem = z.infer<
  typeof form1099IntLineItemWithIdsSchema
>;
