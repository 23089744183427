import { KycIssue } from "@taxbit-dashboard/rest";
import { Button, ContentSpinner, Flex } from "@taxbit-private/cosmic";
import { useState } from "react";

import { IssueDetailsModalDeprecated } from "./IssueDetailsModalDeprecated";
import { issueTypesMap } from "./issuesTypesMap";
import KycBadge from "./KycBadge";
import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";

export enum IssueDetailsTrackingId {
  IssueDetailsButton = "detailsBtn",
  IssueDetailsLoadingSpinner = "detailsLoadingSpinner",
}

type IssueProps = {
  issue: KycIssue;
};

export const IssueDetailsDeprecated: React.FC<IssueProps> = ({ issue }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const issueTypeDescription = issueTypesMap[issue.issueType];
  const { isLoading } = useAccountOwnerTaxDocumentContext();

  return isLoading ? (
    <ContentSpinner
      trackingId={IssueDetailsTrackingId.IssueDetailsLoadingSpinner}
    />
  ) : (
    <Flex direction="row" gap="s">
      <Button
        variant="anchor-primary-inline"
        trackingId={IssueDetailsTrackingId.IssueDetailsButton}
        label={issueTypeDescription}
        onClick={() => setIsModalOpen(true)}
      />
      <KycBadge taxStatus={issue.issueStatus} />
      <IssueDetailsModalDeprecated
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        issue={issue}
      />
    </Flex>
  );
};
