import { TaxBitRest } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { isProdEnvironment } from "./environment";
import useGetAccessTokenAndUpdateAuthState from "./useGetAccessTokenAndUpdateAuthState";

const useTaxBitRest = () => {
  const getAccessTokenAndUpdateAuthState =
    useGetAccessTokenAndUpdateAuthState();

  const environment = isProdEnvironment() ? "PRODUCTION" : "STAGING";

  return useMemo(
    () =>
      new TaxBitRest({
        getAccessToken: () => getAccessTokenAndUpdateAuthState(),
        environment,
      }),
    [environment, getAccessTokenAndUpdateAuthState]
  );
};

export default useTaxBitRest;
