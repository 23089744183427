import { UrlFilterParams } from "@taxbit-dashboard/commons";
import { Optional } from "ts-toolbelt/out/Object/Optional";

import {
  FormsTableParams,
  formsTableParams,
} from "../../../../api/information-reporting/forms/formsApiTypes";
import { IrFormTypeDateParams } from "../../../../api/information-reporting/irApiTypes";

const validateFormsTableUrlParams = (
  params: UrlFilterParams<FormsTableParams>,
  formsParams: Optional<IrFormTypeDateParams>
): FormsTableParams & Optional<IrFormTypeDateParams> => {
  return { ...formsTableParams.parse(params), ...formsParams };
};

export default validateFormsTableUrlParams;
