import { uuidSchema } from "@taxbit-private/uuids";
import z from "zod";

import { KycTaxDocumentationIssueType } from "./kycSharedTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const accountsEnumSearchKeys = [
  "external_id",
  "name",
  "email",
  "masked_tax_id",
] as const;

export const getAccountsSearchKeySchema = z.enum(accountsEnumSearchKeys);

export type GetAccountsSearchKey = z.infer<typeof getAccountsSearchKeySchema>;

export const getAccountsSortKeySchema = z.enum(["created", "name"]);
export type GetAccountsSortKey = z.infer<typeof getAccountsSortKeySchema>;

export const getAccountsSortSchema = z.enum([
  ...getAccountsSortKeySchema.options,
  ...getAccountsSortKeySchema.options.map((opt) => `-${opt}` as const),
]);
export type GetAccountsSort = z.infer<typeof getAccountsSortSchema>;

export type GetAccountsParams = {
  "sort"?: GetAccountsSort;
  "page[offset]"?: number;
  "page[limit]"?: number;
  "filters[email]"?: string;
  "filters[external_id]"?: string;
  "filters[id]"?: string;
  "filters[masked_tax_id]"?: string;
  "filters[name]"?: string;
  "filters[tin_validation_status]"?: TinValidationStatus[];
  "filters[tax_documentation_status]"?: AccountTaxDocumentationStatus[];
  "filters[tax_documentation_type]"?: AccountTaxDocumentationType[];
  "filters[tax_documentation_open_issue]"?: KycTaxDocumentationIssueType[];
  // The created filters must be ISO date strings.
  "filters[created][$gte]"?: string;
  "filters[created][$lte]"?: string;
};

// The ordering of the enum entries below dictates the order
// entries (ex: dropdown options) appear in pertinent UI.
// Do not reorder without running it by the product team.
export enum TinValidationStatus {
  Valid = "VALID",
  TinMismatch = "TIN_MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  TinMismatchForeignIndicia = "TIN_MISMATCH_FOREIGN_INDICIA",
  CountryCodeMismatch = "COUNTRY_CODE_MISMATCH",
  InvalidData = "INVALID_DATA",
  TinTypeMismatch = "TIN_TYPE_MISMATCH",
  ForeignIndicia = "FOREIGN_INDICIA",
  Unprocessed = "UNPROCESSED",
  Pending = "PENDING",
}

export const tinValidationStatusSchema = z.nativeEnum(TinValidationStatus);

export enum AccountTaxDocumentationStatus {
  Invalid = "INVALID",
  Undocumented = "UNDOCUMENTED",
  Valid = "VALID",
  Undetermined = "UNDETERMINED",
}

export const accountTaxDocumentationStatusSchema = z.nativeEnum(
  AccountTaxDocumentationStatus
);

export enum AccountTaxDocumentationType {
  NotSubmitted = "NOT_SUBMITTED",
  W8Ben = "W-8BEN",
  W8BenE = "W-8BEN-E",
  W9 = "W9",
  Comprehensive = "COMPREHENSIVE",
}

export const accountTaxDocumentationTypeSchema = z.nativeEnum(
  AccountTaxDocumentationType
);

export enum AccountTaxDocumentationIssue {
  CareOf = "CARE_OF",
  Incomplete = "INCOMPLETE",
  NoData = "NO_DATA",
  PoBox = "PO_BOX",
  TinValidationFailure = "TIN_VALIDATION_FAILURE",
  Unknown = "UNKNOWN",
  UsAddress = "US_ADDRESS",
}

export enum AccountTaxClassification {
  Ccorporation = "C_CORPORATION",
  Individual = "INDIVIDUAL",
  Llc = "LLC",
  Other = "OTHER",
  Partnership = "PARTNERSHIP",
  Scorporation = "S_CORPORATION",
  Smllc = "SMLLC",
  SoleProprietor = "SOLE_PROPRIETOR",
  TrustEstate = "TRUST_ESTATE",
  Corporation = "CORPORATION",
  SmLlc = "SM_LLC",
  DisregardedEntity = "DISREGARDED_ENTITY",
  LlcC = "LLC_C",
  LlcP = "LLC_P",
  LlcS = "LLC_S",
  SimpleTrust = "SIMPLE_TRUST",
  ComplexTrust = "COMPLEX_TRUST",
  GrantorTrust = "GRANTOR_TRUST",
  Estate = "ESTATE",
  CentralBankOfIssue = "CENTRAL_BANK_OF_ISSUE",
  ForeignGovernmentControlledEntity = "FOREIGN_GOVERNMENT_CONTROLLED_ENTITY",
  ForeignGovernmentIntegralPart = "FOREIGN_GOVERNMENT_INTEGRAL_PART",
  TaxExemptOrganization = "TAX_EXEMPT_ORGANIZATION",
  PrivateFoundation = "PRIVATE_FOUNDATION",
  InternationalOrganization = "INTERNATIONAL_ORGANIZATION",
  Trust = "TRUST",
}

export const accountTaxClassificationSchema = z.nativeEnum(
  AccountTaxClassification
);

/**
 * Backend source of truth [here](https://github.com/taxbit-private/dashboard-data-service/blob/main/workspaces/service/apps/http-service/src/models/accounts/dto/GetAccountsResponse.ts)
 */
const accountSchema = z.object({
  id: uuidSchema.optional(),
  created: z.string().optional(), // ISO date string.
  name: z.string().optional(),
  externalId: z.string().optional(),
  email: z.string().optional(),
  maskedTaxId: z.string().optional(),
  tinValidationStatus: tinValidationStatusSchema,
  taxDocumentationStatus: accountTaxDocumentationStatusSchema,
  taxDocumentationType: accountTaxDocumentationTypeSchema,
  taxDocumentationIssues: z.array(
    z
      .nativeEnum(AccountTaxDocumentationIssue)
      .or(z.nativeEnum(KycTaxDocumentationIssueType))
  ),
  taxClassification: accountTaxClassificationSchema.optional(),
});

export type Account = z.infer<typeof accountSchema>;

export const getAccountsResponseSchema = getPublicApiSuccessSchema(
  z.array(accountSchema)
);
