import { useSetBackHrefAndNavigate } from "@taxbit-dashboard/commons";
import {
  TrimmedBoxContent,
  Table,
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";

import useTransfersTableData from "./useTransfersTableData";

const TransfersTable: React.FC = () => {
  const {
    transfers = [],
    isLoading,
    isError,
    columns,
  } = useTransfersTableData();

  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const searchEmptyStateProps = useSearchEmptyStateProps();

  return (
    <TrimmedBoxContent trim="all">
      <Table
        rows={transfers}
        getRowKey={(row) => `${row.transactionId}-${row.accountId}`}
        getRowOnClick={(row) => {
          setBackHrefAndNavigate({
            to: `./${row.transactionId}/accounts/${row.accountId}`,
            backHrefKey: "transfer-details",
          });
        }}
        columns={columns}
        isLoading={isLoading}
        emptyStateProps={
          isError
            ? getEnUsErrorEmptyStateProps({ entity: "transfer data " })
            : searchEmptyStateProps
        }
      />
    </TrimmedBoxContent>
  );
};

export default TransfersTable;
