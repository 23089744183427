import { KycIssue } from "@taxbit-dashboard/rest";
import { Flex, Snippet } from "@taxbit-private/cosmic";

import { issueTypesMap } from "./issuesTypesMap";
import KycBadge from "./KycBadge";

export enum IssueDetailsModalTrackingIdDeprecated {
  FooterCloseBtn = "footer-issue-details-modal-close-btn",
  ResolvePrimaryBtn = "primary-issue-details-modal-resolve-btn",
  ReopenPrimaryBtn = "primary-issue-details-modal-reopen-btn",
  CloseBtn = "issue-details-modal-close-btn",
  Checklist = "issue-details-modal-checklist",
}

const useGetIssueDetailsModalInfoDeprecated = (
  issue: KycIssue,
  onModalClose: () => void
) => {
  const { issueType, issueStatus, curingStatus, resubmissionStatus } = issue;

  const issueTypeDescription = (
    <Flex gap="s">
      {issueTypesMap[issueType]}
      <KycBadge taxStatus={issueStatus} />
    </Flex>
  );

  const closeBtnProps = {
    label: "Close",
    trackingId: IssueDetailsModalTrackingIdDeprecated.FooterCloseBtn,
    onClick: onModalClose,
  };

  const content = (
    <>
      <Snippet label="Issue Type" content={issueTypeDescription} />
      <Snippet
        label="Issue Status"
        content={<KycBadge taxStatus={issueStatus} />}
      />
      <Snippet
        label="Curing Status"
        content={<KycBadge taxStatus={curingStatus} />}
      />
      <Snippet
        label="Resubmission Status"
        content={<KycBadge taxStatus={resubmissionStatus} />}
      />
    </>
  );

  return {
    closeBtnProps,
    content,
  };
};
export default useGetIssueDetailsModalInfoDeprecated;
