import {
  UserPermission,
  createSingleInstanceHookContext,
  useUserPermission,
  doesResponseErrorMatchStatusCode,
} from "@taxbit-dashboard/commons";

import { useGetAccountV2 } from "../api/account/accountApi";
import { useGetAccountOwnerDetails } from "../api/account-owners/accountOwnersApi";
import {
  useGetKycTaxDocumentationStatus,
  useGetKycTaxDocumentationSummary,
} from "../api/kyc-tax-documentation/kycTaxDocumentationApi";
import useAccountId from "../utils/useAccountId";

const useGetAccountOwnerTaxDocumentData = () => {
  const shouldUnmaskValue = useUserPermission(UserPermission.ReadTins);

  const {
    data: account,
    isLoading: isAccountLoading,
    isError: isAccountError,
    error: accountError,
  } = useGetAccountV2(shouldUnmaskValue);

  const {
    data: accountOwnerDetails,
    isInitialLoading: isAccountOwnerDetailsLoading,
    isError: isAccountOwnerDetailsError,
  } = useGetAccountOwnerDetails(
    account?.accountOwner.accountOwnerId,
    shouldUnmaskValue
  );

  const {
    data: taxDocumentationStatus,
    isFetching: isTaxDocumentationStatusLoading,
    isError: isTaxDocumentationStatusError,
  } = useGetKycTaxDocumentationStatus(
    account?.accountOwner.accountOwnerId,
    accountOwnerDetails?.latestTaxDocument?.documentType
  );

  const {
    data: taxDocumentationSummary,
    isFetching: isTaxDocumentationSummaryLoading,
  } = useGetKycTaxDocumentationSummary(
    account?.accountOwner.accountOwnerId,
    accountOwnerDetails?.latestTaxDocument?.documentType
  );

  const accountId = useAccountId();
  const foundAccount = accountOwnerDetails?.accountOwner.accounts.find(
    (acct) => acct.accountId === accountId
  );

  const isValidTaxDocumentationStatus =
    accountOwnerDetails?.issues.length === 0 ||
    !accountOwnerDetails?.issues.some((issue) => !issue.resolvedAt);

  const isAccountNotFoundError = doesResponseErrorMatchStatusCode(
    accountError,
    404
  );

  const isLoading =
    isAccountLoading ||
    isAccountOwnerDetailsLoading ||
    isTaxDocumentationSummaryLoading ||
    isTaxDocumentationStatusLoading;

  return {
    account: foundAccount,
    accountOwner: accountOwnerDetails?.accountOwner,
    taxDocumentation: accountOwnerDetails?.latestTaxDocument,
    issues: accountOwnerDetails?.issues,
    latestTaxDocumentationGroupNumber:
      accountOwnerDetails?.latestTaxDocumentationGroupNumber,
    taxDocumentationSummary,
    taxDocumentationStatus,
    isLoading,
    isAccountNotFoundError,
    shouldUnmaskValue,
    isError: isAccountError || isAccountOwnerDetailsError,
    isTaxDocumentationStatusError,
    isValidTaxDocumentationStatus,
  };
};

export const {
  useContextHook: useAccountOwnerTaxDocumentContext,
  Context: AccountOwnerTaxDocumentContext,
  Provider: AccountOwnerTaxDocumentProvider,
} = createSingleInstanceHookContext(
  useGetAccountOwnerTaxDocumentData,
  "useGetAccountOwnerTaxDocumentData"
);
