import { Box, Divider, Flex } from "@taxbit-private/cosmic";

import EligibilityFiltersDrawerToggle from "./drawer/EligibilityFiltersDrawerToggle";
import EligibilityTablePagination from "./EligibilityTablePagination";
import EligibilityTableExportButton from "./export/EligibilityTableExportButton";
import EligibilityFilterChips from "./filters/chips/EligibilityFilterChips";
import EligibilityScopedSearchBar from "./filters/EligibilityScopedSearchBar";
import EligibilityActionBar from "./table/actions/EligibilityActionBar";
import EligibilityTable from "./table/EligibilityTable";

const EligibilityTableContent = () => {
  return (
    <>
      <Box>
        <Flex direction="column">
          <Flex padding="m" direction="column" gap="m">
            <Flex gap="s">
              <EligibilityFiltersDrawerToggle />
              <EligibilityScopedSearchBar />
              <Flex>
                <Divider direction="vertical" />
              </Flex>
              <EligibilityTableExportButton label="Export Table" />
            </Flex>
            <EligibilityFilterChips />
          </Flex>
          <Divider />
          <EligibilityTable />
          <Divider />
          <EligibilityTablePagination />
        </Flex>
      </Box>
      <EligibilityActionBar />
    </>
  );
};

export default EligibilityTableContent;
