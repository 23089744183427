import {
  DashboardPrimaryTemplate,
  Link,
  MissingDashboardConfigEmptyState,
  isDefined,
  useOrganizationPrefixedNavigation,
} from "@taxbit-dashboard/commons";
import {
  Box,
  Button,
  ContentEmptyState,
  ContentSpinner,
  Flex,
} from "@taxbit-private/cosmic";
import { useState } from "react";

import { useIrFormsContext } from "./context/useIrForms";
import DownloadFormsConfirmationModal from "./table/actions/DownloadFormsConfirmationModal";
import FormsTableContent from "./table/FormsTableContent";
import Totals from "./totals/Totals";
import IrFormYearUtilityDropdowns from "../shared/IrFormYearUtilityDropdowns";

export enum FormsTrackingIds {
  DownloadFormsButton = "ir-forms-download-forms-button",
  EligibilityPageLink = "ir-forms-eligibility-page-link",
  LoadingSpinner = "ir-forms-loading-spinner",
}

const Forms = () => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const { buildOrganizationPrefixedPath } = useOrganizationPrefixedNavigation();
  const {
    aggregates,
    isLoading,
    currentForm,
    currentYear,
    ...irDropdownProps
  } = useIrFormsContext();

  const content = (() => {
    if (!isDefined(currentForm) || !isDefined(currentYear)) {
      return <MissingDashboardConfigEmptyState />;
    }

    if (isLoading) {
      return <ContentSpinner trackingId={FormsTrackingIds.LoadingSpinner} />;
    }

    if (aggregates && aggregates.total <= 0) {
      return (
        <Box>
          <ContentEmptyState
            title="No Forms Available"
            subtitleElement={
              <>
                Visit the{" "}
                <Link
                  label="eligibility page"
                  variant="anchor-primary-inline"
                  to={buildOrganizationPrefixedPath("/ir/eligibility")}
                  trackingId={FormsTrackingIds.EligibilityPageLink}
                />{" "}
                to review account eligibility and generate forms. Once
                generated, your forms will appear here.
              </>
            }
            avatarProps={{
              iconName: "file-text",
              variant: "secondary",
            }}
          />
        </Box>
      );
    }

    return (
      <>
        <Totals />
        <FormsTableContent />
      </>
    );
  })();

  return (
    <DashboardPrimaryTemplate
      title="Forms"
      utilityElement={
        isDefined(currentForm) && isDefined(currentYear) ? (
          <Flex direction="row" gap="s">
            <IrFormYearUtilityDropdowns
              {...irDropdownProps}
              isDisabled={isLoading}
              currentForm={currentForm}
              currentYear={currentYear}
            />
            <Button
              label="Download Forms"
              isDisabled={
                isLoading || !aggregates?.total || aggregates.total <= 0
              }
              onClick={() => setIsDownloadModalOpen(true)}
              trackingId={FormsTrackingIds.DownloadFormsButton}
            />
          </Flex>
        ) : undefined
      }
    >
      {content}
      {isDownloadModalOpen && (
        <DownloadFormsConfirmationModal
          isOpen={isDownloadModalOpen}
          onClose={() => setIsDownloadModalOpen(false)}
          isBulkDownload={true}
        />
      )}
    </DashboardPrimaryTemplate>
  );
};

export default Forms;
