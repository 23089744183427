import { dateTimeStringSchema } from "@taxbit-private/datetime";
import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import {
  KycCuringStatus,
  KycResubmissionStatus,
  KycTaxDocumentationDac7Status,
  KycTaxDocumentationIssueStatus,
  KycTaxDocumentationIssueType,
  KycTaxDocumentationSubmissionStatus,
  KycTaxdocumentationVatStatus,
  KycTaxDocumentStatus,
  KycTaxDocumentType,
  KycTinVerificationStatus,
  taxDocumentationWithUuidSchema,
} from "./kycSharedTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export type KycDocumentId = StringType<"DocumnetId">;
const kycDocumentIdSchema = z
  .string()
  .refine((formId) => uuidSchema.safeParse(formId).success)
  .transform((formId) => formId as KycDocumentId);

export const getKycDocumentSchema = getPublicApiSuccessSchema(
  z.object({
    id: kycDocumentIdSchema,
    status: z.string(),
    type: z.nativeEnum(KycTaxDocumentType),
    createdDate: z.string().optional(),
    url: z.string().optional(),
  })
);
export type GetKycDocument = z.infer<typeof getKycDocumentSchema>;

export const kycDocumentGenerationParamsSchema = z.object({
  documentType: z.nativeEnum(KycTaxDocumentType),
});

export type KycDocumentGenerationParams = z.infer<
  typeof kycDocumentGenerationParamsSchema
>;

export const getTaxDocumentationWithUuidSchema = getPublicApiSuccessSchema(
  taxDocumentationWithUuidSchema
);

export const kycIssueSchema = z.object({
  issueType: z.nativeEnum(KycTaxDocumentationIssueType),
  issueStatus: z.nativeEnum(KycTaxDocumentationIssueStatus),
  curingStatus: z.nativeEnum(KycCuringStatus),
  resubmissionStatus: z.nativeEnum(KycResubmissionStatus),
});

export type KycIssue = z.infer<typeof kycIssueSchema>;

export const w9KycTaxDocumentationSummarySchema = z.object({
  documentType: z.literal(KycTaxDocumentType.W9),
  status: z.nativeEnum(KycTaxDocumentStatus),
  tinVerificationStatus: z.nativeEnum(KycTinVerificationStatus).optional(),
  tinVerificationDate: dateTimeStringSchema.optional(),
  id: uuidSchema,
});

export const w8KycTaxDocumentationSummarySchema = z.object({
  documentType: z.enum([KycTaxDocumentType.W8Ben, KycTaxDocumentType.W8BenE]),
  status: z.nativeEnum(KycTaxDocumentStatus),
  id: uuidSchema,
  issues: kycIssueSchema.array(),
  expirationDate: dateTimeStringSchema,
});

export const kycTaxDocumentationSummarySchema = z.discriminatedUnion(
  "documentType",
  [w9KycTaxDocumentationSummarySchema, w8KycTaxDocumentationSummarySchema]
);

const kycTaxDocumentationDac7InterviewSchema = z.object({
  dataCollectionStatus: z.nativeEnum(KycTaxDocumentationDac7Status),
  expirationDate: dateTimeStringSchema.optional(),
  vatStatus: z.nativeEnum(KycTaxdocumentationVatStatus).optional(),
  vatValidationDate: dateTimeStringSchema.optional(),
});

export const kycTaxDocumentationStatusSchema = z.object({
  submissionStatus: z.nativeEnum(KycTaxDocumentationSubmissionStatus),
  dac7Interview: kycTaxDocumentationDac7InterviewSchema,
});

export type W9KycTaxDocumentationSummary = z.infer<
  typeof w9KycTaxDocumentationSummarySchema
>;

export type KycTaxDocumentationSummary = z.infer<
  typeof kycTaxDocumentationSummarySchema
>;

export const getKycTaxDocumentationSummarySchema = getPublicApiSuccessSchema(
  kycTaxDocumentationSummarySchema
);
export type GetKycTaxDocumentationSummaryResponse = z.infer<
  typeof getKycTaxDocumentationSummarySchema
>;

export type KycTaxDocumentationStatus = z.infer<
  typeof kycTaxDocumentationStatusSchema
>;

export const getKycTaxDocumentationStatusSchema = getPublicApiSuccessSchema(
  kycTaxDocumentationStatusSchema
);
export type GetKycTaxDocumentationStatusResponse = z.infer<
  typeof getKycTaxDocumentationStatusSchema
>;
