import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  UmcApiReportType,
  TaxReportType,
  GetTaxReportStatus,
} from "@taxbit-dashboard/rest";

import useMapApiReport from "./useMapApiReport";
import useMapTaxReports from "./useMapTaxReports";
import { useGetTaxReports } from "../../../api/taxReportsApi";
import { useGetUmcReports } from "../../../api/umcReportsApi";
import { UmcReport, UmcTaxReport } from "../types/umcReportTypes";

const sortTaxReports = (reports: UmcTaxReport[] | undefined): UmcReport[] => {
  if (!reports) {
    return [];
  }

  const taxReportsCustomSortOrder: Record<TaxReportType, number> = {
    [TaxReportType.ReconciliationReport]: 1,
    [TaxReportType.TransactionCountsReport]: 2,
    [TaxReportType.TransactionIdReport]: 3,
    [TaxReportType.NegativeAssetBalanceReport]: 4,
    [TaxReportType.AccountIdReport]: 5,
    [TaxReportType.AccountValidationReport]: 6,
    [TaxReportType.TinReport]: 7,
    [TaxReportType.TinActionNeeded]: 8,
    [TaxReportType.TaxDocReport]: 9,
    [TaxReportType.TaxDocActionNeeded]: 10,
    [TaxReportType.VatReport]: 11,
    [TaxReportType.VatActionNeeded]: 12,
    [TaxReportType.FormsStatusReport]: 13,
  };

  return reports.sort(
    (a, b) =>
      taxReportsCustomSortOrder[a.key] - taxReportsCustomSortOrder[b.key]
  );
};

const useGetAllReports = (statuses?: GetTaxReportStatus[]) => {
  const {
    hasTaxDocumentationAccess,
    hasTinMatchingServiceAccess,
    hasIrwCustomReportsAccess,
    hasDac7AccountAccess,
    hasTaxCannedReportsAccess,
    hasAccountValidationCannedReportAccess,
    hasFormsStatusCannedReportAccess,
    hasTransactionCountsCannedReportAccess,
  } = useDashboardFeatureFlags();

  const {
    isFetching: isTaxReportsLoading,
    isError: isTaxReportsError,
    data: taxReports,
  } = useGetTaxReports(
    hasTaxDocumentationAccess ||
      hasTinMatchingServiceAccess ||
      hasDac7AccountAccess ||
      hasTaxCannedReportsAccess ||
      hasAccountValidationCannedReportAccess ||
      hasFormsStatusCannedReportAccess ||
      hasTransactionCountsCannedReportAccess,
    [
      ...(hasTaxCannedReportsAccess
        ? [
            TaxReportType.AccountIdReport,
            TaxReportType.NegativeAssetBalanceReport,
            TaxReportType.ReconciliationReport,
            TaxReportType.TransactionIdReport,
          ]
        : []),
      ...(hasAccountValidationCannedReportAccess
        ? [TaxReportType.AccountValidationReport]
        : []),
      ...(hasFormsStatusCannedReportAccess
        ? [TaxReportType.FormsStatusReport]
        : []),
      ...(hasTransactionCountsCannedReportAccess
        ? [TaxReportType.TransactionCountsReport]
        : []),
      ...(hasTaxDocumentationAccess
        ? [TaxReportType.TaxDocReport, TaxReportType.TaxDocActionNeeded]
        : []),
      ...(hasTinMatchingServiceAccess
        ? [TaxReportType.TinReport, TaxReportType.TinActionNeeded]
        : []),
      ...(hasDac7AccountAccess
        ? [TaxReportType.VatReport, TaxReportType.VatActionNeeded]
        : []),
    ]
  );

  const {
    isFetching: isUmcCustomReportsLoading,
    isError: isUmcCustomReportsError,
    data: umcCustomReports,
  } = useGetUmcReports(UmcApiReportType.Custom, hasIrwCustomReportsAccess);

  const mapApiReport = useMapApiReport();
  const mapTaxReports = useMapTaxReports();

  const mappedApiReports =
    umcCustomReports?.map((report) => mapApiReport(report)) ?? [];
  const mappedTaxReports = mapTaxReports(taxReports, statuses);

  return {
    isError: isTaxReportsError && isUmcCustomReportsError,
    isLoading: isTaxReportsLoading || isUmcCustomReportsLoading,
    reports: [...sortTaxReports(mappedTaxReports), ...mappedApiReports],
  };
};

export default useGetAllReports;
