import {
  DashboardPrimaryTemplate,
  getTopNavigationHeight,
} from "@taxbit-dashboard/commons";
import {
  Box,
  Button,
  Divider,
  TrimmedBoxContent,
} from "@taxbit-private/cosmic";
import styled from "styled-components";

import NotificationsPagePagination from "./NotificationsPagePagination";
import NotificationsListContents from "../NotificationsListContents";
import useNotificationsContext, {
  NotificationsContext,
} from "../useNotificationsContext";

export enum NotificationsPageTrackingId {
  MarkAllAsRead = "notifications-page-mark-all-as-read",
  Spinner = "notifications-page-spinner",
}

const NotificationsPage = () => {
  const {
    hasUnreadNotifications,
    isMarkingAllAsRead,
    markAllNotificationsAsRead,
    tabConfigs,
  } = useNotificationsContext(NotificationsContext.Page);

  return (
    <PageWrapper>
      <TemplateWrapper>
        <DashboardPrimaryTemplate
          title="Notifications"
          tabs={tabConfigs}
          utilityElement={
            <Button
              label="Mark all as read"
              trackingId={NotificationsPageTrackingId.MarkAllAsRead}
              iconName="check"
              variant="button-secondary"
              onClick={markAllNotificationsAsRead}
              isDisabled={!hasUnreadNotifications || isMarkingAllAsRead}
            />
          }
        >
          <Box>
            <TrimmedBoxContent trim="all">
              <NotificationsListContents context={NotificationsContext.Page} />
              <Divider />
              <NotificationsPagePagination />
            </TrimmedBoxContent>
          </Box>
        </DashboardPrimaryTemplate>
      </TemplateWrapper>
    </PageWrapper>
  );
};
const TemplateWrapper = styled.div`
  width: 100%;
`;

const PageWrapper = styled.div(
  ({ theme }) => `
  position: fixed;
  top: ${getTopNavigationHeight(theme)};
  width: 100%;
  height: calc(100vh - ${getTopNavigationHeight(theme)});
  overflow: auto;
  display: flex;
  justify-content: center;
`
);

export default NotificationsPage;
