import { useDashboardStore } from "@taxbit-dashboard/commons";
import { useMemo, useEffect, useCallback } from "react";

import {
  useGetNotificationsByCategory,
  useMarkAllNotificationsAsRead,
} from "../../api/notifications/notificationsApi";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "../../api/notifications/notificationsApiTypes";

export enum NotificationToastTrackingId {
  MarkAsReadError = "mark-as-read-error-toast",
  MarkAllAsReadError = "mark-all-as-read-error-toast",
}

export const notificationsTabTitleMap = {
  [NotificationsTab.All]: "All",
  [NotificationsTab.Read]: "Read",
  [NotificationsTab.Unread]: "Unread",
};

const useNotifications = (params: NotificationsPageParams) => {
  const {
    isLoading,
    allNotifications,
    unreadNotifications,
    readNotifications,
    unreadCount,
    readCount,
    allCount,
    isError: isErrorLoadingNotifications,
  } = useGetNotificationsByCategory(params);

  const { mutate: markAllAsRead, isLoading: isMarkingAllAsRead } =
    useMarkAllNotificationsAsRead();

  const addToast = useDashboardStore((store) => store.addToast);

  const hasUnreadNotifications = unreadCount > 0;

  useEffect(() => {
    if (hasUnreadNotifications) {
      document.title = `Taxbit (${unreadCount})`;
    } else {
      document.title = `Taxbit`;
    }
  }, [hasUnreadNotifications, unreadCount]);

  const [filteredNotifications, totalCount] = useMemo(() => {
    switch (params.tab) {
      case NotificationsTab.Read: {
        return [readNotifications, readCount];
      }
      case NotificationsTab.Unread: {
        return [unreadNotifications, unreadCount];
      }
      default: {
        return [allNotifications, allCount];
      }
    }
  }, [
    params.tab,
    readNotifications,
    readCount,
    unreadNotifications,
    unreadCount,
    allNotifications,
    allCount,
  ]);

  const markAllNotificationsAsRead = useCallback(() => {
    markAllAsRead(undefined, {
      onError: () => {
        addToast({
          message:
            "Failed to mark all notifications as read. Please try again.",
          trackingId: NotificationToastTrackingId.MarkAsReadError,
          timeoutMs: 5000,
          variant: "danger",
        });
      },
    });
  }, [addToast, markAllAsRead]);

  return {
    filteredNotifications,
    hasUnreadNotifications,
    isLoading,
    unreadCount,
    totalCount,
    // TODO: Also include errors to connect to web socket
    isError: isErrorLoadingNotifications,
    markAllNotificationsAsRead,
    isMarkingAllAsRead,
  };
};

export default useNotifications;
