import {
  UsTaxIdType,
  TaxDocumentationTaxIdType,
  TaxIdType,
  AccountServiceTinType,
} from "@taxbit-dashboard/rest";

const maskTaxId = (
  taxId?: string,
  taxIdType?:
    | TaxIdType
    | TaxDocumentationTaxIdType
    | UsTaxIdType
    | AccountServiceTinType
): string | undefined => {
  if (!taxId) {
    return undefined;
  }

  switch (taxIdType) {
    case TaxIdType.UsEin:
    case TaxDocumentationTaxIdType.Ein: {
      return `**-***${taxId.slice(-4)}`;
    }
    case TaxIdType.UsSsn:
    case TaxIdType.UsItin:
    case TaxDocumentationTaxIdType.Ssn:
    case TaxDocumentationTaxIdType.Itin: {
      return `***-**-${taxId.slice(-4)}`;
    }
    default: {
      return `*******${taxId.slice(-2)}`;
    }
  }
};

export default maskTaxId;
