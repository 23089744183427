import { zodResolver } from "@hookform/resolvers/zod";
import {
  useCreatePayer,
  useDashboardStore,
  createSingleInstanceHookContext,
  useUpdatePayer,
} from "@taxbit-dashboard/commons";
import { Payer } from "@taxbit-dashboard/rest";
import { ModalProps } from "@taxbit-private/cosmic";
import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import {
  FilerSetupModalTrackingId,
  baseDefaultValues,
  filerSetupBaseDetailsSchema,
  addressDefaultValues,
  filerSetupAddressDetailsSchema,
  FilerSetupBaseDetails,
  FilerSetupAddressDetails,
  FilerSetupFormFields,
  filerLogoFormFieldsSchema,
  FilerLogoFormFields,
  transformPayerToUpdatePayer,
} from "./filerSetupModalTypes";
import FilerAddressDetails from "./forms/FilerAddressDetails";
import FilerBaseDetails from "./forms/FilerBaseDetails";
import FilerLogoUpload from "./forms/FilerLogoUpload";
import { TOAST_TIMEOUT } from "../../utils/toastTimeout";

export const FILER_SETUP_FORM_ID = "filer-setup-form";

export type FormData =
  | FilerSetupBaseDetails
  | FilerSetupAddressDetails
  | FilerLogoFormFields;

export const useFilerSetupModal = () => {
  const { mutate: createPayer, isLoading: isLoadingCreatePayer } =
    useCreatePayer();
  const { mutate: updatePayer, isLoading: isLoadingUploadPayer } =
    useUpdatePayer();
  const addToast = useDashboardStore((store) => store.addToast);
  const [currentStep, setCurrentStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payer, setPayer] = useState<Payer | undefined>(undefined);

  const defaultValues = useMemo(
    () => ({
      ...baseDefaultValues,
      ...addressDefaultValues,
      ...(payer ? transformPayerToUpdatePayer(payer) : {}),
    }),
    [payer]
  );

  const baseFormMethods = useForm<FormData>({
    resolver: zodResolver(filerSetupBaseDetailsSchema),
    defaultValues,
  });

  const addressFormMethods = useForm<FormData>({
    resolver: zodResolver(filerSetupAddressDetailsSchema),
    defaultValues,
  });

  const logoFormMethods = useForm<FormData>({
    resolver: zodResolver(filerLogoFormFieldsSchema),
    defaultValues: {
      logos: [],
    },
  });

  const formMethods = useMemo(() => {
    if (currentStep === 1) return addressFormMethods;
    if (currentStep > 1) return logoFormMethods;
    return baseFormMethods;
  }, [currentStep, addressFormMethods, logoFormMethods, baseFormMethods]);

  const handleModalOpen = ({
    isOpen,
    payerToUpdate,
    isLogoStep,
  }: {
    isOpen: boolean;
    payerToUpdate?: Payer;
    isLogoStep?: boolean;
  }) => {
    setIsModalOpen(isOpen);
    resetForms(payerToUpdate, isLogoStep);
  };

  const resetForms = useCallback(
    (payerToUpdate?: Payer, isLogoStep?: boolean) => {
      const resetDefaultValues = {
        ...baseDefaultValues,
        ...addressDefaultValues,
        ...(payerToUpdate ? transformPayerToUpdatePayer(payerToUpdate) : {}),
      };
      baseFormMethods.reset(resetDefaultValues);
      addressFormMethods.reset(resetDefaultValues);
      logoFormMethods.reset({
        logos: [],
      });
      setCurrentStep(isLogoStep ? 2 : 0);
      setPayer(payerToUpdate);
    },
    [baseFormMethods, addressFormMethods, logoFormMethods]
  );

  const isLoading = isLoadingCreatePayer || isLoadingUploadPayer;

  const incrementStep = useCallback(
    () => setCurrentStep((current) => current + 1),
    []
  );

  const decrementStep = useCallback(
    () => setCurrentStep((current) => current - 1),
    []
  );

  const onSubmit = useCallback(() => {
    const baseFormData = filerSetupBaseDetailsSchema.parse(
      baseFormMethods.getValues()
    );
    const addressFormData = filerSetupAddressDetailsSchema.parse(
      addressFormMethods.getValues()
    );
    const logoFormData = filerLogoFormFieldsSchema.parse(
      logoFormMethods.getValues()
    );

    const fullFormData: FilerSetupFormFields = {
      ...baseFormData,
      ...addressFormData,
    };

    const createPayerData = {
      ...fullFormData,
      address: {
        firstLine: fullFormData.addressLine1,
        secondLine: fullFormData.addressLine2,
        city: fullFormData.city,
        stateOrProvince: fullFormData.stateOrProvince,
        postalCode: fullFormData.zipCode,
        country: fullFormData.addressCountry,
      },
      ein: fullFormData.ein,
      taxCountryCode: fullFormData.taxCountry,
      payerName: fullFormData.filerName,
      phone: fullFormData.phoneNumber,
      dispositionMethod: fullFormData.dispositionMethod,
      gmtOffsetMinutes: fullFormData.timeOffset || 0,
      isDefault: undefined,
    };

    const formInput = {
      data: createPayerData,
      logo: logoFormData.logos?.[0],
    };

    if (payer?.payerId) {
      updatePayer(
        {
          payerId: payer.payerId,
          formInput,
        },
        {
          onSuccess: () => {
            addToast({
              message: `${payer?.payerName} has been updated successfully!`,
              variant: "primary",
              trackingId: FilerSetupModalTrackingId.UpdateSuccessToast,
              timeoutMs: TOAST_TIMEOUT,
            });
          },
          onError: () => {
            addToast({
              message:
                "An error occurred while updating the Filer. Please try again later.",
              variant: "danger",
              trackingId: FilerSetupModalTrackingId.UpdateErrorToast,
              timeoutMs: TOAST_TIMEOUT,
            });
          },
          onSettled: () => {
            setIsModalOpen(false);
          },
        }
      );
    } else {
      createPayer(formInput, {
        onSuccess: () => {
          addToast({
            message: "Filer has been created successfully!",
            variant: "primary",
            trackingId: FilerSetupModalTrackingId.CreateSuccessToast,
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onError: () => {
          addToast({
            message:
              "An error occurred while creating the Filer. Please try again later.",
            variant: "danger",
            trackingId: FilerSetupModalTrackingId.CreateErrorToast,
            timeoutMs: TOAST_TIMEOUT,
          });
        },
        onSettled: () => {
          setIsModalOpen(false);
        },
      });
    }
  }, [
    baseFormMethods,
    addressFormMethods,
    createPayer,
    updatePayer,
    addToast,
    logoFormMethods,
    payer,
  ]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      void formMethods.handleSubmit(() => {
        if (currentStep < 2) {
          incrementStep();
        } else {
          onSubmit();
        }
      })();
    },
    [currentStep, formMethods, onSubmit, incrementStep]
  );

  const formFields = useMemo(() => {
    switch (currentStep) {
      case 1: {
        return <FilerAddressDetails />;
      }
      case 2: {
        return <FilerLogoUpload />;
      }
      default: {
        return <FilerBaseDetails />;
      }
    }
  }, [currentStep]);

  const modalProps: ModalProps = {
    title: payer ? "Edit Filer Details" : "Add a Filer",
    isOpen: isModalOpen,
    onClose: () => {
      handleModalOpen({ isOpen: false });
    },
    primaryButtonProps: {
      form: FILER_SETUP_FORM_ID,
      type: "submit",
      label: currentStep === 2 ? "Submit" : "Next",
      trackingId: FilerSetupModalTrackingId.SubmitButton,
      isLoading,
    },
    secondaryButtonProps:
      currentStep > 0
        ? {
            onClick: decrementStep,
            label: "Back",
            trackingId: FilerSetupModalTrackingId.BackButton,
            isDisabled: isLoading,
          }
        : undefined,
    closeButtonTrackingId: FilerSetupModalTrackingId.CloseButton,
  };

  return {
    formMethods,
    modalProps,
    formFields,
    handleSubmit,
    handleModalOpen,
  };
};

export const {
  Context: FilerSetupModalContext,
  Provider: FilerSetupModalProvider,
  useContextHook: useFilerSetupModalContext,
} = createSingleInstanceHookContext(
  useFilerSetupModal,
  "useFilerSetupModalContext"
);
