import { StringType } from "@taxbit-private/type-wrappers";
import { uuidSchema } from "@taxbit-private/uuids";
import { z } from "zod";

import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export enum DocumentType {
  W9 = "W9",
  W8Ben = "W-8BEN",
  Unknown = "UNKNOWN",
}

export enum ReleasedDocumentType {
  Irs1099B = "1099_B",
  Irs1099Misc = "1099_MISC",
  Irs1099K = "1099_K",
  Irs1099R = "1099_R",
  Irs1099Nec = "1099_NEC",
  Irs1099Div = "1099_DIV",
  Irs1099Int = "1099_INT",
  Irs5498 = "IRS_5498",
  RmdStatement = "RMD_STATEMENT",
  Irs8949 = "IRS_8949",
  Irs1042S = "IRS_1042_S",
  GainLossSummary = "GAIN_LOSS_SUMMARY",
  TransactionSummary = "TRANSACTION_SUMMARY",
  UkGainLossSummary = "UK_GAIN_LOSS_SUMMARY",
  GainLossPdf = "GAIN_LOSS_PDF",
  TransactionSummaryPdf = "TRANSACTION_SUMMARY_PDF",
  Dac7 = "DAC7",
  Unknown = "UNKNOWN",
}

export enum ReleasedDocumentRevisionType {
  Original = "ORIGINAL",
  Correction = "CORRECTION",
  Void = "VOID",
}

export enum ReleasedDocumentRevisionState {
  Delivered = "DELIVERED",
  Filed = "FILED",
}

export type DocumentId = StringType<"DocumentId">;

export type ReleasedDocumentId = StringType<"ReleasedDocumentId">;
const releasedDocumentIdSchema = z
  .string()
  .refine((formId) => uuidSchema.safeParse(formId).success)
  .transform((formId) => formId as ReleasedDocumentId);

export const releasedDocumentSchema = z.object({
  id: releasedDocumentIdSchema,
  year: z.number(),
  type: z.nativeEnum(ReleasedDocumentType),
  revisionType: z.nativeEnum(ReleasedDocumentRevisionType),
  revisionState: z.nativeEnum(ReleasedDocumentRevisionState),
  dateCreated: z.string(),
  dateDelivered: z.string().optional(),
  dateEFiled: z.string().optional(),
  dateSent: z.string().optional(),
  revision: z.number().optional(),
  url: z.string().optional(),
});
export type ReleasedDocument = z.infer<typeof releasedDocumentSchema>;

export const getReleasedDocumentsResponseSchema = getPublicApiSuccessSchema(
  releasedDocumentSchema.array()
);
export type GetReleasedDocumentsResponse = z.infer<
  typeof getReleasedDocumentsResponseSchema
>;

export const getReleasedDocumentResponseSchema = getPublicApiSuccessSchema(
  releasedDocumentSchema
);
export type GetReleasedDocumentResponse = z.infer<
  typeof getReleasedDocumentResponseSchema
>;

const generateTaxFormsSchema = z.object({
  accountIds: z.string().array().optional(),
  should1099BIncludeCostBasis: z.boolean().optional(),
});

export const generateTaxFormsRequestSchema = getPublicApiSuccessSchema(
  generateTaxFormsSchema
);

export type GenerateTaxFormsRequest = z.infer<
  typeof generateTaxFormsRequestSchema
>;
