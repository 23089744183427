import { getEndpointUrl } from "@taxbit-dashboard/rest";

import { isProdEnvironment } from "../environment";
import { vanillaStore } from "../store/useDashboardStore";

/**
 * Creates a standardized meta object for a react-query hook. This
 * meta object will be used to log additional information about the
 * query/mutation on failure.
 */
const createQueryMetaObject = (endpointUrl: string) => {
  const { organizationId, companyId } = vanillaStore.getState();

  return {
    meta: {
      endpointUrl: `${getEndpointUrl(
        endpointUrl,
        isProdEnvironment() ? "PRODUCTION" : "STAGING"
      )}`,
      organizationId,
      companyId,
    },
  };
};

export type DashboardQueryMeta = ReturnType<
  typeof createQueryMetaObject
>["meta"];

export default createQueryMetaObject;
