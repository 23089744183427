import {
  useLoginWithReturnTo,
  useOrganizationId,
} from "@taxbit-dashboard/commons";
import { Organization } from "@taxbit-dashboard/rest";
import {
  Avatar,
  Dropdown,
  Flex,
  getClickableCss,
  getRgbaForHex,
  getSharedAtomFocusCss,
  H4,
  Icon,
  InlineSpinner,
  Text,
  withTracking,
} from "@taxbit-private/cosmic";
import { useState } from "react";
import styled from "styled-components";

import { useGetCurrentCompanyUserOrganizations } from "../../../../api/currentCompanyUserOrganizationsApi";

const OrganizationPicker = () => {
  const { data: organizations = [], isLoading } =
    useGetCurrentCompanyUserOrganizations();

  const loginWithReturnTo = useLoginWithReturnTo();

  const [isSwitchingOrg, setIsSwitchingOrg] = useState(false);

  const currentOrganizationId = useOrganizationId();
  const currentOrganization = organizations?.find(
    ({ id }) => currentOrganizationId === id
  );

  const handleOrgChange = async (org: Organization) => {
    // We only want to hit Auth0 to change the org, not to re-select it.
    if (org !== currentOrganization) {
      setIsSwitchingOrg(true);
      // Currently, there is no way to switch organizations without a redirect
      // or a popup, but Auth0 has plans to make this possible.
      // https://github.com/auth0/auth0-spa-js/issues/1055
      await loginWithReturnTo({
        authorizationParams: {
          organization: org.authOrganizationId,
        },
      });
    }
  };

  // If we're loading the organizations to pick or switching orgs, display a skeleton loader.
  if (isLoading || isSwitchingOrg) {
    return <WhiteSpinner />;
  }

  // If we don't have any organizations to pick from, don't display the picker.
  if (organizations.length === 0 || !currentOrganization) {
    return undefined;
  }

  const hasOneOrganization = organizations.length === 1;

  const pickerContents = (
    <Flex alignItems="center" gap="s" padding="none s">
      <Avatar
        label={currentOrganization.name[0]}
        variant="white"
        size="small"
      />
      <Name forwardedAs="div">{currentOrganization.name}</Name>
      {!hasOneOrganization && <Icon name="chevron-down" />}
    </Flex>
  );

  // If we just have one organization to pick from, display the current org without the picker.
  return hasOneOrganization ? (
    pickerContents
  ) : (
    <Dropdown
      getOptionKey={({ id }) => id}
      getOptionLabel={({ name }) => name}
      onChange={(org) => {
        void handleOrgChange(org);
      }}
      options={organizations}
      trackingId="top-nav-organization-picker"
      value={currentOrganization}
      targetButtonProps={{
        as: TopNavigationButton,
        children: pickerContents,
        trackingId: "top-nav-organization-picker-toggle",
        disabled: isSwitchingOrg || isLoading,
      }}
    />
  );
};

const Name = styled(H4)(
  ({ theme }) => `
  color: ${theme.color.white};
`
);

const WhiteSpinner = styled(InlineSpinner)(
  ({ theme }) => `
  color: ${theme.color.white}`
);

export const TopNavigationButton = withTracking(
  styled.button.withConfig<{
    isMenuOpen?: boolean;
  }>({
    shouldForwardProp: (propName) => !["isMenuOpen"].includes(propName),
  })(
    ({ theme, isMenuOpen = false, disabled }) => `
  ${getClickableCss(theme, { variant: "invisible" })}

  display: flex;
  align-items: center;

  // TODO: Replace with skeleton loader per spec.
  // https://taxbit.atlassian.net/browse/TAX-22178
  ${
    disabled
      ? `
    opacity: 50%;
    pointer-events: none;  
  `
      : ""
  };

  height: ${theme.sizing.l};
  border-radius: ${theme.borderRadius.m};

  &, ${Text} {
    color: ${theme.color.white} !important;
  }

  &:focus-visible {
    ${getSharedAtomFocusCss(theme)}
  }

  ${
    isMenuOpen
      ? `
        background-color: ${getRgbaForHex(theme.color.white, 0.1)};

        &:hover {
          background-color: ${getRgbaForHex(theme.color.white, 0.2)};
        }

        &:active {
          background-color: ${getRgbaForHex(theme.color.white, 0.25)};
        }

        // The standard focus ring applies its own 1px white border,
        // so we can omit our own when in that state.
        &:not(:focus-visible) {
          box-shadow: 0 0 0 ${theme.borderWidth.s} ${theme.color.white};
        }

      `
      : `
        &:hover {
          background-color: ${getRgbaForHex(theme.color.white, 0.1)};
        }

        &:active {
          background-color: ${getRgbaForHex(theme.color.white, 0.2)};
        }  
      `
  }
`
  )
);

export default OrganizationPicker;
