import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { TaxReportStatus } from "@taxbit-dashboard/rest";
import { ActionButton } from "@taxbit-private/cosmic";

import { useDownloadReport } from "./useDownloadReport";
import { useGetTaxReportStatuses } from "../../../api/taxReportsApi";
import { UmcReport } from "../types/umcReportTypes";

export const DownloadReportTrackingId = "downloadIrwReportBtn";

type DownloadReportButtonProps = {
  report: UmcReport;
};

const DownloadReportButton: React.FC<DownloadReportButtonProps> = ({
  report,
}) => {
  const { hasOnDemandReportsAccess } = useDashboardFeatureFlags();
  const { data: taxReportStatuses } = useGetTaxReportStatuses();
  const { isLoading, onReportClick } = useDownloadReport(report);

  const reportStatus = taxReportStatuses?.find(
    (status) => status.reportName === report.key
  );

  const isDisabled =
    hasOnDemandReportsAccess &&
    (!reportStatus?.lastGenerated ||
      reportStatus?.status === TaxReportStatus.InProgress);

  return (
    <ActionButton
      isLoading={isLoading}
      isDisabled={isDisabled}
      label={isLoading ? undefined : "Download"}
      iconName="download"
      trackingId={DownloadReportTrackingId}
      onClick={onReportClick}
    />
  );
};

export default DownloadReportButton;
