import { z } from "zod";

import { environmentSchema } from "./Environment";

export const authConfigSchema = z.object({
  getAccessToken: z.function().returns(z.promise(z.string())),
  environment: environmentSchema.optional(),
});

type AuthConfig = z.infer<typeof authConfigSchema>;

export default AuthConfig;
