import { AssetAmount, Transaction } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import calculateFeeFiatValue from "../../../../utils/calculateFeeFiatValue";
import calculateTransactionInFiatValue from "../../../../utils/calculateTransactionInFiatValue";
import formatTransactionType from "../../../../utils/formatTransactionType";
import sumUpFees from "../../../../utils/sumUpFees";
import useFormatAsset from "../../../../utils/useFormatAsset";

export type TableTransaction = Transaction & {
  transactionFiatValue?: AssetAmount;
  feeFiatValue?: AssetAmount;
};

export const mapTransactionToTableTransaction = (
  transaction: Transaction
): TableTransaction => ({
  ...transaction,
  transactionFiatValue: calculateTransactionInFiatValue(transaction),
  feeFiatValue: calculateFeeFiatValue(transaction.fees),
});

const useTransactionsTableColumns = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  return useMemo(
    (): TableColumns<TableTransaction> =>
      [
        {
          key: "datetime",
          label: "Date/Time",
          renderCell: (date) => {
            return formatDateTime({ date, format: "DateTime" });
          },
        },
        {
          key: "type",
          label: "Type",
          renderCell: (value) => formatTransactionType(value),
        },
        {
          key: "sent",
          label: "Out",
          textAlign: "right",
          renderCell: (value) =>
            formatAsset({ assetAmount: value?.[0]?.assetAmount }),
        },
        {
          key: "received",
          label: "In",
          textAlign: "right",
          renderCell: (value) =>
            formatAsset({ assetAmount: value?.[0]?.assetAmount }),
        },
        {
          key: "fees",
          label: "Fee",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: sumUpFees(value) }),
        },
        {
          key: "transactionFiatValue",
          label: "Transaction Value",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: value }),
        },
        {
          key: "feeFiatValue",
          label: "Fee Value",
          textAlign: "right",
          renderCell: (value) => formatAsset({ assetAmount: value }),
        },
      ] as const,
    [formatAsset, formatDateTime]
  );
};

export default useTransactionsTableColumns;
