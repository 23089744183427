import { useMatchRoute } from "@tanstack/react-location";
import { useCallback } from "react";

import { buildOrganizationPathPrefix } from "./useOrganizationPrefixedNavigation";

/**
 * Returns a helper to determine whether the current route is a match with the given non-prefixed route.
 * The given route can contain wildcards, as long as they are prefixed by colons (ex: `/accounts/:accountId`).
 */
const useIsSubrouteMatch = () => {
  const matchRoute = useMatchRoute();

  return useCallback(
    (subroute: string) => {
      const match = matchRoute({
        to: `${buildOrganizationPathPrefix(":organizationId")}/${subroute}`,
      });

      return !!match;
    },
    [matchRoute]
  );
};

export default useIsSubrouteMatch;
