import { isDefined, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  AccountOwner,
  AccountOwnerDetailsTaxDocumentationIssueType,
  AccountOwnerIssue,
  KycTaxDocumentType,
  KycTaxDocumentation,
  KycTaxDocumentationSummary,
  TaxDocumentStatusValidationResult,
} from "@taxbit-dashboard/rest";

import { TaxDocDetails } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

const getFormDetailsName = (name: string, dbaName?: string) =>
  `${name}${dbaName ? ` (${dbaName})` : ""}`;

export const getIssues = (
  issues?: AccountOwnerIssue[],
  latestTaxDocumentationGroupNumber?: number,
  latestTaxDocumentation?: KycTaxDocumentation
) => {
  if (!issues) {
    return [];
  }

  const latestGroupIssues = issues.filter(
    (issue) =>
      issue.groupNumber === latestTaxDocumentationGroupNumber ||
      issue.groupNumber === 0
  );

  const unresolvedIssues = latestGroupIssues.filter(
    (issue) => !issue.resolvedAt
  );

  const existingIssueTypes = new Set(
    unresolvedIssues.map((issue) => issue.issueType)
  );

  const taxDocUsIndiciaIssue = (() => {
    if (
      !existingIssueTypes.has(
        AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
      )
    ) {
      const usIndiciaIssue = [...issues]
        .reverse()
        .find(
          (issue) =>
            issue.issueType ===
              AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia &&
            issue.accountOwnerCuring
        );

      if (isDefined(usIndiciaIssue)) {
        return [usIndiciaIssue];
      }
    }

    return [];
  })();

  existingIssueTypes.add(
    AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
  );

  const taxDocRelatedIssues = [...latestGroupIssues]
    .reverse()
    .filter((issue) => {
      const isResolvedByLatestTaxDocumentation =
        issue.resolvedAt && latestTaxDocumentation
          ? issue.resolvedAt === latestTaxDocumentation.createdAt
          : false;

      if (
        !existingIssueTypes.has(issue.issueType) &&
        // we don't show resolved Expired Form issues
        issue.issueType !==
          AccountOwnerDetailsTaxDocumentationIssueType.ExpiredForm &&
        isResolvedByLatestTaxDocumentation
      ) {
        existingIssueTypes.add(issue.issueType);

        return true;
      }

      return false;
    });

  const resolvedIssues = [...taxDocRelatedIssues, ...taxDocUsIndiciaIssue].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
  );

  return [...unresolvedIssues, ...resolvedIssues];
};

const getExpirationDate = (signatureTimestamp: string) => {
  const expirationYear = new Date(signatureTimestamp).getUTCFullYear() + 3;
  return new Date(`${expirationYear}-12-31T23:59:59.999Z`).toISOString();
};

const getFormDetailsDeprecated = (
  taxDocumentation?: KycTaxDocumentation,
  taxDocumentationSummary?: KycTaxDocumentationSummary
): TaxDocDetails | undefined => {
  if (
    !taxDocumentation ||
    !taxDocumentationSummary ||
    taxDocumentation.documentType === KycTaxDocumentType.Comprehensive
  ) {
    return undefined;
  }

  const baseData = {
    documentType: taxDocumentation.documentType,
    documentStatus: taxDocumentationSummary.status,
    name: taxDocumentation.name,
    taxClassification: taxDocumentation.taxClassification,
    submissionDate: taxDocumentation.signatureTimestamp,
  };

  if (taxDocumentation.documentType === KycTaxDocumentType.W9) {
    return {
      ...baseData,
      name: `${baseData.name}${
        taxDocumentation.dbaName ? ` (${taxDocumentation.dbaName})` : ""
      }`,
      subjectToBackupWithholding:
        !taxDocumentation.isNotSubjectBackupWithholding,
      isDeprecated: true,
    };
  }

  if (taxDocumentationSummary.documentType !== KycTaxDocumentType.W9) {
    return {
      ...baseData,
      expirationDate: taxDocumentationSummary.expirationDate,
      issues: taxDocumentationSummary.issues,
      isDeprecated: true,
    };
  }

  return undefined;
};

const getFormDetails = (
  issues?: AccountOwnerIssue[],
  taxDocumentation?: KycTaxDocumentation,
  accountOwner?: AccountOwner,
  latestTaxDocumentationGroupNumber?: number
): TaxDocDetails | undefined => {
  const isComprehensive =
    taxDocumentation?.documentType === KycTaxDocumentType.Comprehensive;

  if (!taxDocumentation || isComprehensive) {
    return undefined;
  }

  const baseData = {
    documentType: taxDocumentation.documentType,
    taxClassification: taxDocumentation.taxClassification,
    submissionDate: taxDocumentation.signatureTimestamp,
    documentStatus:
      accountOwner?.validation.taxDocumentStatus?.validationResult ??
      TaxDocumentStatusValidationResult.Undocumented,
  };

  if (taxDocumentation.documentType === KycTaxDocumentType.W9) {
    return {
      ...baseData,
      name: getFormDetailsName(taxDocumentation.name, taxDocumentation.dbaName),
      subjectToBackupWithholding:
        !taxDocumentation.isNotSubjectBackupWithholding,
      isDeprecated: false,
    };
  }

  const currentIssues = getIssues(
    issues,
    latestTaxDocumentationGroupNumber,
    taxDocumentation
  );

  return {
    ...baseData,
    name: getFormDetailsName(taxDocumentation.name),
    expirationDate: getExpirationDate(taxDocumentation.signatureTimestamp),
    issues: currentIssues,
    permanentAddress: taxDocumentation.permanentAddress,
    isDeprecated: false,
  };
};

const useGetFormDetails = () => {
  const {
    isLoading,
    taxDocumentation,
    issues,
    taxDocumentationSummary,
    accountOwner,
    latestTaxDocumentationGroupNumber,
  } = useAccountOwnerTaxDocumentContext();

  const { hasAddressCuringAccess } = useDashboardFeatureFlags();
  return {
    isLoading,
    formDetails: hasAddressCuringAccess
      ? getFormDetails(
          issues,
          taxDocumentation,
          accountOwner,
          latestTaxDocumentationGroupNumber
        )
      : getFormDetailsDeprecated(taxDocumentation, taxDocumentationSummary),
  };
};

export default useGetFormDetails;
