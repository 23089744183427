import {
  pageLimitPaginationParamsSchema,
  searchValueStringSchema,
  utcDateStringSchema,
  withOptionalCatch,
} from "@taxbit-dashboard/commons";
import {
  GetFormsSearchKey,
  GetFormsSort,
  IrFormStatus,
  IrFormType,
  getFormsSearchKeySchema,
  getFormsSortSchema,
  irFormStatusSchema,
  irFormTypeSchema,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

export const DEFAULT_FORMS_SORT: GetFormsSort = "-date_generated";
const sortSchema = getFormsSortSchema.catch(DEFAULT_FORMS_SORT);

const searchKeyStringParams = Object.fromEntries(
  getFormsSearchKeySchema.options.map((key) => [key, searchValueStringSchema])
) as Record<GetFormsSearchKey, typeof searchValueStringSchema>;

export const formsSearchKeyLabelMap: Record<GetFormsSearchKey, string> = {
  account_external_id: "Account ID",
  name: "Name",
};

const formsEnumParamsSchema = z.object({
  statuses: withOptionalCatch(z.array(irFormStatusSchema).optional()),
  types: withOptionalCatch(z.array(irFormTypeSchema).optional()),
});

export const formsEnumParamKeys = Object.keys(
  formsEnumParamsSchema.shape
) as (keyof z.infer<typeof formsEnumParamsSchema>)[];

export const formsTableParams = pageLimitPaginationParamsSchema
  .extend({
    sort: sortSchema,
    startDate: withOptionalCatch(utcDateStringSchema.optional()),
    endDate: withOptionalCatch(utcDateStringSchema.optional()),
    ...searchKeyStringParams,
  })
  .merge(formsEnumParamsSchema);

export type FormsTableParams = z.infer<typeof formsTableParams>;

export const formTypeToLabelMap: Record<IrFormType, string> = {
  [IrFormType.Original]: "Original",
  [IrFormType.Correction]: "Correction",
  [IrFormType.Void]: "Void",
};

export const formStatusToLabelMap: Record<IrFormStatus, string> = {
  [IrFormStatus.Generated]: "Generated",
  [IrFormStatus.Filed]: "Filed",
};
