import { NAVIGATION_SIDEBAR_WIDTH, ThemeType } from "@taxbit-private/cosmic";
import styled from "styled-components";

// This helper lives in commons rather than the top navigation component itself to
// avoid a circular dependency issue between packages.
export const getTopNavigationHeight = (theme: ThemeType) => theme.sizing.xxxl;

/**
 * The basic layout wrapper for the authenticated app. This lives in its
 * own component file to avoid a dependency cycle.
 */
export const AppWrapper = styled.div(
  ({ theme }) => `
  position: fixed;
  top: ${getTopNavigationHeight(theme)};
  width: 100%;
  display: grid;
  grid-template-columns: ${NAVIGATION_SIDEBAR_WIDTH} 1fr;
`
);
