import { useNavigate, useSearch } from "@tanstack/react-location";
import { DashboardFormType } from "@taxbit-dashboard/rest";
import { FourDigitYear } from "@taxbit-private/datetime";
import { useMemo } from "react";

import useSharedFormDropdownData from "./useSharedFormDropdownData";
import validateIrParams, { IrSearchLocationParams } from "./validateIrParams";

/**
 * @deprecated This hook provides parsed data from the shared `irFormTypesByYear` feature flag. All
 * new form flagging should use individual feature flags and `useFormFeatureFlagDropdowns` instead.
 */
const useIrDropdowns = (shouldUseFormItemsForms = false) => {
  const { forms, formTypeToYearsMap, irFormTypesByYear } =
    useSharedFormDropdownData({
      shouldUseFormItemsForms,
    });

  const navigate = useNavigate<IrSearchLocationParams>();

  const rawUrlParams = useSearch<IrSearchLocationParams>();
  const validatedUrlParams = useMemo(() => {
    return validateIrParams(
      rawUrlParams,
      irFormTypesByYear,
      shouldUseFormItemsForms
    );
  }, [rawUrlParams, irFormTypesByYear, shouldUseFormItemsForms]);

  const currentForm = validatedUrlParams?.formType ?? forms[0];

  const onFormChange = (form: DashboardFormType) => {
    navigate({
      search: {
        formType: form,
        formDate: `${
          formTypeToYearsMap[form].find(
            (formYear) => formYear === validatedUrlParams?.formDate
          ) ?? formTypeToYearsMap[form][0]
        }`,
      },
    });
  };

  const onYearChange = (year: FourDigitYear) => {
    navigate({
      search: {
        formType: currentForm,
        formDate: `${year}`,
      },
    });
  };

  return {
    forms,
    currentForm,
    onFormChange,
    years: formTypeToYearsMap[currentForm],
    currentYear:
      validatedUrlParams?.formDate ?? formTypeToYearsMap[forms[0]][0],
    onYearChange,
  };
};

export default useIrDropdowns;
