import {
  accountValidationsEligibilityStatusSchema,
  dashboardEligibilityFormStatusSchema,
  eligibilityStatusSchema,
} from "@taxbit-dashboard/rest";
import { z } from "zod";

export const eligibilityFiltersFormFieldSchema = z.object({
  statuses: z.array(eligibilityStatusSchema),
  accountValidationsEligibilityStatuses: z.array(
    accountValidationsEligibilityStatusSchema
  ),
  formStatuses: z.array(dashboardEligibilityFormStatusSchema),
});

export type EligibilityFiltersFormFields = z.infer<
  typeof eligibilityFiltersFormFieldSchema
>;
