import { ToastConfig, useDashboardStore } from "@taxbit-dashboard/commons";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

enum QaPackageDownloadTrackingIds {
  SuccessToast = "ir-eligibility-qa-package-download-success-toast",
  ErrorToast = "ir-eligibility-qa-package-download-error-toast",
}

export const createQaPackageSuccessToastContent: ToastConfig = {
  message:
    "The QA Package is being prepared. The zip file will appear in the toolbar when ready to download.",
  trackingId: QaPackageDownloadTrackingIds.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const createQaPackageErrorToastContent: ToastConfig = {
  message: "The QA Package failed to download. Please try again.",
  variant: "danger",
  trackingId: QaPackageDownloadTrackingIds.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useEligibilityQaPackageDownload = () => {
  const { createEligibilityPackage, toggleAllSelectedItems, selectedItems } =
    useEligibilityDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  return {
    onDownloadQaPackage: () => {
      toggleAllSelectedItems(false);
      createEligibilityPackage(selectedItems, {
        onSuccess: () => {
          addToast(createQaPackageSuccessToastContent);
        },
        onError: () => {
          addToast(createQaPackageErrorToastContent);
        },
      });
    },
  };
};

export default useEligibilityQaPackageDownload;
