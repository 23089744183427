import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useTaxBitRest,
  unwrapPublicApiWrappedQuery,
  DashboardQueryKey,
  createQueryMetaObject,
  useDashboardFeatureFlags,
  useDashboardStore,
  navigateToUrl,
} from "@taxbit-dashboard/commons";
import { NotificationActionType } from "@taxbit-dashboard/rest";
import { Uuid } from "@taxbit-private/uuids";
import { useCallback } from "react";

import getNotificationsParams from "./getNotificationsParams";
import {
  NotificationsPageParams,
  NotificationsTab,
} from "./notificationsApiTypes";

const useGetNotifications = (params: NotificationsPageParams) => {
  const restSdk = useTaxBitRest();
  const { hasNotificationCenterAccess } = useDashboardFeatureFlags();

  return unwrapPublicApiWrappedQuery(
    useQuery(
      [DashboardQueryKey.Notifications, { ...params }],
      () => restSdk.notifications.get(getNotificationsParams(params)),
      {
        ...createQueryMetaObject(restSdk.notifications.buildPath()),
        enabled: hasNotificationCenterAccess,
      }
    )
  );
};

export const useGetNotificationsByCategory = (
  params: NotificationsPageParams
) => {
  const {
    data: allNotifications = [],
    meta: allMeta,
    isLoading: isLoadingAllNotifications,
    isError: isErrorAllNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.All,
  });

  const allCount = allMeta?.page?.totalCount ?? 0;

  const {
    data: unreadNotifications = [],
    meta: unreadMeta,
    isLoading: isLoadingUnreadNotifications,
    isError: isErrorUnreadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Unread,
  });

  const unreadCount = unreadMeta?.page?.totalCount ?? 0;

  const {
    data: readNotifications = [],
    meta: readMeta,
    isLoading: isLoadingReadNotifications,
    isError: isErrorReadNotifications,
  } = useGetNotifications({
    ...params,
    tab: NotificationsTab.Read,
  });

  const readCount = readMeta?.page?.totalCount ?? 0;

  return {
    allNotifications,
    allCount,
    unreadNotifications,
    unreadCount,
    readNotifications,
    readCount,
    isLoading:
      isLoadingAllNotifications ||
      isLoadingUnreadNotifications ||
      isLoadingReadNotifications,
    isError:
      isErrorAllNotifications ||
      isErrorUnreadNotifications ||
      isErrorReadNotifications,
  };
};

export const useMarkNotificationAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: Uuid) =>
      restSdk.notifications.read.post(notificationId),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useMarkAllNotificationsAsRead = () => {
  const restSdk = useTaxBitRest();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => restSdk.notifications.put(),
    onSuccess: () => {
      void queryClient.invalidateQueries([DashboardQueryKey.Notifications]);
    },
  });
};

export const useFetchNotificationDownloadUrl = (notificationId: Uuid) => {
  const restSdk = useTaxBitRest();
  const addToast = useDashboardStore((store) => store.addToast);

  const { refetch: fetchNotification, isFetching: isFetchingDownloadUrl } =
    useQuery(
      [DashboardQueryKey.Notification, { notificationId }],
      () => restSdk.notifications.notification.get(),
      {
        ...createQueryMetaObject(
          restSdk.notifications.notification.buildPath()
        ),
        // We only want to fetch a fresh version of this notification on button click,
        // so we disable the query from running outside of the 'refetch' call.
        enabled: false,
      }
    );

  const fetchNotificationDownloadUrl = useCallback(async () => {
    const { data: notification } = unwrapPublicApiWrappedQuery(
      await fetchNotification()
    );

    const downloadAction = notification?.actions?.find(
      ({ type }) => type === NotificationActionType.Download
    );

    if (downloadAction?.actionUrl) {
      navigateToUrl(downloadAction.actionUrl);
    } else {
      addToast({
        variant: "danger",
        message: "Failed to download file. Please try again.",
        trackingId: "fetch-notification-download-url-error-toast",
      });
    }
  }, [addToast, fetchNotification]);

  return {
    fetchNotificationDownloadUrl,
    isFetchingDownloadUrl,
  };
};
