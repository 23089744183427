import { useIsMutating } from "@tanstack/react-query";
import {
  DashboardQueryKey,
  createSingleInstanceHookContext,
  useDashboardStore,
  useLocalPageBanner,
} from "@taxbit-dashboard/commons";
import { IrForm } from "@taxbit-dashboard/rest";
import {
  useCosmicTable,
  getEnUsErrorEmptyStateProps,
  useSearchEmptyStateProps,
} from "@taxbit-private/cosmic";
import { useCallback, useMemo, useState } from "react";

import {
  useGetFormsAggregates,
  useRescindIrForms,
} from "../../../../api/information-reporting/forms/formsApi";
import useFormFeatureFlagDropdowns from "../../shared/useFormFeatureFlagDropdowns";
import {
  getDeleteErrorToastContent,
  successDeleteToastContent,
} from "../table/actions/toastContents";
import useFormsTableColumns from "../table/useFormsTableColumns";
import useFormsTableData from "../table/useFormsTableData";
import useFormsTableSort from "../table/useFormsTableSort";

const useIrForms = () => {
  const [selectedRows, setSelectedRows] = useState<IrForm[]>([]);
  const formsDropdownData = useFormFeatureFlagDropdowns({
    featureFlag: "formsPageYearsByFormType",
  });
  const addToast = useDashboardStore((state) => state.addToast);

  const {
    data: aggregates,
    isError: isErrorAggregates,
    isLoading: isLoadingAggregates,
  } = useGetFormsAggregates({
    formType: formsDropdownData.currentForm,
    formDate: formsDropdownData.currentYear,
  });

  const { mutate: rescindForms } = useRescindIrForms({
    formType: formsDropdownData.currentForm,
    formDate: formsDropdownData.currentYear,
    onSettled: (data, error) => {
      const errorCount = data?.errorCount ?? 0;
      const totalCount = data?.totalCount ?? 0;

      if (error || errorCount > 0) {
        addToast(
          getDeleteErrorToastContent({
            errorCount,
            totalCount,
          })
        );
      } else {
        addToast(successDeleteToastContent);
      }
    },
  });

  const isRescindingForms =
    useIsMutating({
      mutationKey: [
        DashboardQueryKey.IrFormsRescind,
        {
          formType: formsDropdownData.currentForm,
          formDate: formsDropdownData.currentYear,
        },
      ],
    }) > 0;

  const {
    isLoading: isFormsLoading,
    isError: isFormsError,
    data: irForms,
    ...formsTableData
  } = useFormsTableData();

  const shouldDisableControls =
    formsTableData.shouldDisableControls || isRescindingForms;

  const irFormsTableSortProps = useFormsTableSort();
  const irFormsRows = useMemo(() => irForms ?? [], [irForms]);
  const columns = useFormsTableColumns();
  const memoizedGetRowKey = useCallback(({ id }: IrForm) => id, []);

  const searchEmptyStateProps = useSearchEmptyStateProps();

  const irFormsTableProps = useCosmicTable({
    isManualSortable: true,
    getRowKey: memoizedGetRowKey,
    shouldShowAutomaticPlaceholders: true,
    rows: irFormsRows,
    columns,
    onRowSelect: setSelectedRows,
    isLoading: shouldDisableControls,
    emptyStateProps: isFormsError
      ? getEnUsErrorEmptyStateProps({ entity: "forms" })
      : searchEmptyStateProps,
    ...irFormsTableSortProps,
  });

  useLocalPageBanner({
    shouldShow: isRescindingForms,
    bannerConfig: {
      shouldShowSpinner: true,
      message: "We are deleting forms. Please wait a few moments.",
    },
  });

  return {
    ...formsDropdownData,
    ...formsTableData,
    aggregates,
    isErrorAggregates,
    isFormsError,
    isLoading: isLoadingAggregates || isFormsLoading,
    isLoadingAggregates,
    irFormsTableProps,
    selectedForms: selectedRows,
    toggleAllSelectedForms:
      irFormsTableProps.tableInstance.toggleAllRowsSelected,
    shouldDisableControls,
    rescindForms,
  };
};

export const {
  useContextHook: useIrFormsContext,
  Context: IrFormsContext,
  Provider: IrFormsContextProvider,
} = createSingleInstanceHookContext(useIrForms, "useIrForms");
