import { KycIssue } from "@taxbit-dashboard/rest/src/types/api/kycTaxDocumentationApiTypes";
import { Modal, Flex } from "@taxbit-private/cosmic";

import useGetIssueDetailsModalInfoDeprecated, {
  IssueDetailsModalTrackingIdDeprecated,
} from "./useGetIssueDetailsModalInfoDeprecated";

type IssueDetailsModalProps = {
  isModalOpen: boolean;
  onModalClose: () => void;
  issue: KycIssue;
};

export const IssueDetailsModalDeprecated: React.FC<IssueDetailsModalProps> = ({
  onModalClose,
  isModalOpen,
  issue,
}) => {
  const { closeBtnProps, content } = useGetIssueDetailsModalInfoDeprecated(
    issue,
    onModalClose
  );

  return (
    <Modal
      title="Issue Details"
      isOpen={isModalOpen}
      onClose={onModalClose}
      primaryButtonProps={closeBtnProps}
      closeButtonTrackingId={IssueDetailsModalTrackingIdDeprecated.CloseBtn}
    >
      <Flex direction="column" gap="m">
        {content}
      </Flex>
    </Modal>
  );
};
