import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import {
  TaxReportType,
  TaxReport,
  GetTaxReportStatus,
} from "@taxbit-dashboard/rest";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useCallback } from "react";

import { UmcReportDisplayType, UmcTaxReport } from "../types/umcReportTypes";

const mapReportTypeToName: Record<TaxReportType, string> = {
  [TaxReportType.AccountIdReport]: "Account Owner and Account IDs",
  [TaxReportType.AccountValidationReport]: "Account Validation",
  [TaxReportType.FormsStatusReport]: "Forms Status",
  [TaxReportType.NegativeAssetBalanceReport]: "Negative Asset Balance",
  [TaxReportType.ReconciliationReport]: "Reconciliation Counts",
  [TaxReportType.TransactionCountsReport]: "Transaction Counts",
  [TaxReportType.TransactionIdReport]: "Transaction IDs",
  [TaxReportType.TaxDocReport]: "Tax Documentation Status",
  [TaxReportType.TaxDocActionNeeded]:
    "Tax Documentation Status (Action Required)",
  [TaxReportType.TinReport]: "TIN Verification Status",
  [TaxReportType.TinActionNeeded]: "TIN Verification Status (Action Required)",
  [TaxReportType.VatReport]: "VAT Validation Status",
  [TaxReportType.VatActionNeeded]: "VAT Validation Status (Action Required)",
};

const mapReportTypeToDescription: Record<TaxReportType, string> = {
  [TaxReportType.AccountIdReport]: "IDs for all accounts ingested to TaxBit",
  [TaxReportType.AccountValidationReport]:
    "Identifies all instances of accounts with incomplete or missing data required for filing with regulatory authorities (e.g., name, address, and TIN)",
  [TaxReportType.FormsStatusReport]:
    "Status and dates of all tax forms generated, delivered, and filed",
  [TaxReportType.NegativeAssetBalanceReport]:
    "Identifies all instances of an asset balance going negative by asset and account",
  [TaxReportType.ReconciliationReport]:
    "Counts of transactions, accounts, and form data within TaxBit's system",
  [TaxReportType.TransactionCountsReport]:
    "Counts of historical and 2023 transactions per user",
  [TaxReportType.TransactionIdReport]:
    "IDs for all transactions ingested to TaxBit",
  [TaxReportType.TaxDocReport]:
    "Forms W-8, W-9, and Self-Certification Status for all accounts",
  [TaxReportType.TaxDocActionNeeded]:
    "Forms W-8, W-9, and Self-Certification Status for accounts that require new or updated documentation",
  [TaxReportType.TinReport]:
    "Results based on the IRS TIN Matching system for all accounts",
  [TaxReportType.TinActionNeeded]:
    "Results based on the IRS TIN Matching system for accounts that are not a Valid Match",
  [TaxReportType.VatReport]:
    "Results based on the VIES system for all accounts",
  [TaxReportType.VatActionNeeded]:
    "Results based on the VIES system for accounts that are not a Valid Match",
};

const mapReportTypeToType: Record<TaxReportType, UmcReportDisplayType> = {
  [TaxReportType.AccountIdReport]: UmcReportDisplayType.Reconciliation,
  [TaxReportType.AccountValidationReport]: UmcReportDisplayType.Accounts,
  [TaxReportType.FormsStatusReport]: UmcReportDisplayType.TaxForms,
  [TaxReportType.NegativeAssetBalanceReport]:
    UmcReportDisplayType.Reconciliation,
  [TaxReportType.ReconciliationReport]: UmcReportDisplayType.Reconciliation,
  [TaxReportType.TransactionCountsReport]: UmcReportDisplayType.Reconciliation,
  [TaxReportType.TransactionIdReport]: UmcReportDisplayType.Reconciliation,
  [TaxReportType.TaxDocReport]: UmcReportDisplayType.Accounts,
  [TaxReportType.TaxDocActionNeeded]: UmcReportDisplayType.Accounts,
  [TaxReportType.TinReport]: UmcReportDisplayType.Accounts,
  [TaxReportType.TinActionNeeded]: UmcReportDisplayType.Accounts,
  [TaxReportType.VatReport]: UmcReportDisplayType.Accounts,
  [TaxReportType.VatActionNeeded]: UmcReportDisplayType.Accounts,
};

const useMapTaxReports = () => {
  const { formatDateTime } = useCosmicLocalizationContext();
  const { hasOnDemandReportsAccess } = useDashboardFeatureFlags();

  return useCallback(
    (
      reports?: TaxReport[],
      statuses?: GetTaxReportStatus[]
    ): UmcTaxReport[] => {
      if (!reports) return [];

      return reports.reduce<UmcTaxReport[]>((acc, report) => {
        const updatedDate = statuses?.find(
          (status) => status.reportName === report.type
        )?.lastGenerated;
        const defaultReportValues = {
          date: formatDateTime({
            date: hasOnDemandReportsAccess ? updatedDate : report.date,
            format: "DateTime",
          }),
          key: report.type,
        };

        return [
          ...acc,
          {
            ...defaultReportValues,
            name: mapReportTypeToName[report.type],
            description: mapReportTypeToDescription[report.type],
            type: mapReportTypeToType[report.type],
          },
        ];
      }, []);
    },
    [formatDateTime, hasOnDemandReportsAccess]
  );
};

export default useMapTaxReports;
