import { DispositionMethod, Payer } from "@taxbit-dashboard/rest";
import { z } from "zod";

export enum FilerSetupModalTrackingId {
  SubmitButton = "filer-setup-modal-submit-button",
  CreateSuccessToast = "filer-setup-modal-create-success-toast",
  CreateErrorToast = "filer-setup-modal-create-error-toast",
  UpdateSuccessToast = "filer-setup-modal-update-success-toast",
  UpdateErrorToast = "filer-setup-modal-update-error-toast",
  BackButton = "filer-setup-modal-back-button",
  CloseButton = "filer-setup-modal-close-button",
  DispositionMethodDropdown = "filer-setup-disposition-method-dropdown",
  DispositionMethodHelpIcon = "filer-setup-disposition-method-help-icon",
  FilerName = "filer-setup-filer-name",
  TaxCountryDropdown = "filer-setup-tax-country-dropdown",
  Ein = "filer-setup-ein",
  EinHelpIcon = "filer-setup-ein-help-icon",
  TimeOffset = "filer-setup-time-offset",
  AddressCountryDropdown = "filer-setup-address-country-dropdown",
  AddressLine1 = "filer-setup-address-line-1",
  AddressLine2 = "filer-setup-address-line-2",
  City = "filer-setup-city-dropdown",
  StateOrProvinceDropdown = "filer-setup-state-or-province-dropdown",
  ZipCode = "filer-setup-zip-code",
  PhoneNumber = "filer-setup-phone-number",
  LogoUploader = "filer-setup-logo-uploader",
}

export const filerSetupBaseDetailsSchema = z.object({
  filerName: z.string().min(1),
  taxCountry: z.string().min(2),
  ein: z.string().regex(/^\d{9}$/, {
    message: "Your EIN must be exactly 9 digits",
  }),
  dispositionMethod: z.nativeEnum(DispositionMethod),
  timeOffset: z.number().optional(),
});

export const baseDefaultValues = {
  filerName: "",
  taxCountry: "US",
  ein: "",
  dispositionMethod: DispositionMethod.UniversalFifo,
  timeOffset: 0,
};

export type FilerSetupBaseDetails = z.infer<typeof filerSetupBaseDetailsSchema>;

export const filerSetupAddressDetailsSchema = z.object({
  addressCountry: z.string().min(2),
  addressLine1: z.string().min(1),
  addressLine2: z.string().optional(),
  city: z.string().min(1),
  stateOrProvince: z.string().min(2),
  zipCode: z.string().min(1),
  phoneNumber: z.string().min(1),
});

export const addressDefaultValues = {
  addressCountry: "US",
  addressLine1: "",
  addressLine2: "",
  city: "",
  stateOrProvince: "",
  zipCode: "",
  phoneNumber: "",
};

export type FilerSetupAddressDetails = z.infer<
  typeof filerSetupAddressDetailsSchema
>;

export const filerSetupFormFieldsSchema = filerSetupBaseDetailsSchema.and(
  filerSetupAddressDetailsSchema
);

export type FilerSetupFormFields = z.infer<typeof filerSetupFormFieldsSchema>;

export const transformPayerToUpdatePayer = (
  payer: Payer
): Partial<FilerSetupFormFields> => {
  return {
    filerName: payer.payerName,
    taxCountry: payer.taxCountryCode,
    ein: payer.ein,
    dispositionMethod: payer.dispositionMethod,
    timeOffset: payer.gmtOffsetMinutes,
    phoneNumber: payer.phone,
    addressCountry: payer.address.country,
    addressLine1: payer.address.firstLine,
    addressLine2: payer.address.secondLine,
    city: payer.address.city,
    stateOrProvince: payer.address.stateOrProvince,
    zipCode: payer.address.postalCode,
  };
};

export const filerLogoFormFieldsSchema = z
  .object({
    logos: z.array(z.instanceof(File)).optional(),
  })
  .superRefine((data, ctx) => {
    const { logos } = data;

    if (!logos) {
      return;
    }

    if (logos.length > 1) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Only one logo file may be uploaded.",
        path: ["logos"],
      });
    }

    const file = logos[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png"];

      if (!validTypes.includes(file.type)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Only JPEG and PNG files are supported.",
          path: ["logos"],
        });
      }

      const MAX_LOGO_SIZE = 2 * 1024 * 1024; // 2MB

      if (file.size > MAX_LOGO_SIZE) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "A logo file must be less than 2MB.",
          path: ["logos"],
        });
      }
    }
  });

export type FilerLogoFormFields = z.infer<typeof filerLogoFormFieldsSchema>;
