import { isDefined, useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import {
  Box,
  ContentEntityEmptyState,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";

import Dac7InterviewDetails from "./dac7-interview-details/Dac7InterviewDetails";
import DownloadTaxDocumentation from "./download-tax-documentation/DownloadTaxDocumentation";
import FormDetails from "./form-details/FormDetails";
import { useAccountOwnerTaxDocumentContext } from "../../../../hooks/useGetAccountOwnerTaxDocumentData";

export enum DocumentationTabTrackingId {
  DocumentationTabSpinner = "documentation-tab-spinner",
}

const DocumentationTab = () => {
  const { hasDac7AccountAccess } = useDashboardFeatureFlags();

  const { isLoading, isError, taxDocumentation } =
    useAccountOwnerTaxDocumentContext();

  const shouldShowDac7InterviewDetails =
    isDefined(taxDocumentation) &&
    taxDocumentation.documentType === KycTaxDocumentType.Comprehensive &&
    hasDac7AccountAccess;

  const shouldShowWFormsDetails =
    isDefined(taxDocumentation) &&
    taxDocumentation.documentType !== KycTaxDocumentType.Comprehensive;

  return isLoading ? (
    <ContentSpinner
      trackingId={DocumentationTabTrackingId.DocumentationTabSpinner}
    />
  ) : isError ? (
    <Box>
      <ContentErrorEmptyState entity="tax documentation" />
    </Box>
  ) : shouldShowDac7InterviewDetails ? (
    <Dac7InterviewDetails />
  ) : shouldShowWFormsDetails ? (
    <>
      <DownloadTaxDocumentation />
      <FormDetails />
    </>
  ) : (
    <Box>
      <ContentEntityEmptyState entity="tax documentation" />
    </Box>
  );
};

export default DocumentationTab;
