import { Payer, payerDispositionMethodLabelMap } from "@taxbit-dashboard/rest";
import { optionalAlphabeticalSort, TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import formatPayerAddress from "./formatPayerAddress";

export const EIN_HELP_ICON_TEXT =
  "An EIN is a unique tax identification number issued by the Internal Revenue Service (IRS) to a business entity, commonly used for the purpose of reporting tax information.";

export enum FilerSetupTableTrackingId {
  HelpIcon = "filer-setup-table-ein-help-icon",
  LoadingSpinner = "filer-setup-table-loading-spinner",
}

const useFilerSetupTableColumns = () => {
  const { formatBoolean } = useCosmicLocalizationContext();

  return useMemo(
    (): TableColumns<Payer> => [
      {
        key: "payerId",
        label: "Filer ID",
        isContentFullWidth: true,
      },
      {
        key: "payerName",
        label: "Name",
        sortMethod: optionalAlphabeticalSort,
      },
      {
        key: "ein",
        label: "EIN",
        helpIconProps: {
          trackingId: FilerSetupTableTrackingId.HelpIcon,
          tooltipProps: {
            content: EIN_HELP_ICON_TEXT,
          },
        },
      },
      {
        key: "dispositionMethod",
        label: "Disp. Method",
        renderCell: (dispositionMethod) =>
          payerDispositionMethodLabelMap[dispositionMethod],
      },
      {
        key: "address",
        label: "Address",
        renderCell: (address) => (
          <>
            {formatPayerAddress(address)?.map((line) => (
              <div key={line}>{line}</div>
            ))}
          </>
        ),
        isContentFullWidth: true,
      },
      {
        key: "phone",
        label: "Phone Number",
        isContentFullWidth: true,
      },
      {
        key: "isDefault",
        label: "Default Filer",
        renderCell: (isDefault: boolean) => formatBoolean(isDefault),
      },
    ],
    [formatBoolean]
  );
};

export default useFilerSetupTableColumns;
