import { ActionButton, getRgbaForHex } from "@taxbit-private/cosmic";
import styled from "styled-components";

// Special non-theme color for the top navigation bar, per design spec.
export const TOP_NAV_COLOR = "#052854";

const TopNavigationActionButton = styled(ActionButton)
  .withConfig<{
    isMenuOpen?: boolean;
  }>({
    shouldForwardProp: (propName) => propName !== "isMenuOpen",
  })
  .attrs({
    variant: "white",
  })(
  ({ theme, isMenuOpen }) => `
  ${
    isMenuOpen
      ? `
    color: ${TOP_NAV_COLOR};
    background-color: ${theme.color.white};

    &:hover {
      background-color: ${getRgbaForHex(theme.color.white, 0.8)};
    }

    &:active {
      background-color: ${getRgbaForHex(theme.color.white, 0.6)};
    }
  `
      : ""
  }
`
);

export default TopNavigationActionButton;
