import {
  DashboardNavigationItem,
  UserPermission,
  AuthorizedView,
} from "@taxbit-dashboard/commons";

import { AccountDetailsTabTrackingId } from "./account-details/AccountDetails";
import AccountDetailsPage from "./account-details/AccountDetailsPage";
import TransactionDetails from "./account-details/transactions/TransactionDetails";
import Accounts from "./accounts/Accounts";

export const navigationItem: DashboardNavigationItem = {
  href: "/accounts",
  iconName: "user",
  label: "Accounts",
  trackingId: "sidebar-navigation-accounts-anchor",
  shouldShowRoute: ({ permissions }) =>
    permissions[UserPermission.ReadAccounts],
  pageContextKeys: [
    "accounts",
    "account-details",
    "transactions",
    "transaction-details",
  ],
} as const;

const accountDetailsPagePaths = Object.values(AccountDetailsTabTrackingId);
const accountDetailsPageChildren = accountDetailsPagePaths.map((path) => ({
  path,
  element: (
    <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
      <AccountDetailsPage />
    </AuthorizedView>
  ),
}));

export const route = {
  path: "accounts",
  children: [
    {
      path: "/",
      element: (
        <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
          <Accounts />
        </AuthorizedView>
      ),
    },
    {
      path: ":accountId",
      children: [
        {
          path: "/",
          element: (
            <AuthorizedView permissions={[UserPermission.ReadAccounts]}>
              <AccountDetailsPage />
            </AuthorizedView>
          ),
        },
        {
          path: `${AccountDetailsTabTrackingId.TransactioinsTab}/:transactionId`,
          element: (
            <AuthorizedView
              permissions={[UserPermission.ReadAccountTransactions]}
            >
              <TransactionDetails />
            </AuthorizedView>
          ),
        },
        ...accountDetailsPageChildren,
      ],
    },
  ],
};
