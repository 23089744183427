import {
  DashboardPrimaryTemplate,
  useSavedScrollPosition,
} from "@taxbit-dashboard/commons";
import { Button, ContentSpinner, Flex } from "@taxbit-private/cosmic";
import { useState } from "react";

import FormDataForWrongCountryCodeUsersAlert from "./alerts/FormDataForWrongCountryCodeUsersAlert";
import GainLossStatementAlert from "./alerts/GainLossStatementAlert";
import PreviouslyEligibleUsersAlert from "./alerts/PreviouslyEligibleUsersAlert";
import { useEligibilityDataContext } from "./context/useEligibilityData";
import EligibilityCounts from "./eligibility-counts/EligibilityCounts";
import EligibilityTableContent from "./EligibilityTableContent";
import GenerateTaxFormsConfirmationModal from "./table/actions/GenerateTaxFormsConfirmationModal";
import IrFormYearUtilityDropdowns from "../shared/IrFormYearUtilityDropdowns";
import useFormFeatureFlagData from "../shared/useFormFeatureFlagData";

export enum EligibleUsersTrackingId {
  GenerateFormsButton = "ir-eligibility-generate-forms-button",
  LoadingSpinner = "ir-eligibility-loading-spinner",
}

const EligibleUsers = () => {
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const { isLoading, shouldDisableControls, ...props } =
    useEligibilityDataContext();

  const { hasYearForForm } = useFormFeatureFlagData({
    featureFlag: "generateFormsYearsByFormType",
  });

  useSavedScrollPosition({ key: "eligibility-page" });

  const content = (() => {
    if (isLoading) {
      return (
        <ContentSpinner trackingId={EligibleUsersTrackingId.LoadingSpinner} />
      );
    } else {
      return (
        <>
          <Flex gap="m" direction="column">
            <PreviouslyEligibleUsersAlert />
            <FormDataForWrongCountryCodeUsersAlert />
            <GainLossStatementAlert />
          </Flex>
          <EligibilityCounts />
          <EligibilityTableContent />
          {isGenerateModalOpen && (
            <GenerateTaxFormsConfirmationModal
              shouldIgnoreSelectedItems={true}
              isOpen={isGenerateModalOpen}
              onClose={() => setIsGenerateModalOpen(false)}
            />
          )}
        </>
      );
    }
  })();

  return (
    <DashboardPrimaryTemplate
      title="Eligibility"
      utilityElement={
        <Flex direction="row" gap="s">
          <IrFormYearUtilityDropdowns {...props} isDisabled={isLoading} />
          {hasYearForForm(props.currentForm, props.currentYear) && (
            <Button
              label="Generate Forms"
              onClick={() => setIsGenerateModalOpen(true)}
              isDisabled={isLoading || shouldDisableControls}
              trackingId={EligibleUsersTrackingId.GenerateFormsButton}
            />
          )}
        </Flex>
      }
    >
      {content}
    </DashboardPrimaryTemplate>
  );
};

export default EligibleUsers;
