import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { Dac7Details } from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

const useGetDac7InterviewDetails = () => {
  const { isLoading, taxDocumentation, taxDocumentationStatus } =
    useAccountOwnerTaxDocumentContext();

  const dac7Details: Dac7Details | undefined = useMemo(() => {
    if (
      !taxDocumentation ||
      !taxDocumentationStatus ||
      taxDocumentation.documentType !== KycTaxDocumentType.Comprehensive
    ) {
      return undefined;
    }

    return {
      interviewStatus:
        taxDocumentationStatus.dac7Interview.dataCollectionStatus,
      name: taxDocumentation.accountHolder?.name,
      isIndividual: taxDocumentation.accountHolder?.isIndividual,
      isEuResident: taxDocumentation.accountHolder?.isEuResident,
      submissionDate: taxDocumentation.createdAt,
      expirationDate: taxDocumentationStatus.dac7Interview.expirationDate,
    };
  }, [taxDocumentation, taxDocumentationStatus]);

  return {
    isLoading,
    dac7Details,
  };
};

export default useGetDac7InterviewDetails;
