import {
  Avatar,
  Flex,
  H4,
  H5,
  Icon,
  PopoverProps,
} from "@taxbit-private/cosmic";
import { forwardRef } from "react";

import { useTopNavigationActionMenuContext } from "./useTopNavigationActionMenu";
import { TopNavigationButton } from "../OrganizationPicker";
import getInitialsForTopNavDropdown from "../shared/getInitialsForTopNavDropdown";

type Props = PopoverProps<typeof TopNavigationButton>["targetButtonProps"];

export enum TopNavigationActionMenuTargetButtonTrackingId {
  Button = "top-navigation-action-menu-target-button",
}

const TopNavigationActionMenuTargetButton = forwardRef<
  typeof TopNavigationButton,
  Props
>((props, ref) => {
  const {
    currentOrganization,
    currentCompanyUser,
    hasMoreThanOneOrganizationForCurrentCompany,
    isSwitchingOrg,
  } = useTopNavigationActionMenuContext();

  return (
    <TopNavigationButton
      ref={ref}
      trackingId={TopNavigationActionMenuTargetButtonTrackingId.Button}
      disabled={isSwitchingOrg}
      {...props}
    >
      <Flex alignItems="center" gap="s" padding="none s">
        <Avatar
          label={getInitialsForTopNavDropdown(currentCompanyUser.name)}
          variant="white"
          size="small"
        />
        {hasMoreThanOneOrganizationForCurrentCompany ? (
          <Flex direction="column" gap="xxs" alignItems="flex-start">
            <H4 as="div">{currentOrganization?.companyName}</H4>
            <H5 as="div">{currentOrganization?.name}</H5>
          </Flex>
        ) : (
          <H4 as="div">{currentOrganization?.name}</H4>
        )}
        <Icon name="chevron-down" color="white" />
      </Flex>
    </TopNavigationButton>
  );
});

export default TopNavigationActionMenuTargetButton;
