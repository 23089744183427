import { zodResolver } from "@hookform/resolvers/zod";
import { hasDefinedValues } from "@taxbit-dashboard/commons";
import { useCallback, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";

import {
  EligibilityFiltersFormFields,
  eligibilityFiltersFormFieldSchema,
} from "./eligibilityFiltersFormFieldSchema";
import { EligibilityTableParams } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";
import { useEligibilityDataContext } from "../context/useEligibilityData";

const getFormDataFromUrlParams = ({
  statuses,
  accountValidationsEligibilityStatuses,
  formStatuses,
}: EligibilityTableParams): EligibilityFiltersFormFields => ({
  statuses: statuses ?? [],
  accountValidationsEligibilityStatuses:
    accountValidationsEligibilityStatuses ?? [],
  formStatuses: formStatuses ?? [],
});

const formDataEmptyValues: EligibilityFiltersFormFields = {
  statuses: [],
  accountValidationsEligibilityStatuses: [],
  formStatuses: [],
};

const useEligibilityFiltersDrawerForm = () => {
  const { urlParams, setFilterParams } = useEligibilityDataContext();

  const defaultValues = useMemo(
    () => getFormDataFromUrlParams(urlParams),
    [urlParams]
  );

  const formMethods = useForm<EligibilityFiltersFormFields>({
    resolver: zodResolver(eligibilityFiltersFormFieldSchema),
    defaultValues,
  });

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      void formMethods.handleSubmit(
        ({ statuses, accountValidationsEligibilityStatuses, formStatuses }) => {
          setFilterParams((draft) => {
            draft.statuses = statuses;
            draft.accountValidationsEligibilityStatuses =
              accountValidationsEligibilityStatuses;
            draft.formStatuses = formStatuses;
          });
        }
      )(e);
    },
    [formMethods, setFilterParams]
  );

  /**
   * React location search param updates are asynchronous, so we must manually
   * reset the form to new url params when they are updated instead of relying
   * on them being available for the initial defaults for the form.
   */
  useEffect(() => {
    formMethods.reset(getFormDataFromUrlParams(urlParams));
  }, [urlParams, formMethods]);

  /**
   * Clears all form filters. We must use the `setValue` form methods to individually
   * mark each state as dirty, in order to not mess with RHF's state handling.
   */
  const clearAllFormFilters = useCallback(() => {
    const values = formMethods.getValues();

    for (const [key, value] of Object.entries(values)) {
      const typedKey = key as keyof EligibilityFiltersFormFields;

      if (value !== formDataEmptyValues[typedKey]) {
        formMethods.setValue(typedKey, formDataEmptyValues[typedKey], {
          shouldDirty: true,
        });
      }
    }
  }, [formMethods]);

  const hasFiltersApplied = hasDefinedValues(formMethods.getValues());

  return {
    formMethods,
    formType: urlParams.formType,
    isFormDirty: formMethods.formState.isDirty,
    resetForm: () => formMethods.reset(defaultValues),
    handleSubmit,
    clearAllFormFilters,
    hasFiltersApplied,
  };
};

export default useEligibilityFiltersDrawerForm;
