import { z } from "zod";

import { taxDocumentationWithUuidSchema } from "./kycSharedTypes";
import {
  accountOwnerCuringSchema,
  accountOwnerIdSchema,
  accountOwnerPiiDtoSchema,
  accountOwnerSchema,
  accountV2Schema,
  companyUserIdSchema,
} from "./sharedApiTypes";
import getPublicApiSuccessSchema from "../getPublicApiSuccessSchema";

export const accountOwnerValidationStatusValues = [
  "IN_PROGRESS",
  "PASSED",
  "FAILED",
  "ERROR",
  "COMPLETE",
] as const;

const accountOwnerValidationStatusSchema = z.enum(
  accountOwnerValidationStatusValues
);

export const accountOwnerGenericValidationSchema = z.object({
  accountOwnerId: accountOwnerIdSchema,
  validationDate: z.string(),
  targetDateModified: z.string(),
  validationType: z.string(),
  validationVersion: z.number(),
  validationStatus: accountOwnerValidationStatusSchema,
  validationResultCode: z.string().optional(),
  validationResults: z.any().optional(),
  validationErrors: z.string().array().optional(),
});

const accountOwnerDetailsPiiSchema = accountOwnerPiiDtoSchema.extend({
  birthCity: z.string().optional(),
  birthCountry: z.string().optional(),
  vatId: z.string().optional(),
  vatIdMasked: z.string().optional(),
  vatCountry: z.string().optional(),
  businessRegistrationNumber: z.string().optional(),
  businessRegistrationNumberMasked: z.string().optional(),
  businessRegistrationCountry: z.string().optional(),
  financialAccountId: z.string().optional(),
  financialAccountIdMasked: z.string().optional(),
  financialAccountHolderName: z.string().optional(),
});

const validationStatusSchema = z.enum(["IN_PROGRESS", "COMPLETE"]);

export const IRS_TIN_MATCH_VALIDATION_TYPE = "IRS_TIN_MATCH" as const;

const irsTinMatchValidationTypeSchema = z.literal(
  IRS_TIN_MATCH_VALIDATION_TYPE
);

export enum IrsTinMatchValidationResult {
  Pending = "PENDING",
  Foreign = "FOREIGN",
  InvalidData = "INVALID_DATA",
  ValidSsnMatch = "VALID_SSN_MATCH",
  ValidEinMatch = "VALID_EIN_MATCH",
  ValidSsnEinMatch = "VALID_SSN_EIN_MATCH",
  Mismatch = "MISMATCH",
  TinNotIssued = "TIN_NOT_ISSUED",
  Error = "ERROR",
}

export const irsTinMatchValidationResultSchema = z.nativeEnum(
  IrsTinMatchValidationResult
);

const irsTinMatchValidationSchema = z.object({
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  validationVersion: z.number(),
  validationStatus: validationStatusSchema,
  validationType: irsTinMatchValidationTypeSchema,
  validationResult: irsTinMatchValidationResultSchema,
  validationResponseDate: z.string().optional(),
});

export const VAT_VALIDATION_TYPE = "VAT" as const;

const vatValidationTypeSchema = z.literal(VAT_VALIDATION_TYPE);

const vatValidationResultSchema = z.enum([
  "PENDING",
  "VALID",
  "INVALID",
  "INSUFFICIENT_DATA",
  "NOT_REQUIRED",
]);

export const vatValidationSchema = z.object({
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  validationVersion: z.number(),
  validationStatus: validationStatusSchema,
  validationType: vatValidationTypeSchema,
  validationResult: vatValidationResultSchema,
});

export const TAX_DOCUMENT_STATUS_VALIDATION_TYPE =
  "TAX_DOCUMENT_STATUS" as const;

const taxDocumentStatusValidationTypeSchema = z.literal(
  TAX_DOCUMENT_STATUS_VALIDATION_TYPE
);

export enum TaxDocumentStatusValidationResult {
  Valid = "VALID",
  Invalid = "INVALID",
  Undocumented = "UNDOCUMENTED",
  Undetermined = "UNDETERMINED",
}

export const taxDocumentStatusValidationResultSchema = z.nativeEnum(
  TaxDocumentStatusValidationResult
);

export const taxDocumentStatusValidationSchema = z.object({
  dateModified: z.string(),
  dateCreated: z.string(),
  accountOwnerId: accountOwnerIdSchema,
  validationVersion: z.number(),
  validationStatus: validationStatusSchema,
  validationType: taxDocumentStatusValidationTypeSchema,
  validationResult: taxDocumentStatusValidationResultSchema,
});

const accountOwnerValidationMapDtoSchema = z.object({
  irsTinMatch: irsTinMatchValidationSchema.optional(),
  vat: vatValidationSchema.optional(),
  taxDocumentStatus: taxDocumentStatusValidationSchema.optional(),
});

const accountOwnerDetailsAccountSchema = accountV2Schema
  .omit({
    accountOwner: true,
  })
  .extend({
    metadata: z.any().optional(),
  });

const accountOwnerDetailsAccountOwnerSchema = accountOwnerSchema.extend({
  pii: accountOwnerDetailsPiiSchema,
  validations: z.array(accountOwnerGenericValidationSchema),
  validation: accountOwnerValidationMapDtoSchema,
  metadata: z.any().optional(),
  accounts: z.array(accountOwnerDetailsAccountSchema),
});

export enum AccountOwnerDetailsTaxDocumentationIssueType {
  W8benPoBoxPermanentAddress = "W8BEN_PO_BOX_PERMANENT_ADDRESS",
  W8benePoBoxPermanentAddress = "W8BENE_PO_BOX_PERMANENT_ADDRESS",
  UsPermanentAddress = "US_PERMANENT_ADDRESS",
  ExpiredForm = "EXPIRED_FORM",
  W8benCareOf = "W8BEN_CARE_OF_PERMANENT_ADDRESS",
  W8beneCareOf = "W8BENE_CARE_OF_PERMANENT_ADDRESS",
  UsIndicia = "US_INDICIA",
}

const accountOwnerCuringArchiveSchema = z.object({
  id: z.number(),
  companyUserId: companyUserIdSchema,
  accountOwnerCuringId: z.number(),
  note: z.string().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

const accountOwnerIssueSchema = z.object({
  issueType: z.nativeEnum(AccountOwnerDetailsTaxDocumentationIssueType),
  target: z.string(),
  groupNumber: z.number(),
  createdAt: z.string().datetime(),
  resolvedAt: z.string().datetime().optional(),
  accountOwnerCuring: accountOwnerCuringSchema
    .omit({
      accountOwnerId: true,
      organizationId: true,
    })
    .extend({
      archive: accountOwnerCuringArchiveSchema.optional(),
    })
    .optional(),
});

const accountOwnerDetailsSchema = z.object({
  accountOwner: accountOwnerDetailsAccountOwnerSchema,
  issues: z.array(accountOwnerIssueSchema),
  latestTaxDocument: taxDocumentationWithUuidSchema.optional(),
  latestTaxDocumentationGroupNumber: z.number(),
});

export const accountOwnerDetailsResponseSchema = getPublicApiSuccessSchema(
  accountOwnerDetailsSchema
);

export type AccountOwnerDetailsResponse = z.infer<
  typeof accountOwnerDetailsResponseSchema
>;

export type AccountOwner = z.infer<
  typeof accountOwnerDetailsAccountOwnerSchema
>;

export type AccountOwnerIssue = z.infer<typeof accountOwnerIssueSchema>;
