/**
 * Keys to index saved page context data for a particular page, alongside
 * the actual route they correspond to. These routes must be kept up to date
 * to ensure that we do not mistakenly overwrite page context with top
 * our level hook to handle scroll state.
 */
export const pageContextPageKeyMap = {
  "accounts": "/accounts/",
  "account-details": "/accounts/:accountId/",
  "transactions": "/accounts/:accountId/transactions/",
  "transaction-details": "/accounts/:accountId/transactions/:transactionId/",
  "transfer-activity": "/transfer-activity/:transferType/",
  "transfer-details":
    "/transfer-activity/:transferType/:transactionId/accounts/:accountId",
  "eligibility-page": "/ir/eligibility",
} as const;

export type PageContextPageKey = keyof typeof pageContextPageKeyMap;

export type PageContextSlice = {
  /**
   * Map of all the scroll positions for each page.
   */
  scrollPositions: Partial<Record<PageContextPageKey, number>>;
  /**
   * Set current top scroll position for the specified page key.
   */
  setScrollPosition: (key: PageContextPageKey, position: number) => void;
  /**
   * Map of all the intended back button hrefs for each page. The hrefs stored
   * in this state object should include the organization prefix, so they can
   * be safely navigated to without needing our prefixed navigation helpers.
   */
  backHrefs: Partial<Record<PageContextPageKey, string>>;
  /**
   * Set the intended href that should be used for the back button corresponding
   * to the specified page key.
   */
  setBackHref: (key: PageContextPageKey, position: string) => void;
  /**
   * Resets page context for the given key, or all page context if no key is given. Can be used
   * to ensure that we don't maintain page contexts across different organizations or other context
   * switches within a single session.
   */
  resetPageContext: (keys?: readonly PageContextPageKey[]) => void;
};
