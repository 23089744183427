import { Flex } from "@taxbit-private/cosmic";
import {
  RhfDropdown,
  RhfTextInput,
} from "@taxbit-private/cosmic-react-hook-form";
import { useMemo, useState } from "react";

import { AddressCountry, countryOptions } from "../../../utils/countryOptions";
import {
  AddressStateOrProvince,
  caProvinceOptions,
  usStateOptions,
} from "../../../utils/stateOrProvinceOptions";
import {
  FilerSetupAddressDetails,
  FilerSetupModalTrackingId,
} from "../filerSetupModalTypes";

const FilerAddressDetails: React.FC = () => {
  const [addressCountry, setAddressCountry] = useState<AddressCountry>(
    countryOptions.find((option) => option.code === "US") as AddressCountry
  );
  const stateOrProvinceOptions = useMemo(() => {
    if (addressCountry.code === "US") {
      return usStateOptions;
    } else if (addressCountry.code === "CA") {
      return caProvinceOptions;
    } else {
      return [];
    }
  }, [addressCountry]);

  const handleCountryChange = (newCountry: AddressCountry) => {
    setAddressCountry(newCountry);
  };

  return (
    <Flex direction="column" gap="l">
      <RhfDropdown<FilerSetupAddressDetails, "addressCountry", AddressCountry>
        getOptionKey={(option) => option.code}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.code}
        label="Address Country"
        isLabelHidden={false}
        name="addressCountry"
        options={countryOptions}
        trackingId={FilerSetupModalTrackingId.AddressCountryDropdown}
        onChange={handleCountryChange}
      />
      <RhfTextInput<FilerSetupAddressDetails>
        label="Street Address"
        name="addressLine1"
        trackingId={FilerSetupModalTrackingId.AddressLine1}
      />
      <RhfTextInput<FilerSetupAddressDetails>
        label="Apt, suite, etc. (optional)"
        name="addressLine2"
        trackingId={FilerSetupModalTrackingId.AddressLine2}
      />
      <Flex direction="column" gap="l">
        {/* TODO: Fix formatting of state / province box dropdown (sizing is off) https://taxbit.atlassian.net/browse/TAX-38612 */}
        <Flex direction="row" gap="m">
          <RhfTextInput<FilerSetupAddressDetails>
            label="City"
            name="city"
            trackingId={FilerSetupModalTrackingId.City}
          />
          {addressCountry.code === "US" || addressCountry.code === "CA" ? (
            <RhfDropdown<
              FilerSetupAddressDetails,
              "stateOrProvince",
              AddressStateOrProvince
            >
              getOptionKey={(option) => option.code}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.code}
              label="State or Province"
              isLabelHidden={false}
              name="stateOrProvince"
              options={stateOrProvinceOptions}
              trackingId={FilerSetupModalTrackingId.StateOrProvinceDropdown}
              shouldAlignMenuToTargetWidth={true}
            />
          ) : (
            <RhfTextInput<FilerSetupAddressDetails>
              label="State or Province"
              name="stateOrProvince"
              trackingId={FilerSetupModalTrackingId.StateOrProvinceDropdown}
            />
          )}
        </Flex>
        <Flex direction="row" gap="m">
          <RhfTextInput<FilerSetupAddressDetails>
            label="ZIP Code"
            name="zipCode"
            trackingId={FilerSetupModalTrackingId.ZipCode}
          />
        </Flex>
      </Flex>
      <RhfTextInput<FilerSetupAddressDetails>
        label="Phone Number"
        name="phoneNumber"
        trackingId={FilerSetupModalTrackingId.PhoneNumber}
      />
    </Flex>
  );
};

export default FilerAddressDetails;
