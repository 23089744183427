import { DashboardFormType, EligibilityStatus } from "@taxbit-dashboard/rest";
import { RhfMultiselect } from "@taxbit-private/cosmic-react-hook-form";
import { useMemo } from "react";

import { EligibilityFiltersFormFields } from "./eligibilityFiltersFormFieldSchema";
import { getEligibilityStatusLabelMap } from "../../../../api/information-reporting/eligible-users/eligibleUsersApiTypes";

export enum EligibilityStatusFilterTrackingIds {
  Multiselect = "irEligibilityStatusFilterMultiselect",
}

export const getStatusOptions = (formType: DashboardFormType) =>
  Object.values(EligibilityStatus).map((status) => ({
    key: status,
    label: getEligibilityStatusLabelMap(formType)[status],
  }));

const EligibilityStatusFilter = ({
  formType,
}: {
  formType: DashboardFormType;
}) => {
  const STATUS_OPTIONS = useMemo(() => getStatusOptions(formType), [formType]);

  return (
    <RhfMultiselect<
      EligibilityStatus,
      Pick<EligibilityFiltersFormFields, "statuses">,
      "statuses",
      (typeof STATUS_OPTIONS)[number]
    >
      getOptionKey={({ key }) => key}
      getOptionLabel={({ label }) => label}
      getOptionValue={({ key }) => key}
      name="statuses"
      options={STATUS_OPTIONS}
      shouldAlignMenuToTargetWidth={true}
      shouldShowChips={true}
      label="Status"
      placeholder="All"
      trackingId={EligibilityStatusFilterTrackingIds.Multiselect}
    />
  );
};

export default EligibilityStatusFilter;
