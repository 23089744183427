import { Notification, NotificationActionType } from "@taxbit-dashboard/rest";
import { ClickableProps, Flex, Clickable } from "@taxbit-private/cosmic";

import { useFetchNotificationDownloadUrl } from "../../../api/notifications/notificationsApi";

type Props = Pick<Notification, "actions" | "notificationId">;

enum NotificationsListItemCtasTrackingId {
  Primary = "primary-cta",
  Secondary = "secondary-cta",
}

const NotificationsListItemCtas: React.FC<Props> = ({
  actions,
  notificationId,
}) => {
  const { fetchNotificationDownloadUrl, isFetchingDownloadUrl } =
    useFetchNotificationDownloadUrl(notificationId);

  if (!actions || actions.length === 0) {
    return undefined;
  }

  const [primaryAction, secondaryAction] = actions;

  const hasTwoCtas = !!secondaryAction;

  const getCtaClickableProps = (isSecondaryAction: boolean) => {
    const action = isSecondaryAction ? secondaryAction : primaryAction;

    const sharedProps = {
      variant: isSecondaryAction
        ? "button-tertiary"
        : hasTwoCtas
          ? "button-primary"
          : "anchor-primary",
      size: "small",
      label: action.actionText,
      trackingId: `${isSecondaryAction ? NotificationsListItemCtasTrackingId.Secondary : NotificationsListItemCtasTrackingId.Primary}-${notificationId}`,
    } as const;

    return (
      action.type === NotificationActionType.Download
        ? {
            ...sharedProps,
            onClick: () => {
              if (action.type === NotificationActionType.Download) {
                void fetchNotificationDownloadUrl();
              }
            },
            loadingText: "Downloading...",
            isLoading: isFetchingDownloadUrl,
          }
        : {
            ...sharedProps,
            href: action.actionUrl,
            target: "_self",
          }
    ) as ClickableProps;
  };

  return (
    <Flex gap="s" padding="xs none none none">
      <Clickable {...getCtaClickableProps(false)} />
      {hasTwoCtas && <Clickable {...getCtaClickableProps(true)} />}
    </Flex>
  );
};

export default NotificationsListItemCtas;
