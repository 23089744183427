import {
  KycTaxDocumentType,
  KycTaxdocumentationVatStatus,
} from "@taxbit-dashboard/rest";
import { useMemo } from "react";

import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";
import maskData from "../../../../../utils/maskData";

export type VatInformation = {
  vatinNotRequired?: boolean;
  vatin: string;
  unmaskedVatin?: string;
  vatinCountry?: string;
  vatStatus: KycTaxdocumentationVatStatus;
  vatValidationDate?: string;
};

const useGetVatInformation = () => {
  const {
    shouldUnmaskValue,
    isLoading,
    taxDocumentation,
    taxDocumentationStatus,
    isError: isAccountDetailsError,
    isTaxDocumentationStatusError,
  } = useAccountOwnerTaxDocumentContext();

  const taxDocumentationData = useMemo(() => {
    if (taxDocumentation?.documentType !== KycTaxDocumentType.Comprehensive) {
      return taxDocumentation;
    }

    return {
      ...taxDocumentation,
      accountHolder: taxDocumentation.accountHolder
        ? {
            ...taxDocumentation.accountHolder,
            vatin: maskData(taxDocumentation.accountHolder.vatin),
            unmaskedVatin: shouldUnmaskValue
              ? taxDocumentation.accountHolder.vatin
              : undefined,
            businessRegistrationNumber: maskData(
              taxDocumentation.accountHolder.businessRegistrationNumber
            ),
            unmaskedBusinessRegistrationNumber: shouldUnmaskValue
              ? taxDocumentation.accountHolder.businessRegistrationNumber
              : undefined,
          }
        : undefined,
    };
  }, [shouldUnmaskValue, taxDocumentation]);

  const vatInformation: VatInformation | undefined = useMemo(() => {
    if (
      taxDocumentationData?.documentType === KycTaxDocumentType.Comprehensive &&
      taxDocumentationStatus?.dac7Interview?.vatStatus &&
      taxDocumentationData.accountHolder?.vatin
    ) {
      return {
        vatinNotRequired: taxDocumentationData.accountHolder.vatinNotRequired,
        vatin: taxDocumentationData.accountHolder.vatin,
        unmaskedVatin: shouldUnmaskValue
          ? taxDocumentationData.accountHolder.unmaskedVatin
          : undefined,
        vatinCountry: taxDocumentationData.accountHolder.vatinCountry,
        vatStatus: taxDocumentationStatus.dac7Interview.vatStatus,
        vatValidationDate:
          taxDocumentationStatus.dac7Interview.vatValidationDate,
      };
    }

    return undefined;
  }, [shouldUnmaskValue, taxDocumentationData, taxDocumentationStatus]);

  const isError = isAccountDetailsError || isTaxDocumentationStatusError;

  return {
    isLoading,
    isError,
    vatInformation,
  };
};

export default useGetVatInformation;
