import { useLocation, useNavigate } from "@tanstack/react-location";
import {
  DashboardPrimaryTemplate,
  UserPermission,
  isDefined,
  useDashboardFeatureFlags,
  useNavigateBackWithFallback,
  useUserPermission,
} from "@taxbit-dashboard/commons";
import { KycTaxDocumentType } from "@taxbit-dashboard/rest";
import {
  Box,
  ContentErrorEmptyState,
  ContentSpinner,
} from "@taxbit-private/cosmic";
import { COSMIC_VALUE_PLACEHOLDER } from "@taxbit-private/cosmic-localization";
import { useCallback, useMemo } from "react";

import Details from "./details-tab/Details";
import Documentation from "./documentation-tab/Documentation";
import FormData from "./form-data-tab/FormData";
import Inventory from "./inventory-tab/Inventory";
import TaxForms from "./tax-forms-tab/TaxForms";
import Transactions from "./transactions-tab/Transactions";
import { useAccountOwnerTaxDocumentContext } from "../../../hooks/useGetAccountOwnerTaxDocumentData";
import EntityNotFound from "../EntityNotFound";

export enum AccountDetailsTabTrackingId {
  DetailsTab = "details-tab",
  DocumentationTab = "documentation-tab",
  TaxFormsTab = "tax-forms-tab",
  InventoryTab = "inventory-tab",
  FormDataTab = "form-data-tab",
  TransactioinsTab = "transactions-tab",
}

export enum AccountDetailsTrackingId {
  BackBtn = "account-details-back-button",
  LoadingSpinner = "account-details-loading-spinner",
}

const AccountDetails: React.FC = () => {
  const {
    hasTaxDocumentationAccess,
    hasAccountInventoryAccess,
    hasTransactionsTabAccess,
    hasAccountFormItemsAccess,
  } = useDashboardFeatureFlags();

  const navigateBackWithFallback = useNavigateBackWithFallback();

  const navigate = useNavigate();

  const {
    current: { pathname },
  } = useLocation();

  const {
    account,
    accountOwner,
    isLoading,
    isError,
    isAccountNotFoundError,
    taxDocumentation,
  } = useAccountOwnerTaxDocumentContext();

  const canViewTaxForms = useUserPermission(
    UserPermission.ReadAccountDocuments
  );

  const canViewAssets = useUserPermission(UserPermission.ReadAccountAssets);

  const tabItems = [
    {
      label: "Details",
      trackingId: AccountDetailsTabTrackingId.DetailsTab,
    },
    ...(hasTaxDocumentationAccess
      ? [
          {
            label: "Documentation",
            trackingId: AccountDetailsTabTrackingId.DocumentationTab,
          },
        ]
      : []),
    ...(canViewTaxForms
      ? [
          {
            label: "Tax Forms",
            trackingId: AccountDetailsTabTrackingId.TaxFormsTab,
          },
        ]
      : []),
    ...(canViewAssets && hasAccountInventoryAccess
      ? [
          {
            label: "Inventory",
            trackingId: AccountDetailsTabTrackingId.InventoryTab,
          },
        ]
      : []),
    ...(hasAccountFormItemsAccess
      ? [
          {
            label: "Form Data",
            trackingId: AccountDetailsTabTrackingId.FormDataTab,
          },
        ]
      : []),
    ...(hasTransactionsTabAccess
      ? [
          {
            label: "Transactions",
            trackingId: AccountDetailsTabTrackingId.TransactioinsTab,
          },
        ]
      : []),
  ];

  const accountName = useCallback(() => {
    if (accountOwner?.pii.name) {
      return accountOwner.pii.name;
    }

    if (
      isDefined(taxDocumentation) &&
      taxDocumentation?.documentType !== KycTaxDocumentType.Comprehensive
    ) {
      return taxDocumentation.name;
    }

    if (
      taxDocumentation?.documentType === KycTaxDocumentType.Comprehensive &&
      taxDocumentation?.accountHolder?.name
    ) {
      return taxDocumentation.accountHolder.name;
    }

    return "Name Not Available";
  }, [accountOwner, taxDocumentation])();

  const activeTab = useMemo(() => {
    const tabIds = Object.values(AccountDetailsTabTrackingId);
    const activeTabId = tabIds.find((tab) => pathname.includes(tab));
    return activeTabId ?? AccountDetailsTabTrackingId.DetailsTab;
  }, [pathname]);

  return isLoading ? (
    <ContentSpinner trackingId={AccountDetailsTrackingId.LoadingSpinner} />
  ) : (
    <DashboardPrimaryTemplate
      title={accountName}
      onBack={() => navigateBackWithFallback("account-details", "/accounts")}
      backButtonTrackingId={AccountDetailsTrackingId.BackBtn}
      backButtonLabel="Back"
      subtitles={[account?.externalId || COSMIC_VALUE_PLACEHOLDER]}
      tabs={
        isAccountNotFoundError
          ? undefined
          : tabItems.map((tab) => ({
              ...tab,
              isActive: tab.trackingId === activeTab,
              onClick: () => navigate({ to: `../${tab.trackingId}` }),
            }))
      }
    >
      {isAccountNotFoundError ? (
        <EntityNotFound entity="Account" />
      ) : isError ? (
        <Box>
          <ContentErrorEmptyState entity="account" />
        </Box>
      ) : (
        <>
          {activeTab === AccountDetailsTabTrackingId.DetailsTab && <Details />}
          {activeTab === AccountDetailsTabTrackingId.DocumentationTab && (
            <Documentation />
          )}
          {activeTab === AccountDetailsTabTrackingId.TaxFormsTab && (
            <TaxForms />
          )}
          {activeTab === AccountDetailsTabTrackingId.InventoryTab && (
            <Inventory />
          )}
          {activeTab === AccountDetailsTabTrackingId.FormDataTab && (
            <FormData />
          )}
          {activeTab === AccountDetailsTabTrackingId.TransactioinsTab && (
            <Transactions />
          )}
        </>
      )}
    </DashboardPrimaryTemplate>
  );
};

export default AccountDetails;
