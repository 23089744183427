import { irFormTypeToDisplayNameMap } from "@taxbit-dashboard/commons";
import { FileAction } from "@taxbit-dashboard/rest";
import { Dropdown, Flex } from "@taxbit-private/cosmic";

import useFormFeatureFlagData from "../../../information-reporting/shared/useFormFeatureFlagData";
import useFileUploaderContext from "../../context/useFileUploaderContext";

export enum FormDataInformationDropdownsTrackingIds {
  FormType = "form-data-form-type",
  FormYear = "form-data-year",
}

type Props = {
  action: FileAction;
};

const FormDataInformation: React.FC<Props> = ({ action }) => {
  const {
    forms,
    currentForm,
    years,
    currentYear,
    setCurrentForm,
    setCurrentYear,
  } = useFileUploaderContext(action);

  const { hasYearForForm, getYearsForForm } = useFormFeatureFlagData({
    featureFlag: "ingestionModuleYearsByFormTemplate",
  });

  return (
    <Flex direction="row" growItems={true} gap="s">
      <Dropdown
        options={forms}
        value={currentForm}
        getOptionKey={(value) => value}
        getOptionLabel={(value) => irFormTypeToDisplayNameMap[value]}
        onChange={(form) => {
          setCurrentForm(form);
          if (!currentYear || !hasYearForForm(form, currentYear)) {
            const fallbackYear = getYearsForForm(form)[0];
            setCurrentYear(fallbackYear);
          }
        }}
        trackingId={FormDataInformationDropdownsTrackingIds.FormType}
        label="Form Type"
        placeholder="Select an option"
      />
      <Dropdown
        options={years}
        value={currentYear}
        getOptionKey={(value) => value.toString()}
        getOptionLabel={(value) => value.toString()}
        onChange={setCurrentYear}
        trackingId={FormDataInformationDropdownsTrackingIds.FormYear}
        label="Form Year"
        placeholder="Select an option"
        isDisabled={!currentForm}
      />
    </Flex>
  );
};

export default FormDataInformation;
