import { useSetBackHrefAndNavigate } from "@taxbit-dashboard/commons";
import { AccountId } from "@taxbit-dashboard/rest";
import { Table, TrimmedBoxContent } from "@taxbit-private/cosmic";
import { useCallback } from "react";

import useTransactionsTableColumns, {
  TableTransaction,
} from "./useTransactionsTableColumns";

type TransactionsTableProps = {
  rows: TableTransaction[];
  accountId?: AccountId;
};

const TransactionsTable: React.FC<TransactionsTableProps> = ({
  rows,
  accountId,
}) => {
  const setBackHrefAndNavigate = useSetBackHrefAndNavigate();

  const columns = useTransactionsTableColumns();

  const getRowKey = useCallback((row: TableTransaction) => row.id, []);
  const getRowOnClick = useCallback(
    (row: TableTransaction) => {
      setBackHrefAndNavigate({
        to: `/accounts/${accountId}/transactions-tab/${row.id}`,
        backHrefKey: "transaction-details",
        reset: ["transaction-details"],
      });
    },
    [accountId, setBackHrefAndNavigate]
  );

  return (
    <TrimmedBoxContent trim="all">
      <Table
        columns={columns}
        getRowKey={getRowKey}
        rows={rows}
        getRowOnClick={getRowOnClick}
      />
    </TrimmedBoxContent>
  );
};

export default TransactionsTable;
