import {
  DashboardNavigationItem,
  AuthorizedView,
  DashboardAppRoutePath,
} from "@taxbit-dashboard/commons";

import AccountSettings from "./AccountSettings";

export const navigationItem: DashboardNavigationItem = {
  href: DashboardAppRoutePath.AccountSettings,
  iconName: "user",
  label: "My Account",
  trackingId: "sidebar-navigation-my-account",
  shouldShowRoute: ({ flags: { hasUserSettingsAccess } }) =>
    hasUserSettingsAccess,
} as const;

export const route = {
  path: "/my-account",
  element: (
    <AuthorizedView flags={["hasUserSettingsAccess"]}>
      <AccountSettings />
    </AuthorizedView>
  ),
};
