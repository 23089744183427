import { useDashboardFeatureFlags } from "@taxbit-dashboard/commons";
import { TaxReportStatus } from "@taxbit-dashboard/rest";
import { ErrorIcon, Flex } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";

import { useGetTaxReportStatuses } from "../../../api/taxReportsApi";
import { UmcReport } from "../types/umcReportTypes";

export const GenerateReportErrorIconTrackingId = "generateTaxReportErrorIcon";

const ReportDateWithErrorIcon: React.FC<{ report: UmcReport }> = ({
  report,
}) => {
  const { hasOnDemandReportsAccess } = useDashboardFeatureFlags();
  const { data: taxReportStatuses } = useGetTaxReportStatuses();
  const { formatDateTime } = useCosmicLocalizationContext();
  const reportStatus = taxReportStatuses?.find(
    (status) => status.reportName === report.key
  );
  const lastFailed = formatDateTime({
    date: reportStatus?.lastFailed,
    format: "DateTime",
  });

  return (
    <Flex alignItems="center" gap="s">
      <span>{report.date}</span>
      {hasOnDemandReportsAccess &&
        reportStatus?.status === TaxReportStatus.Failed && (
          <ErrorIcon
            trackingId={GenerateReportErrorIconTrackingId}
            tooltipProps={{
              content: `This report failed to generate on ${lastFailed}. Please try again, or contact Taxbit support if the issue persists.`,
              appendTo: "body",
              isInteractive: false,
            }}
          />
        )}
    </Flex>
  );
};

export default ReportDateWithErrorIcon;
