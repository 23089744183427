import {
  ToastConfig,
  hasDefinedValues,
  useDashboardStore,
} from "@taxbit-dashboard/commons";
import {
  AccountValidationsEligibilityStatus,
  EligibilityStatus,
} from "@taxbit-dashboard/rest";

import { TOAST_TIMEOUT } from "../../../../../utils/toastTimeout";
import { useEligibilityDataContext } from "../../context/useEligibilityData";

export enum GenerateTaxFormsConfirmationModalTrackingId {
  Checkbox = "ir-eligibility-generate-tax-forms-confirmation-modal-checkbox",
  CloseButton = "ir-eligibility-generate-tax-forms-confirmation-modal-close-btn",
  CancelButton = "ir-eligibility-generate-tax-forms-confirmation-modal-cancel-btn",
  SubmitButton = "ir-eligibility-generate-tax-forms-confirmation-modal-submit-btn",
  SuccessToast = "ir-eligibility-generate-tax-forms-confirmation-modal-success-toast",
  ErrorToast = "ir-eligibility-generate-tax-forms-confirmation-modal-error-toast",
}

export const generateTaxFormsSuccessToastContent: ToastConfig = {
  message: "The tax forms are generating.",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.SuccessToast,
  timeoutMs: TOAST_TIMEOUT,
};

export const generateTaxFormsErrorToastContent: ToastConfig = {
  message: "The tax forms failed to generate. Please try again.",
  variant: "danger",
  trackingId: GenerateTaxFormsConfirmationModalTrackingId.ErrorToast,
  timeoutMs: TOAST_TIMEOUT,
};

const useGenerateTaxFormsConfirmationModal = ({
  shouldIgnoreSelectedItems = false,
  shouldIncludeFilters = false,
  onClose,
}: {
  shouldIgnoreSelectedItems?: boolean;
  shouldIncludeFilters?: boolean;
  onClose: () => void;
}) => {
  const {
    urlParams: { formDate, formType, page, limit, ...filterParams },
    selectedItems,
    eligibilityCounts,
    totalCount,
    shouldDisableControls,
    generateTaxForms,
    toggleAllSelectedItems,
  } = useEligibilityDataContext();
  const addToast = useDashboardStore((state) => state.addToast);

  const { completeAccountData = 0, incompleteAccountData = 0 } =
    eligibilityCounts?.eligible || {};
  const totalEligibleAccounts = completeAccountData + incompleteAccountData;

  const handleSubmit = () => {
    generateTaxForms(
      {
        shouldIncludeFilters,
        items: shouldIgnoreSelectedItems
          ? undefined
          : selectedItems.filter(
              (item) =>
                (item.status === EligibilityStatus.Eligible ||
                  item.status === EligibilityStatus.Added) &&
                (item.accountValidationsEligibilityStatus ===
                  AccountValidationsEligibilityStatus.Complete ||
                  item.accountValidationsEligibilityStatus ===
                    AccountValidationsEligibilityStatus.CompleteWithWarnings)
            ),
      },
      {
        onSuccess: () => {
          addToast(generateTaxFormsSuccessToastContent);
        },
        onError: () => {
          addToast(generateTaxFormsErrorToastContent);
        },
      }
    );

    onClose();
    toggleAllSelectedItems(false);
  };

  return {
    handleSubmit,
    totalAccountsLabel: shouldIgnoreSelectedItems
      ? "Total Eligible Accounts"
      : "Total Accounts",
    totalAccounts: shouldIgnoreSelectedItems
      ? totalEligibleAccounts
      : selectedItems.length,
    totalFilteredAccounts: totalCount,
    isLoading: shouldDisableControls,
    hasFiltersApplied: hasDefinedValues(filterParams),
  };
};

export default useGenerateTaxFormsConfirmationModal;
