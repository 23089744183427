import { OffsetPaginationApiParams } from "@taxbit-dashboard/rest";

import { PageLimitPaginationParams } from "./pageLimitPaginationSchema";

const getPageLimitApiParams = ({
  page,
  limit,
}: PageLimitPaginationParams): OffsetPaginationApiParams => {
  const normalizedPage = Math.max(page - 1, 0);
  const offset = normalizedPage * limit;

  return {
    "page[limit]": limit,
    "page[offset]": offset,
  };
};

export default getPageLimitApiParams;
