import { useMatch } from "@tanstack/react-location";
import { AccountId, GainItem, TransactionId } from "@taxbit-dashboard/rest";
import { TableColumns } from "@taxbit-private/cosmic";
import { useCosmicLocalizationContext } from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { useGetGains } from "../../../../../api/gainsApi";
import useFormatAsset from "../../../../../utils/useFormatAsset";
import { formatGainType } from "../formatGainType";

const GAINS_LIMIT = 25;

const useDispositionTableData = () => {
  const {
    params: { accountId, transactionId },
  } = useMatch();

  const {
    isFetching: isLoading,
    data: gainsData,
    isError,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useGetGains({
    accountId: accountId as AccountId,
    transactionId: transactionId as TransactionId,
    limit: GAINS_LIMIT,
  });

  const { formatDateTime } = useCosmicLocalizationContext();
  const formatAsset = useFormatAsset();

  const columns = useMemo(
    (): TableColumns<GainItem> => [
      {
        key: "costBasisDate",
        label: "Date Acquired",
        renderCell: (costBasisDate) => {
          return formatDateTime({ date: costBasisDate, format: "DateTime" });
        },
      },
      {
        key: "amountSold",
        label: "Quantity",
        renderCell: (amountSold) => {
          return formatAsset({ assetAmount: amountSold?.assetAmount });
        },
      },
      {
        key: "proceeds",
        label: "Proceeds",
        renderCell: (proceeds) => {
          return formatAsset({ assetAmount: proceeds?.assetAmount });
        },
      },
      {
        key: "cost",
        label: "Cost Basis",
        renderCell: (cost) => {
          return formatAsset({ assetAmount: cost?.assetAmount });
        },
      },
      {
        key: "gain",
        label: "gain/loss",
        renderCell: (gain) => {
          return formatAsset({ assetAmount: gain?.assetAmount });
        },
      },
      {
        key: "gainType",
        label: "gain/loss type",
        renderCell: (gainType) => {
          return formatGainType(gainType);
        },
      },
    ],
    [formatAsset, formatDateTime]
  );

  return {
    isLoading,
    gainItems: gainsData || [],
    hasNextPage,
    fetchNextPage,
    isError,
    isFetchingNextPage,
    columns,
  };
};

export default useDispositionTableData;
