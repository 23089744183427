import {
  Card,
  ContentErrorEmptyState,
  ContentSpinner,
  RowGroup,
} from "@taxbit-private/cosmic";
import { useMemo } from "react";

import DownloadTaxDocumentationFormButton from "./DownloadTaxDocumentationFormButton";
import { useAccountOwnerTaxDocumentContext } from "../../../../../hooks/useGetAccountOwnerTaxDocumentData";

export enum DownloadTaxDocumentationTrackingId {
  DownloadTaxDocumentationSpinner = "download-tax-documentation-loading-spinner",
}

const DownloadTaxDocumentation: React.FC = () => {
  const { isLoading, taxDocumentationSummary } =
    useAccountOwnerTaxDocumentContext();

  const kycTaxFormRows = useMemo(() => {
    if (!taxDocumentationSummary) {
      return undefined;
    }

    return [
      {
        key: `${taxDocumentationSummary.documentType}-form`,
        avatarProps: { iconName: "file-text" },
        title: `${taxDocumentationSummary.documentType} Form`,
        content: (
          <DownloadTaxDocumentationFormButton
            documentType={taxDocumentationSummary.documentType}
          />
        ),
      },
    ];
  }, [taxDocumentationSummary]);

  return (
    <Card title="Download Tax Documentation">
      {isLoading ? (
        <ContentSpinner
          trackingId={
            DownloadTaxDocumentationTrackingId.DownloadTaxDocumentationSpinner
          }
        />
      ) : kycTaxFormRows ? (
        <RowGroup rows={kycTaxFormRows} />
      ) : (
        <ContentErrorEmptyState entity="tax documentation" />
      )}
    </Card>
  );
};

export default DownloadTaxDocumentation;
