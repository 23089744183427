import {
  Row,
  isDefined,
  renderExcludedSpan,
  useDashboardFeatureFlags,
} from "@taxbit-dashboard/commons";
import { AccountOwnerDetailsTaxDocumentationIssueType } from "@taxbit-dashboard/rest";
import { Flex } from "@taxbit-private/cosmic";
import {
  useCosmicLocalizationContext,
  COSMIC_VALUE_PLACEHOLDER,
} from "@taxbit-private/cosmic-localization";
import { useMemo } from "react";

import { isAccountOwnerDetailsCuringIssueType } from "../../../../../api/account-owners/accountOwnersApiTypes";
import {
  TaxDocDetails,
  taxClassificationMap,
} from "../../../../../api/kyc-tax-documentation/kycTaxDocumentationApiTypes";
import { IssueDetails } from "../../kyc-tax-documentation/IssueDetails";
import { IssueDetailsDeprecated } from "../../kyc-tax-documentation/IssueDetailsDeprecated";
import KycBadge from "../../kyc-tax-documentation/KycBadge";

const getAddressField = (field?: string, hasTrailingSpace: boolean = true) =>
  field ? `${field}${hasTrailingSpace ? " " : ""}` : "";

const useFormDetailsRows = ({
  formDetails,
}: {
  formDetails?: TaxDocDetails;
}) => {
  const { formatDateTime, formatBoolean } = useCosmicLocalizationContext();
  const { hasAddressCuringAccess } = useDashboardFeatureFlags();

  return useMemo(() => {
    if (!formDetails) {
      return undefined;
    }

    const topRows: Row[] = [
      {
        label: "Documentation Status",
        content: <KycBadge taxStatus={formDetails.documentStatus} />,
      },
      {
        label: "Name",
        content: renderExcludedSpan(formDetails.name),
      },
      {
        label: "Tax Classification",
        content: taxClassificationMap[formDetails.taxClassification] ?? "Other",
      },
      {
        label: "Submission Date",
        content: formatDateTime({
          date: formDetails.submissionDate,
        }),
      },
    ];

    if (formDetails.expirationDate) {
      topRows.push({
        label: "Expiration Date",
        content: formatDateTime({
          date: formDetails.expirationDate,
        }),
      });
    }

    if (isDefined(formDetails.subjectToBackupWithholding)) {
      topRows.push({
        label: "Subject to Backup Withholding",
        content: formatBoolean(formDetails.subjectToBackupWithholding),
      });
    }

    const getAddressString = (
      issueType: AccountOwnerDetailsTaxDocumentationIssueType
    ) => {
      if (!formDetails.permanentAddress) {
        return undefined;
      }

      if (isAccountOwnerDetailsCuringIssueType(issueType)) {
        return issueType ===
          AccountOwnerDetailsTaxDocumentationIssueType.UsIndicia
          ? "US"
          : `${getAddressField(formDetails.permanentAddress?.firstLine)}${getAddressField(formDetails.permanentAddress?.secondLine)}${getAddressField(formDetails.permanentAddress?.city)}${getAddressField(formDetails.permanentAddress?.stateOrProvince)}${getAddressField(formDetails.permanentAddress?.postalCode)}${getAddressField(formDetails.permanentAddress?.country, false)}`;
      }

      return undefined;
    };

    const deprecatedIssueContent =
      isDefined(formDetails.issues) &&
      formDetails.issues?.length > 0 &&
      formDetails.isDeprecated ? (
        <Flex direction="column" gap="s">
          {formDetails.issues.map((issue) => {
            return (
              <IssueDetailsDeprecated
                issue={issue}
                key={`issue-${issue.issueType}`}
              />
            );
          })}
        </Flex>
      ) : (
        COSMIC_VALUE_PLACEHOLDER
      );

    const hasValidIssue =
      !formDetails.isDeprecated && isDefined(formDetails.permanentAddress);

    const issueContent =
      isDefined(formDetails.issues) &&
      formDetails.issues.length > 0 &&
      hasValidIssue ? (
        <Flex direction="column" gap="s">
          {formDetails.issues.map((issue) => {
            return (
              <IssueDetails
                issue={issue}
                taxDocumentType={formDetails.documentType}
                key={`issue-${issue.issueType}`}
                addressString={getAddressString(issue.issueType)}
              />
            );
          })}
        </Flex>
      ) : (
        COSMIC_VALUE_PLACEHOLDER
      );

    const bottomRows: Row[] = [];
    bottomRows.push({
      label: "Issues",
      content: hasAddressCuringAccess ? issueContent : deprecatedIssueContent,
    });

    return [topRows, bottomRows];
  }, [formDetails, formatBoolean, formatDateTime, hasAddressCuringAccess]);
};

export default useFormDetailsRows;
